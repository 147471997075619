import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-award-terms',
  templateUrl: './award-terms.component.html',
  styleUrls: ['./award-terms.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AwardTermsComponent {
  @Input() handleScrolledToBottom: () => void;
  read = false;

  constructor() {}

  checkBottom(e) {
    if (this.read) {
      return;
    }

    const el = e.target;
    if (el.scrollHeight - Math.round(el.scrollTop) === el.clientHeight) {
      this.read = true;
      this.handleScrolledToBottom();
    }
  }
}
