import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RarityService {
  rarity = [
    { value: 'UN', text: 'Unique' },
    { value: 'LE', text: 'Limited edition' },
  ];
}
