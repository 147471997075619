<div class="wrapperwrapper">
  <div class="wrapper">
    <div class="sidebar">
      <ng-content select="[sidebar]"></ng-content>
    </div>
    <div class="content">
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
</div>
