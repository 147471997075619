import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../core/services/api/services';
import {
  ArtworksList,
  PatchedSetArtworkOrder,
  SetOrderResponse,
} from 'src/app/core/services/api/models';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/store/app.reducer';
import { selectUser } from 'src/app/store/app.selectors';
import {
  CanDirtyCheck,
  WarnOnUnsavedComponent,
} from 'src/app/shared/components/warn-on-unsaved/warn-on-unsaved.component';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { forkJoin, Observable, Subject } from 'rxjs';
import { StrictHttpResponse } from 'src/app/core/services/api/strict-http-response';

@Component({
  selector: 'app-user-artwork-overview',
  templateUrl: './user-artwork-overview.component.html',
  styleUrls: ['./user-artwork-overview.component.scss'],
})
export class UserArtworkOverviewComponent implements OnInit, OnDestroy, CanDirtyCheck {
  @ViewChild(WarnOnUnsavedComponent) warnerComponent: WarnOnUnsavedComponent;

  hasApplied: boolean;
  awardId: number;
  awardName: string;
  termsScrolled = false;
  success = false;
  step = 0;
  artworkId: number;
  artworkTitle: string;
  nextStep = false;
  // ! currently this is limited to one award
  awards: any = [];

  profileId: number;

  userId: number; // this is different from the profileId
  userArtworks: Array<ArtworksList> = [];
  userArtworksInitial: Array<ArtworksList> = []; // used to restore order when user cancels edit
  artworksToDelete: number[] = [];

  editMode = false;
  cancelModal = false;
  saveModal: boolean;

  userProfileType: string;

  selectedImage = 0;

  userAPICall: () => Observable<any>;

  userArtworksTable: Array<ArtworksList[]>;
  boxWidth = 248;
  columnSize: number;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private store: Store<AppState>,
    private ref: ChangeDetectorRef
  ) {
    this.apiService.apiV1AwardsAwardsRetrieve$Response().subscribe((r) => {
      let rawAwards: any = [];
      rawAwards = r.body;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      this.awards = rawAwards.filter(({ user_can_apply }) => user_can_apply === true);
      this.awardId = this.awards.length > 0 ? this.awards[0].id : undefined;
      this.awardName = this.awards.length > 0 ? this.awards[0].title : undefined;
    });
  }

  ngOnInit(): void {
    // void this.getUserData().then(this.getArtworks.bind(this));
    this.store
      .select(selectUser)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        if (!user.profileType) {
          return;
        }

        this.userId = user.userId;

        this.userProfileType = user.profileType;
        if (user.profileType === 'artist') {
          this.userAPICall = () => this.apiService.apiV1UserArtistProfilesRetrieve$Response();
        } else {
          this.userAPICall = () => this.apiService.apiV1UserUserProfilesRetrieve$Response();
        }

        this.getArtworks.bind(this);
        this.getArtworks();
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getArtworks() {
    this.updateArtwork();
  }

  checkDuplicate() {
    const empty = [];
    for (const userArtwork of this.userArtworks) {
      for (const application of userArtwork.applies) {
        if (parseInt(String(application), 10) === this.awardId) {
          empty.push(application);
          this.checkArray(empty);
        }
      }
    }
  }

  checkArray(empty) {
    if (empty.length && empty.length < 1) {
      this.hasApplied = false;
    } else {
      this.hasApplied = true;
    }
  }

  updateArtwork() {
    this.userAPICall().subscribe((rProfile: any) => {
      this.userId = rProfile.body.user;
      this.apiService
        .apiV1UserArtworksList({ id: this.userId })
        .subscribe((rArtworks: ArtworksList[]) => {
          this.userArtworks = rArtworks;
          this.userArtworksInitial = rArtworks;
          this.checkDuplicate();
          this.initTable();
        });
    });
  }

  cancelEditMode = () => {
    this.editMode = false;
    this.saveModal = false;
    this.cancelModal = false;
    this.artworksToDelete = [];

    this.userArtworks = [...this.userArtworksInitial];
    this.initTable();
  };

  continueEditMode = () => {
    this.cancelModal = false;
    this.saveModal = false;
  };

  saveEditMode = () => {
    // collecting delete requests and reorder request to be able to
    // execute updateArtwork after all of them are through.
    const requests: Array<Observable<any>> = [];
    this.artworksToDelete.forEach((id) => {
      requests.push(this.deleteArtwork(id));
    });
    requests.push(this.saveNewArtworkOrder());
    forkJoin(requests).subscribe(() => {
      this.updateArtwork();
    });

    this.editMode = false;
    this.saveModal = false;
    this.cancelModal = false;
    this.artworksToDelete = [];
  };

  checkDirty(): boolean {
    const orderChanged = !this.userArtworks.every(
      (artwork, i) => artwork.id === this.userArtworksInitial[i].id
    );

    return this.artworksToDelete.length > 0 || orderChanged;
  }

  markForDeletion(id) {
    this.artworksToDelete.push(id);
    this.checkDirty();
  }

  unmarkForDeletion(id) {
    this.artworksToDelete.splice(
      this.artworksToDelete.findIndex((e) => e === id),
      1
    );
    this.checkDirty();
  }

  deleteArtwork = (id: number): Observable<StrictHttpResponse<void>> => {
    this.userArtworks.forEach((element, index) => {
      if (element.id === id) {
        this.userArtworks.splice(index, 1);
      }
    });
    // also delete from initial artwork list
    this.userArtworksInitial.forEach((element, index) => {
      if (element.id === id) {
        this.userArtworksInitial.splice(index, 1);
      }
    });
    return this.apiService.apiV1ArtworkArtworksDestroy$Response({ id });
  };

  saveNewArtworkOrder = (): Observable<SetOrderResponse> => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const order: PatchedSetArtworkOrder = { display_order: {} };
    this.userArtworks.forEach((artwork, index) => {
      order.display_order[artwork.id] = index;
    });

    return this.apiService.apiV1ArtworkArtworksDisplayOrdersPartialUpdate$Json({ body: order });
  };

  next = () => {
    this.nextStep = true;
    setTimeout(() => {
      this.step++;
    }, 500);
  };

  handleTermsScrolled = () => {
    this.termsScrolled = true;
  };

  applyForArtAward = () => {
    const body = {
      artwork: this.artworkId,
      award: this.awardId,
      id: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      total_audience_vote: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      total_jury_votes: null,
    };

    this.apiService.apiV1AwardsNominationsCreate$Json$Response({ body }).subscribe((r) => {
      this.success = true;
      this.next();
    });
  };

  showDialog(id, title) {
    this.nextStep = false;
    this.artworkId = id;
    this.artworkTitle = title;
    this.termsScrolled = false;
    this.step = 1;
  }

  closeDialog = () => {
    this.step = 0;
  };

  refreshArtwork = () => {
    this.closeDialog();
    this.updateArtwork();
    this.ref.detectChanges();
  };

  getItemsTable(rowLayout: Element): ArtworksList[][] {
    // calculate column size per row

    const { width } = rowLayout.getBoundingClientRect();
    const columnSize = Math.max(1, Math.floor(width / this.boxWidth));

    // view has been resized? => update table with new column size
    if (columnSize !== this.columnSize) {
      this.columnSize = columnSize;
      this.initTable();
    }

    return this.userArtworksTable;
  }

  initTable() {
    // create table rows based on input list
    // example: [1,2,3,4,5,6] => [ [1,2,3], [4,5,6] ]
    this.userArtworksTable = this.userArtworks
      .filter((_, outerIndex) => outerIndex % this.columnSize === 0) // create outter list of rows
      .map(
        (
          _,
          rowIndex // fill each row from...
        ) =>
          this.userArtworks.slice(
            rowIndex * this.columnSize, // ... row start and
            rowIndex * this.columnSize + this.columnSize // ...row end
          )
      );
  }

  reorderDroppedItem(event: CdkDragDrop<ArtworksList[]>) {
    // same row/container? => move item in same row
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // different rows? => transfer item from one to another list
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    // update items after drop: flatten matrix into list
    // example: [ [1,2,3], [4,5,6] ] => [1,2,3,4,5,6]
    this.userArtworks = this.userArtworksTable.reduce(
      (previous, current) => previous.concat(current),
      []
    );

    // re-initialize table - makes sure each row has same numbers of entries
    // example: [ [1,2], [3,4,5,6] ] => [ [1,2,3], [4,5,6] ]
    this.initTable();
    this.checkDirty();
  }
}
