import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { timeout } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api/services';
import { CookieService } from 'src/app/core/services/cookie-service/cookie-service.service';
import { signupValidators } from '../../form-validators/signup-in.validators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.form = new UntypedFormGroup(
      {
        oldPassword: new UntypedFormControl('', [Validators.required]),
        password: new UntypedFormControl('', [Validators.required, signupValidators.passwordValid]),
        verifyPassword: new UntypedFormControl('', [Validators.required]),
      },
      { validators: [signupValidators.passwordMatchValidator], updateOn: 'submit' }
    );
  }

  formSubmitted = false;
  passwordTooCommon = false;
  passwordNotCorrect = false;
  loggedIn = false;

  get oldPassword() {
    return this.form.get('oldPassword');
  }
  get password() {
    return this.form.get('password');
  }
  get passwordConfirm() {
    return this.form.get('verifyPassword');
  }
  ngOnInit(): void {}

  navigateToHome() {
    this.router.navigate(['/']).then(() => {
      window.location.href = window.location.protocol + '//' + window.location.host + '/';
    });
  }
  onSubmit() {
    if (this.form.invalid) {
      this.form.setErrors({ ...this.form.errors });
      return;
    }
    const body = {
      old_password: this.oldPassword.value,
      password: this.password.value,
      password_confirm: this.passwordConfirm.value,
    };
    this.apiService.apiV1UserChangePasswordCreate$Json$Response({ body }).subscribe(
      (r) => {
        if (r.status == 200) {
          this.formSubmitted = true;
          this.loggedIn = true;
          this.passwordNotCorrect = false;
          this.passwordTooCommon = false;
          setTimeout(() => {
            this.navigateToHome();
          }, 3000);
          this.cookieService.deleteCookie('userProfile');
          this.cookieService.deleteCookie('userType');
        }
      },
      (error) => {
        console.log(error.error);
        if (error.error.password) {
          this.passwordTooCommon = true;
        }
        if (error.error.old_password) {
          this.passwordNotCorrect = true;
        }
      }
    );
  }
}
