import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MaterialService {
  material = [
    { value: 'CAN', text: 'Canvas' },
    { value: 'PAP', text: 'Paper' },
    { value: 'ALIM', text: 'Aluminium' },
    { value: 'MAR', text: 'Marble' },
    { value: 'PLAS', text: 'Plaster' },
    { value: 'CONC', text: 'Concrete' },
    { value: 'CERAM', text: 'Ceramic' },
    { value: 'SYN', text: 'Synthetic resin' },
    { value: 'COPP', text: 'Copper' },
    { value: 'PORC', text: 'Porcelain' },
    { value: 'OTH', text: 'Other' },
  ];
}
