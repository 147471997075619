/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { WagtailService } from 'angular-wagtail';
import { BaseService } from 'src/app/core/services/api/base-service';
import { ThemeService } from 'src/app/core/services/theme/theme.service';

@Component({
  selector: 'app-generic-page',
  templateUrl: './generic-page.component.html',
  styleUrls: ['./generic-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GenericPageComponent implements OnInit, OnDestroy {
  data: any = {};
  theme = 'WHITE';

  backgroundImages = [];
  backgroundImage;

  constructor(
    private wagtailService: WagtailService,
    private baseService: BaseService,
    private themeService: ThemeService
  ) {}

  getData() {
    this.wagtailService.getPageForCurrentUrl().subscribe((r: any) => {
      this.data = r;
      this.themeService.theme = this.data.theme;

      if (r.background_images[0]) {
        if (r.background_images[0].value.length > 1) {
          r.background_images[0].value.forEach((image) => {
            this.backgroundImages.push({ path: image.image_url });
          });
        } else {
          this.backgroundImage = r.background_images[0].value[0].image_url;
        }
      }
    });
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy() {
    this.themeService.theme = undefined;
  }

  getStyle() {
    let style = '';
    if (!!this.backgroundImage || this.backgroundImages.length > 0) {
      style += 'min-height: 95vh;';
    }

    if (this.data.theme === 'DARK') {
      if (!(!!this.backgroundImage || this.backgroundImages.length > 0)) {
        style += 'background-color: black;';
      }

      style += ' color: white';
    }

    return style;
  }
}
