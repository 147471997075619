import { Component, Input, OnInit } from '@angular/core';

import Glide from '@glidejs/glide';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  glide: any;

  @Input() data;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.glide = new Glide('.glide', {
      autoplay: 5000,
    }).mount();
  }

  scroll() {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    });
  }
}
