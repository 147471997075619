import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wagtail-grid-block',
  templateUrl: './wagtail-grid-block.component.html',
  styleUrls: ['./wagtail-grid-block.component.scss'],
})
export class WagtailGridBlockComponent implements OnInit {
  @Input() entries;
  constructor() {}

  ngOnInit(): void {}
}
