import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root',
})
export class SignupInStatusService {
  showLogin = false;
  showSignin = false;

  loggedIn: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  toggleSignin() {
    this.showSignin = !this.showSignin;
    this.showLogin = false;
  }

  toggleLogin() {
    this.showLogin = !this.showLogin;
    this.showSignin = false;
  }

  closeAll() {
    this.showLogin = false;
    this.showSignin = false;
  }

  setLoggedIn(val: boolean) {
    this.loggedIn.next(val);
  }
}
