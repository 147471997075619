import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-checkbox-field',
  templateUrl: './formly-checkbox-field.component.html',
  styleUrls: ['./formly-checkbox-field.component.scss'],
})
export class FormlyCheckboxFieldComponent extends FieldType implements OnInit {
  checked;

  ngOnInit(): void {}

  onChange(status) {
    this.checked = status;
    this.formControl.setValue(status);

    this.formControl.markAsTouched();
  }
}
