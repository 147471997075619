import { signupValidators } from '../../form-validators/signup-in.validators';
import { Component, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { OccupationsService } from '../../../core/services/occupations/occupations.service';
import { SignupInStatusService } from '../../../core/services/signup-in-status/signup-in-status.service';
import { ApiService } from 'src/app/core/services/api/services';
import { UserRegister } from 'src/app/core/services/api/models';

// Sentry integration
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-user-signup',
  templateUrl: './user-signup.component.html',
  styleUrls: ['./user-signup.component.scss'],
})
export class UserSignupComponent {
  @Output() closed = new EventEmitter();

  occupationsList = this.occupationsService.occupations;
  occupationSelected = false;
  termsAccepted = false;

  /// Form///
  form = new UntypedFormGroup(
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      firstName: new UntypedFormControl('', Validators.required),
      // eslint-disable-next-line @typescript-eslint/unbound-method
      lastName: new UntypedFormControl('', Validators.required),
      // eslint-disable-next-line @typescript-eslint/unbound-method
      occupations: new UntypedFormControl('', Validators.required),
      // eslint-disable-next-line @typescript-eslint/unbound-method
      email: new UntypedFormControl('', [Validators.required, signupValidators.emailValid]),
      // eslint-disable-next-line @typescript-eslint/unbound-method
      password: new UntypedFormControl('', [Validators.required, signupValidators.passwordValid]),
      // eslint-disable-next-line @typescript-eslint/unbound-method
      verifyPassword: new UntypedFormControl('', Validators.required),
      // eslint-disable-next-line @typescript-eslint/unbound-method
      acceptTerms: new UntypedFormControl(false, Validators.requiredTrue),
    },
    // eslint-disable-next-line @typescript-eslint/unbound-method
    { validators: [signupValidators.passwordMatchValidator], updateOn: 'submit' }
  );

  formSubmitted = false;
  // placeholder for the servercall to validate the form
  formIsValid = false;
  emailUnique = false;
  passwordTooCommon = false;

  registered = false;

  constructor(
    private occupationsService: OccupationsService,
    private signupInStatusService: SignupInStatusService,
    private apiService: ApiService
  ) {}

  closeSignin = () => this.signupInStatusService.toggleSignin();
  openLogin = () => this.signupInStatusService.toggleLogin();
  termsAccepter = () => (this.termsAccepted = !this.termsAccepted);
  checkOccupation = () => (!this.occupationSelected ? (this.occupationSelected = true) : null);

  emailFocus() {
    this.emailUnique = false;
  }

  signUp = () => {
    if (this.form.invalid) {
      this.form.setErrors({ ...this.form.errors });
      return;
    }

    const requestBody: UserRegister = {
      first_name: this.firstName.value,
      last_name: this.lastName.value,
      email: this.email.value,
      password: this.password.value,
      occupation: this.occupations.value,
    };

    this.apiService.apiV1UserRegisterCreate$FormData$Response({ body: requestBody }).subscribe(
      (r) => {
        // console.log(r);
        this.registered = true;
      },
      (error) => {
        console.log(error.error);
        this.emailUnique = !!error.error.email;

        if (error.error.password) {
          this.passwordTooCommon = error.error.password.find(
            (obj) => obj.code === 'password_too_common'
          );
          this.passwordTooCommon = true;
        }

        // report via Sentry
        if (error && error.stack && error.message) {
          Sentry.captureException(error, {
            tags: {
              api: 'apiV1UserRegisterCreate',
            },
          });
        } else {
          Sentry.captureException(new Error(error?.message), {
            tags: {
              api: 'apiV1UserRegisterCreate',
              requestStatus: error?.status,
              requestError: error?.name,
              requestUrl: error?.url,
            },
          });
        }
      }
    );
  };

  get occupations() {
    return this.form.get('occupations');
  }
  get firstName() {
    return this.form.get('firstName');
  }
  get lastName() {
    return this.form.get('lastName');
  }

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }

  get verifyPassword() {
    return this.form.get('verifyPassword');
  }

  get acceptTerms() {
    return this.form.get('acceptTerms');
  }
}
