<div class="outer-wrapper">
  <div class="wrapper">
    <div class="bubble">
      <div class="text-wrapper">
        <p>{{ awardData.title }}</p>
        <p>OPEN CALL</p>
      </div>
      <div class="apply">
        <p>APPLY NOW</p>
      </div>
    </div>
    <img src="/assets/art-award-call-artronaut.png" alt="" class="artronaut-image" />
  </div>
</div>
