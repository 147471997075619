/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { ApiService } from 'src/app/core/services/api/services';
import { TitlesService } from 'src/app/core/services/titles/titles.service';

// Sentry integration
import * as Sentry from '@sentry/angular';
import { DatePipe } from '@angular/common';
import {
  CanDirtyCheck,
  WarnOnUnsavedComponent,
} from 'src/app/shared/components/warn-on-unsaved/warn-on-unsaved.component';

@Component({
  selector: 'app-formly-artist-editor',
  templateUrl: './formly-artist-editor.component.html',
  styleUrls: ['./formly-artist-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyArtistEditorComponent implements OnInit, CanDirtyCheck {
  @ViewChild(WarnOnUnsavedComponent) warnerComponent: WarnOnUnsavedComponent;
  // Status indicators
  displayLoadingIndicator = false;
  showErrorOnSaveModal = false;
  success = false;
  resetSuccess = false;
  errors = false;

  artisticOptions = [
    { code: 'PAI', name: 'painter' },
    { code: 'SCU', name: 'sculptor' },
    { code: 'PHO', name: 'photographer' },
    { code: 'PER', name: 'performance artist' },
    { code: 'VID', name: 'video artist' },
    { code: 'LIG', name: 'light artist' },
    { code: 'CON', name: 'conceptual artist' },
    { code: 'INS', name: 'installation artist' },
    { code: 'ARCH', name: 'architect' },
    { code: 'IND', name: 'industrial designer' },
    { code: 'MED', name: 'media designer' },
    { code: 'FAS', name: 'fashion designer' },
    { code: 'COS', name: 'costume designer' },
    { code: 'MAK', name: 'makeup artist' },
    { code: 'SET', name: 'set designer' },
    { code: 'DIR', name: 'director' },
    { code: 'ACT', name: 'actor' },
    { code: 'MUS', name: 'musician' },
    { code: 'WRI', name: 'writer' },
    { code: 'DAN', name: 'dancer' },
    { code: 'OTH', name: 'other' },
  ];

  titles = this.titlesService.titles;

  // Form
  form = new UntypedFormGroup({});

  // Model for user form
  model: any = {};

  // Form options
  options: FormlyFormOptions = {};

  // Fields configuration
  fields: FormlyFieldConfig[] = [
    // Title, Artistic activities - row 1
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'title',
          type: 'dropdown',
          className: 'flex-1',
          templateOptions: {
            label: 'Title',
            required: true,
            placeholder: 'Select title',
            options: this.titles,
          },
          validation: {
            messages: {
              required: 'Title is required',
            },
          },
        },
        {
          key: 'artistic_activities',
          type: 'multiselect',
          className: 'flex-1',
          templateOptions: {
            selectOptions: this.artisticOptions,
            label: 'Artistic activities',
          },
        },
      ],
    },
    // Name - row 2
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'firstName',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'First Name',
            required: true,
          },
          validation: {
            messages: {
              required: 'First name is required',
            },
          },
        },
        {
          key: 'lastName',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Last Name',
            required: true,
          },
          validation: {
            messages: {
              required: 'Last name is required',
            },
          },
        },
      ],
    },
    // Address - row 3
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'address',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Address',
            required: true,
          },
          validation: {
            messages: {
              required: 'Address is required',
            },
          },
        },
      ],
    },
    // Email, Phone - row 4
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'email',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Email',
            disabled: true,
            required: true,
          },
        },
        {
          key: 'phone',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Phone',
            required: false,
            hint: 'Not for public use',
          },
          validators: {
            validation: ['numbers'],
          },
          validation: {
            messages: {
              numbers: 'Invalid phone number',
            },
          },
        },
      ],
    },
    // Website - row 5
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'website',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Website if applicable',
            required: false,
            placeholder: 'https://www.example.com',
            hint: 'Please enter URLs with http:// or https://',
          },
          validators: {
            validation: ['url'],
          },
          validation: {
            messages: {
              url: 'Invalid URL',
            },
          },
        },
      ],
    },
    // Place, Country of birth - row 6
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'place_of_birth',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Place of Birth',
          },
        },
        {
          key: 'country_of_birth',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Country of birth',
          },
        },
      ],
    },
    // Place, Country of birth - row 7
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'dob',
          type: 'calendar',
          className: 'flex-1',
          templateOptions: {
            label: 'Date of Birth (DD/MM/YYYY)',
            required: false,
            yearNavigator: true,
            yearRange: Array.from({ length: 100 }, (v, k) => k + new Date().getFullYear()),
          },
        },
        {
          key: 'current_location',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Where do you currently live and work?',
            hint: 'If it does not match the place of birth',
          },
        },
      ],
    },
    // Choices - row 8
    {
      fieldGroup: [
        {
          key: 'choices',
          type: 'multicheckbox',
          templateOptions: {
            fullWidth: true,
            optionsArray: [
              {
                label: '',
                options: [
                  {
                    value: 'AP',
                    text: "I apply for the publication of my artworks on the ARTRONAUT's website",
                  },
                  {
                    value: 'NL',
                    text: "I would like to be informed about ARTRONAUT's activities and receive newsletters",
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    // Degree - row 9
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'degree',
          type: 'file',
          className: 'flex-1',
          templateOptions: {
            label: 'Academy Letter/ Degree Certificate',
            multiple: false,
            type: 'file',
            marginTop: true,
            maxNoOfFiles: 1,
            maxFileSize: 10,
            maxTotalFileSize: 10,
            description: 'Drag or click to upload your Academy Letter / Degree Certificate',
          },
          validation: {
            messages: {
              invalidFileType: 'Please select files of type pdf',
              invalidNoOfFiles: 'Maximum number of files is 1',
              invalidFileSize: 'File can only have a maximum size of 10MB',
            },
          },
        },
      ],
    },
    // Portrait - row 10
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'portrait',
          type: 'file',
          className: 'flex-1',
          templateOptions: {
            label: 'Portrait',
            multiple: false,
            type: 'image',
            maxNoOfFiles: 1,
            maxFileSize: 10,
            maxTotalFileSize: 10,
            description: 'Drag or click to upload your <b>Portrait Image</b>',
            helpText:
              'Please name your Portrait Image as follows: Last Name_First Name_Portrait Image' +
              '</br> Please upload a JPG or PNG format with a height or width of at least 2000 px.',
          },
          validation: {
            messages: {
              invalidFileType: 'Please select files of type jpg, jpeg or png',
              invalidNoOfFiles: 'Maximum number of files is 1',
              invalidFileSize: 'Each file can only have a maximum size of 10MB',
            },
          },
        },
      ],
    },
    // Documentation - row 11
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'artist_documents',
          type: 'file',
          className: 'flex-1',
          templateOptions: {
            label: 'Documentation',
            multiple: true,
            type: 'file',
            maxNoOfFiles: 10,
            maxFileSize: 2,
            maxTotalFileSize: 20,
            description: 'Drag or click to upload additional documentation',
          },
          validation: {
            messages: {
              invalidFileType: 'Please select files of type pdf',
              invalidNoOfFiles: 'Maximum number of files is 1',
              invalidFileSize: 'File can only have a maximum size of 2MB',
              invalidTotalSize: 'The total size of all files can only be 20MB',
            },
          },
        },
      ],
    },

    // Education - row 12
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'education',
          type: 'text-area',
          className: 'flex-1',
          templateOptions: {
            label: 'Education',
            required: true,
            sectionHeading: 'Biography *',
            showNA: true,
            placeholder: 'Year - Year, Institution, City, Country',
            hintText:
              'Please enter the required information from your CV as follows:<br> Year - Year, Institution, City, Country',
          },
          validation: {
            messages: {
              required: 'Education is required',
            },
          },
        },
      ],
    },

    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'awards_and_accolades',
          type: 'text-area',
          className: 'flex-1',
          templateOptions: {
            label: 'Awards and accolades',
            required: true,
            showNA: true,
            placeholder: 'Year, Name of the Award, City, Country',
            hintText:
              'Please enter the required information from your CV as follows:<br> Year, Name of the Award, City, Country',
          },
          validation: {
            messages: {
              required: 'Field is required',
            },
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'solo_exhibitions',
          type: 'text-area',
          className: 'flex-1',
          templateOptions: {
            label: 'Solo Exhibitions',
            required: true,
            showNA: true,
            placeholder: 'Year, Exhibition Title, Name of Exhibition Location, City, Country',
            hintText:
              'Please enter the required information from your CV as follows:' +
              '<br>Year, Exhibition Title, Name of Exhibition Location, City, Country',
          },
          validation: {
            messages: {
              required: 'Field is required',
            },
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'group_exhibitions',
          type: 'text-area',
          className: 'flex-1',
          templateOptions: {
            label: 'Group Exhibitions',
            required: true,
            showNA: true,
            placeholder:
              'Year, Exhibition Title, Name of Exhibition Location, Participating Artists, City, Country',
            hintText:
              'Please enter the required information from your CV as follows:' +
              '<br>Year, Exhibition Title, Name of Exhibition Location, Participating Artists, City, Country',
          },
          validation: {
            messages: {
              required: 'Field is required',
            },
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'collection',
          type: 'text-area',
          className: 'flex-1',
          templateOptions: {
            label: 'Collections',
            required: true,
            showNA: true,
            placeholder: 'Name of the Collection, City, Country',
            hintText:
              'Please enter the required information from your CV as follows:<br>Name of the Collection, City, Country',
          },
          validation: {
            messages: {
              required: 'Field is required',
            },
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'cooperation',
          type: 'text-area',
          className: 'flex-1',
          templateOptions: {
            label: 'Cooperations',
            labelBold: true,
            required: true,
            showNA: true,
            hintText:
              'Please list the galleries / institutions / organizations with whom you work currently or worked in the past',
          },
          validation: {
            messages: {
              required: 'Field is required',
            },
          },
        },
      ],
    },
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'statement',
          type: 'text-area',
          className: 'flex-1',
          templateOptions: {
            label: 'Artist Statement',
            required: true,
            labelBold: true,
            showNA: true,
            maxLength: 2500,
            hintText:
              'Please enter your artist statement with up to max. 2500 characters including spaces',
          },
          validation: {
            messages: {
              required: 'Field is required',
            },
          },
        },
      ],
    },
    {
      key: 'vimeoVideos',
      type: 'repeat',
      templateOptions: {
        addText: 'Add an additional video',
        sectionLabel: 'Videos',
      },
      fieldArray: {
        fieldGroupClassName: 'display-grid',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'text',
            key: 'url',
            templateOptions: {
              label: 'Please add a URL to a Vimeo video',
              placeholder: 'https://vimeo.com/347119375',
            },
            validators: {
              validation: ['vimeoUrl'],
            },
            validation: {
              messages: {
                vimeoUrl: 'Invalid Vimeo URL',
              },
            },
          },
          {
            type: 'text-area',
            key: 'description',
            className: 'col-6',
            templateOptions: {
              type: 'date',
              label: 'Description',
              placeholder:
                'First Name Last Name \n' +
                'Title of the Exhibition \n' +
                'Name of Exhibition Location \n' +
                'Country',
              rows: 4,
            },
          },
          {
            key: 'remote',
          },
          {
            key: 'delete',
          },
        ],
      },
    },
  ];

  constructor(
    private apiService: ApiService,
    private datePipe: DatePipe,
    private titlesService: TitlesService
  ) {}

  ngOnInit(): void {
    this.getUser();
  }

  // Function for getting saved user data
  getUser() {
    this.apiService.apiV1UserArtistProfilesRetrieve$Response().subscribe(
      (r) => {
        this.formatDataStructure(r.body);
      },
      (error) => {
        // console.log(error.error);

        this.showErrorOnSaveModal = true;

        // report via Sentry
        if (error && error.stack && error.message) {
          Sentry.captureException(error, {
            tags: {
              api: 'apiV1UserUserProfilesRetrieve',
            },
          });
        } else {
          Sentry.captureException(new Error(error?.message), {
            tags: {
              api: 'apiV1UserUserProfilesRetrieve',
              requestStatus: error?.status,
              requestError: error?.name,
              requestUrl: error?.url,
            },
          });
        }
      }
    );
  }

  // Format data from backed to work with frontend
  formatDataStructure(data) {
    this.model = {};
    this.model = data;
    this.model.firstName = data.user_data.first_name;
    this.model.lastName = data.user_data.last_name;
    this.model.email = data.user_data.email;
    this.model.dob = data.date_of_birth ? new Date(data.date_of_birth) : null;

    this.model.vimeoVideos = [];

    this.model.portrait = this.model.portrait_small ? this.model.portrait_small : [];
    this.model.degree = this.model.degree ? this.model.degree : [];

    if (this.model.artist_videos.length) {
      this.model.artist_videos.forEach((video) => {
        this.model.vimeoVideos.push({
          id: video.id,
          url: video.url,
          description: video.description,
          remote: true,
          delete: false,
        });
      });
    } else {
      this.model.vimeoVideos = [{}];
    }
  }

  // Submit function
  onSubmit() {
    if (this.form.invalid) {
      this.errors = true;
      this.form.markAllAsTouched();
      this.form.setErrors({ ...this.form.errors });
      return;
    }

    this.displayLoadingIndicator = true;
    const requestBody: any = {
      // Personal info

      user_data: {
        first_name: this.model.firstName,
        last_name: this.model.lastName,
        email: this.model.email,
      },

      title: this.model.title,
      address: this.model.address,
      phone: this.model.phone,
      website: this.model.website,
      place_of_birth: this.model.place_of_birth,
      country_of_birth: this.model.country_of_birth,
      date_of_birth: this.datePipe.transform(this.model.dob, 'yyyy-MM-dd'),
      dob: this.model.dob,
      choices: Array.from(this.model.choices),
      artistic_activities: this.model.artistic_activities,

      // Files
      artist_documents: [],

      // Biography fields
      education: this.model.education,
      awards_and_accolades: this.model.awards_and_accolades,
      solo_exhibitions: this.model.solo_exhibitions,
      group_exhibitions: this.model.group_exhibitions,
      collection: this.model.collection,
      cooperation: this.model.cooperation,
      statement: this.model.statement,
    };

    // Add Portrait  to request body handle deletion
    if (this.model.portrait.length > 0) {
      if (typeof this.model.portrait[0].name === 'string') {
        requestBody.portrait = this.model.portrait[0];
      } else {
        requestBody.portrait = undefined;
      }

      if (this.model.portrait[0].delete) {
        this.apiService.apiV1UserArtistPortraitDestroy$Response().subscribe(
          () => {},
          (error) => {
            this.displayLoadingIndicator = false;
            // console.log(error.error);

            // report via Sentry
            if (error && error.stack && error.message) {
              Sentry.captureException(error, {
                tags: {
                  api: 'apiV1UserArtistPortraitDestroy',
                },
              });
            } else {
              Sentry.captureException(new Error(error?.message), {
                tags: {
                  api: 'apiV1UserArtistPortraitDestroy',
                  requestStatus: error?.status,
                  requestError: error?.name,
                  requestUrl: error?.url,
                },
              });
            }
          }
        );
      }
    }

    // Add Degree  to request body and handle deletion
    if (this.model.degree.length > 0) {
      if (this.model.degree && typeof this.model.degree[0].name === 'string') {
        requestBody.degree = this.model.degree[0];
      } else {
        requestBody.degree = undefined;
      }

      if (this.model.degree[0].delete) {
        this.apiService.apiV1UserArtistDegreeDestroy$Response().subscribe(
          () => {},
          (error) => {
            this.displayLoadingIndicator = false;
            // console.log(error.error);

            // report via Sentry
            if (error && error.stack && error.message) {
              Sentry.captureException(error, {
                tags: {
                  api: 'apiV1UserArtistDegreeDestroy',
                },
              });
            } else {
              Sentry.captureException(new Error(error?.message), {
                tags: {
                  api: 'apiV1UserArtistDegreeDestroy',
                  requestStatus: error?.status,
                  requestError: error?.name,
                  requestUrl: error?.url,
                },
              });
            }
          }
        );
      }
    }

    this.model.artist_documents.forEach((document) => {
      if (document.hasOwnProperty('delete')) {
        if (document.delete === true) {
          this.apiService.apiV1UserArtistDocumentsDestroy$Response({ id: document.id }).subscribe(
            () => {},
            (error) => {
              this.showErrorOnSaveModal = true;
              this.displayLoadingIndicator = false;
              // console.log(error.error);

              // report via Sentry
              if (error && error.stack && error.message) {
                Sentry.captureException(error, {
                  tags: {
                    api: 'apiV1UserArtistDocumentsDestroy',
                  },
                });
              } else {
                Sentry.captureException(new Error(error?.message), {
                  tags: {
                    api: 'apiV1UserArtistDocumentsDestroy',
                    requestStatus: error?.status,
                    requestError: error?.name,
                    requestUrl: error?.url,
                  },
                });
              }
            }
          );
        }
      } else {
        requestBody.artist_documents.push(document);
      }
    });

    const artistVideos = [];

    this.model.vimeoVideos.forEach((video) => {
      // TODO -- Remove if condition (apiV1UserArtistProfilesUpdate$FormData$Response) API
      // TODO -- is updated to accept files marked for deletion.
      // TODO -- https://greyrook.atlassian.net/browse/ART-590
      if (!video.delete) {
        if (video.hasOwnProperty('url')) {
          artistVideos.push({ id: video.id, url: video.url, description: video.description });
        }
      }
    });

    requestBody.artist_videos = JSON.stringify(artistVideos);

    // TODO -- Remove once (apiV1UserArtistProfilesUpdate$FormData$Response) API is updated to accept files marked for deletion.
    // TODO -- https://greyrook.atlassian.net/browse/ART-590
    this.model.vimeoVideos.forEach((video) => {
      if (video.delete) {
        this.apiService.apiV1UserArtistVideosDestroy$Response({ id: video.id }).subscribe(
          () => {},
          (error) => {
            this.displayLoadingIndicator = false;
            // console.log(error.error);

            // report via Sentry
            if (error && error.stack && error.message) {
              Sentry.captureException(error, {
                tags: {
                  api: 'apiV1UserArtistVideosDestroy',
                },
              });
            } else {
              Sentry.captureException(new Error(error?.message), {
                tags: {
                  api: 'apiV1UserArtistVideosDestroy',
                  requestStatus: error?.status,
                  requestError: error?.name,
                  requestUrl: error?.url,
                },
              });
            }
          }
        );
      }
    });

    this.apiService
      .apiV1UserArtistProfilesUpdate$FormData$Response({ body: requestBody })
      .subscribe(
        (response) => {
          this.displayLoadingIndicator = false;
          this.success = true;

          this.formatDataStructure(response.body);

          setTimeout(() => {
            location.reload();
          }, 0);
        },
        (error) => {
          this.showErrorOnSaveModal = true;
          this.displayLoadingIndicator = false;
          // console.log(error.error);

          // report via Sentry
          if (error && error.stack && error.message) {
            Sentry.captureException(error, {
              tags: {
                api: 'apiV1UserArtistProfilesUpdate',
              },
            });
          } else {
            Sentry.captureException(new Error(error?.message), {
              tags: {
                api: 'apiV1UserArtistProfilesUpdate',
                requestStatus: error?.status,
                requestError: error?.name,
                requestUrl: error?.url,
              },
            });
          }
        }
      );
  }

  // Reset function
  onReset() {
    this.resetSuccess = true;
    this.options.formState = 'reset';
    this.options.resetModel();
    this.form.updateValueAndValidity();
    this.form.markAsPristine();

    setTimeout(() => {
      this.resetSuccess = false;
      this.options.formState = {};
    }, 2000);
  }

  // Callback for clicking 'OK' on backend error modal
  acknowledgeError = () => {
    this.showErrorOnSaveModal = false;
  };
}
