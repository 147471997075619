import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-repeat-section',
  templateUrl: './formly-repeat-section.component.html',
  styleUrls: ['./formly-repeat-section.component.scss'],
})
export class FormlyRepeatSectionComponent extends FieldArrayType implements OnInit {
  ngOnInit(): void {}

  markForDeletion(i) {
    const temp = [];
    this.formControl.value.forEach((element, index) => {
      if (index === i) {
        element.delete = true;
      }
      temp.push(element);
    });

    this.formControl.setValue(temp);
  }

  unmarkForDeletion(i) {
    const temp = [];
    this.formControl.value.forEach((element, index) => {
      if (index === i) {
        element.delete = false;
      }
      temp.push(element);
    });

    this.formControl.setValue(temp);
  }
}
