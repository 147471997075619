import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subpage',
  templateUrl: './subpage.component.html',
  styleUrls: ['./subpage.component.scss'],
})
export class SubpageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
