import { UntypedFormControl, ValidationErrors } from '@angular/forms';

const urlRegex = new RegExp('^(https?|http)://[^s$.?#].[^s]*$');

const numbersRegex = new RegExp('^[0-9-/]*$');

const vimeoUrlRegex = new RegExp('^(http|https)?:\\/\\/(www\\.)?vimeo\\.com\\/(\\d+)(?:|\\/\\?)');

const decimalNumberRegex = new RegExp('^[0-9]+((\\.|,)[0-9]{1,})?$');

const year = new RegExp('^[12][0-9]{3}$');

export class FormlyValidators {
  static url(control: UntypedFormControl): ValidationErrors {
    return !control.value || urlRegex.test(control.value) ? null : { url: true };
  }

  static numbers(control: UntypedFormControl): ValidationErrors {
    return !control.value || numbersRegex.test(control.value) ? null : { numbers: true };
  }

  static vimeoUrl(control: UntypedFormControl): ValidationErrors | null {
    if (!vimeoUrlRegex.test(control.value) && !!control.value) {
      return { vimeoUrl: true };
    } else {
      return null;
    }
  }

  static validDimensionFormat(control: UntypedFormControl): ValidationErrors | null {
    if (!decimalNumberRegex.test(control.value) && !!control.value) {
      return { validDimensionFormat: true };
    } else {
      return null;
    }
  }

  static validYear(control: UntypedFormControl): ValidationErrors | null {
    if (!year.test(control.value)) {
      return { validYear: true };
    } else {
      return null;
    }
  }
}
