<div class="content-wrapper">
  <div *ngIf="verificationStatus === 'pending'" class="pending">
    <p>Verifying...</p>
  </div>
  <div *ngIf="verificationStatus === 'success'" class="success">
    <img src="/assets/artronaut19_illu_peace.png" alt="Illustration depicting success" />
    <p>
      <span class="highlight">YOUR ACCOUNT WAS VALIDATED.</span><br />
      To log in, click the button above.
    </p>
  </div>
  <div *ngIf="verificationStatus === 'error'" class="error">
    <img src="/assets/bigheadnaut_moonfishing.png" alt="Illustration depicting failure" />
    <p>There was an error.</p>
  </div>
</div>
