import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CookieService } from '../../services/cookie-service/cookie-service.service';
import { ProfileStatusService } from '../../services/profile-status/profile-status.service';
@Injectable({
  providedIn: 'root',
})
export class ProfileEditorGuard implements CanActivate {
  loggedIn: boolean;
  has_profile: boolean;

  constructor(
    private route: Router,
    private cookieService: CookieService,
    private profileStatus: ProfileStatusService
  ) {}

  canActivate(): any {
    this.loggedIn = !!this.cookieService.getCookie('userType');
    this.has_profile = !!this.cookieService.getCookie('userProfile');
    if (this.has_profile || !this.loggedIn || this.cookieService.getCookie('userType') === 'null') {
      return true;
    } else {
      this.profileStatus.profileLess.next(true);
      if (this.cookieService.getCookie('userType') === 'artist') {
        this.route.navigate(['/artist-editor']);
      } else if (this.cookieService.getCookie('userType') === 'user') {
        this.route.navigate(['/user-editor']);
      }
    }
  }
}
