<div class="artwork-zoom-wrapper">
  <div class="close-button" (click)="closeZoomView()">
    <img src="/assets/icons/x.svg" alt="close window" />
  </div>
  <div class="zoom-controll">
    <div class="zoom-button" (click)="zoomOut()">
      <img src="/assets/icons/minus.svg" alt="zoom out" />
    </div>
    <div class="slider">
      <p-slider [(ngModel)]="sliderValue" [step]="stepSize" (onChange)="slide($event)"></p-slider>
    </div>
    <div class="zoom-button" (click)="zoomIn()">
      <img src="/assets/icons/plus.svg" alt="zoom in" />
    </div>
  </div>

  <div class="pinch-wrap">
    <pinch-zoom
      #myPinch
      backgroundColor="#fff"
      autoHeight="true"
      [wheel]="false"
      [zoomControlScale]="scaleFactor"
      limit-zoom="scaleLimit"
      disableZoomControl="disable"
    >
      <div id="image-zoom-container" [style.backgroundImage]="'url(' + image + ')'"></div>
    </pinch-zoom>
  </div>
</div>
