import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wagtail-image',
  templateUrl: './wagtail-image.component.html',
  styleUrls: ['./wagtail-image.component.scss'],
})
export class WagtailImageComponent implements OnInit {
  @Input() entries;
  @Input() styleExtra: string | undefined = '';
  @Input() classExtra: string | undefined;
  constructor() {}

  ngOnInit(): void {}
}
