/* eslint-disable no-underscore-dangle */
import { Component, Input, OnInit } from '@angular/core';
import { MenuService } from '../../services/menu/menu.service';
import { UserDataService } from '../../services/user-data/user-data.service';
import { SignupInStatusService } from '../../services/signup-in-status/signup-in-status.service';
import { ApiService } from '../../services/api/services';
import { CookieService } from '../../services/cookie-service/cookie-service.service';
import { ProfileEditorLinkService } from '../../services/profile-editor-link/profile-editor-link.service';
import { Router } from '@angular/router';
import { User } from '../../../shared/interfaces/user';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { setUser } from 'src/app/store/app.actions';

// ART-545, for beta only - this is probably not DSGVO compliant
import * as Sentry from '@sentry/angular';
import { ThemeService } from '../../services/theme/theme.service';

@Component({
  selector: 'app-navigationbar',
  templateUrl: './navigationbar.component.html',
  styleUrls: ['./navigationbar.component.scss'],
})
export class NavigationbarComponent implements OnInit {
  menus;

  userFirstName: string;
  userProfileType: string;

  loggedIn: boolean;

  subMenuActive?: number;
  userMenuActive = false;
  burgerActive = false;

  windowWidth: number = window.innerWidth;

  editorLink: string;

  constructor(
    private _menuService: MenuService,
    private _userService: UserDataService,
    private signupInStatusService: SignupInStatusService,
    private apiService: ApiService,
    private cookieService: CookieService,
    private _profileLinkService: ProfileEditorLinkService,
    public themeService: ThemeService,
    private router: Router,
    private store: Store<AppState>
  ) {
    this._userService.userFirstName.subscribe((value) => (this.userFirstName = value));
    this.signupInStatusService.loggedIn.subscribe((value) => (this.loggedIn = value));
  }

  openSignin = () => this.signupInStatusService.toggleSignin();
  openLogin = () => this.signupInStatusService.toggleLogin();

  ngOnInit(): void {
    this.checkWindowWidth();
    this.checkLoggedIn();
    this._menuService.combinedMenu.subscribe((data) => {
      this.menus = data;
    });
    this.editorLink = this._profileLinkService.getLink();
  }

  checkWindowWidth() {
    this.windowWidth = window.innerWidth;
    if (window.innerWidth >= 1201) {
      this.burgerActive = true;
    } else {
      this.burgerActive = false;
    }
  }

  closeMenus() {
    if (window.innerWidth < 1201) {
      this.burgerActive = false;
    }
    this.subMenuActive = undefined;
    this.userMenuActive = false;
  }

  setUserMenuActive = () => {
    this.subMenuActive = undefined;
    this.userMenuActive = !this.userMenuActive;
    this.editorLink = this._profileLinkService.getLink();
  };

  setBurgerActive = () => {
    this.subMenuActive = undefined;
    this.burgerActive = !this.burgerActive;
  };

  setMenuActive = (i: number) => {
    this.subMenuActive = this.subMenuActive === i ? undefined : i;
    this.userMenuActive = this.userMenuActive ? false : null;
  };

  checkLoggedIn() {
    this.apiService.apiV1UserVerifySessionRetrieve().subscribe((rObj: any) => {
      if (rObj.is_authenticated) {
        if (!this.cookieService.getCookie('userType')) {
          this.cookieService.setCookie('userType', rObj.user_profile_type, { secure: true });
        }

        if (rObj.user_profile_type === 'artist') {
          this.apiService.apiV1UserArtistProfilesRetrieve$Response().subscribe(
            (r) => {
              const data: any = r.body;
              this._userService.userFirstName.next(data.user_data.first_name);

              // ART-545 - to be changed/remove before going live
              Sentry.setUser({ email: data.user_data.email });

              const userLoggedIn: User = {
                firstName: data.user_data.first_name,
                lastName: data.user_data.last_name,
                profileType: rObj.user_profile_type,
                profileId: data.id,
                userId: data.user,
              };
              this.store.dispatch(setUser(userLoggedIn));
            },
            (error) => {
              console.log(error.error);
            }
          );
        } else {
          this.apiService.apiV1UserUserProfilesRetrieve$Response().subscribe(
            (r) => {
              const data: any = r.body;

              this._userService.userFirstName.next(data.user_data.first_name);

              // ART-545 - to be changed/remove before going live
              Sentry.setUser({ email: data.user_data.email });

              const profileType = rObj.user_profile_type ? rObj.user_profile_type : 'admin';

              const userLoggedIn: User = {
                firstName: data.user_data.first_name,
                lastName: data.user_data.last_name,
                profileType,
                profileId: data.id,
                userId: data.id,
              };
              this.store.dispatch(setUser(userLoggedIn));
            },
            (error) => {
              console.log(error.error);
            }
          );
        }

        this.signupInStatusService.setLoggedIn(true);
      } else {
        this.signupInStatusService.setLoggedIn(false);

        // ART-545 - to be changed/remove before going live
        Sentry.configureScope((scope) => scope.setUser(null));

        this.store.dispatch(setUser(null));
      }
    });
  }

  logOut() {
    this.apiService
      // eslint-disable-next-line @typescript-eslint/naming-convention
      .apiV1UserLogoutCreate$FormData$Response({ body: { revoke_token: false } })
      .subscribe(
        (r) => {
          this.cookieService.deleteCookie('userFirstName');
          this.cookieService.deleteCookie('userProfile');
          this.cookieService.deleteCookie('userType');
          this._userService.profileIdCached = undefined;
          this._userService.userIdCached = undefined;
          this.signupInStatusService.setLoggedIn(false);
          this.userMenuActive = false;
          void this.router.navigate(['/']);

          // ART-545 - to be changed/remove before going live
          Sentry.configureScope((scope) => scope.setUser(null));
          this.store.dispatch(setUser(null));
        },
        (error) => {
          console.log(error.error);
          void this.router.navigate(['/']);
        }
      );
  }
}
