import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'overlay-container',
  templateUrl: './overlay-container.component.html',
  styleUrls: ['./overlay-container.component.scss'],
})
export class OverlayContainerComponent implements OnInit {
  constructor() {}
  @Input() open = true;
  @Output() closed = new EventEmitter();

  onClick() {
    this.open = false;
    this.closed.emit();
  }
  ngOnInit(): void {}
}
