import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArtworksList, Award, NominatedArtworks } from 'src/app/core/services/api/models';
import { ApiService } from 'src/app/core/services/api/services';
import { CookieService } from 'src/app/core/services/cookie-service/cookie-service.service';
@Component({
  selector: 'app-artworks',
  templateUrl: './artworks.component.html',
  styleUrls: ['./artworks.component.scss'],
})
export class ArtworksComponent implements OnInit {
  artworks: Array<NominatedArtworks> = [];
  artworksList: Array<ArtworksList> = [];
  // overall awards
  awards: any = [];

  // award voting session
  voting = false;
  award: Award;
  loggedIn: boolean;
  canVote: boolean = undefined;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private cookieService: CookieService
  ) {
    this.loggedIn = !!this.cookieService.getCookie('userType');
    const awardId = parseInt(this.route.snapshot.paramMap.get('awardId'), 10);

    if (awardId) {
      // get award
      this.apiService.apiV1AwardsAwardsRetrieve({ id: awardId }).subscribe((r) => {
        this.voting = true;
        this.award = r;
        this.canVote = this.award.can_vote;
      });

      // get artworks nominated for award
      this.apiService.apiV1AwardsNominationsList({ award_id: awardId }).subscribe((r) => {
        this.artworks = r;
      });
    } else {
      // get all artworks
      this.apiService.apiV1ArtworkArtworksList().subscribe((r) => {
        this.artworksList = r;
      });
    }

    this.apiService.apiV1AwardsAwardsRetrieve$Response().subscribe((r) => {
      let rawAwards: any = [];
      rawAwards = r.body;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      this.awards = rawAwards.filter(({ user_can_apply }) => user_can_apply === true);
    });
  }

  ngOnInit(): void {}
}
