import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl',
})
export class SafeUrlPipe implements PipeTransform {
  constructor(protected _sanitizer: DomSanitizer) {}

  transform(value: any, args?: any): any {
    const url = value.replace('vimeo.com/', 'player.vimeo.com/video/');

    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
