<div class="glide">
  <div class="glide__track" data-glide-el="track">
    <ul class="glide__slides">
      <li class="glide__slide" *ngFor="let artwork of data">
        <div
          class="image"
          [ngStyle]="{
            'background-image':
              'url(' + (artwork.artwork_image.url | relativePathToBackendUrl) + ')'
          }"
        ></div>
        <div class="content-wrapper">
          <div class="meta">
            {{ artwork.artwork_data.creator_fname }}
            {{ artwork.artwork_data.creator_lname }} |
            <span id="title">{{ artwork.artwork_data.title }}</span> |
            {{ artwork.artwork_data.year }} |
            {{ artwork.artwork_data.technique | resolveTechnique }} |
            {{
              artwork.artwork_data.saved_unit === 'IN'
                ? (artwork.artwork_data.width | cmToIn)
                : artwork.artwork_data.width
            }}
            x
            {{
              artwork.artwork_data.saved_unit === 'IN'
                ? (artwork.artwork_data.height | cmToIn)
                : artwork.artwork_data.height
            }}
            {{ artwork.artwork_data.saved_unit | resolveUnitOfLength }}
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="scroller-wrapper">
    <div class="scroller" (click)="scroll()">
      <img src="/assets/scroller-arrow.svg" alt="arrow" />
    </div>
  </div>
</div>
