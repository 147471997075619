import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PriceRangeService {
  priceRange = [
    { value: '50+', text: '50K+' },
    { value: '25-50', text: '25k - 50k' },
    { value: '10-25', text: '10k-25k' },
    { value: '1-5', text: '1k-5k' },
    { value: '0-1', text: '0-1k' },
  ];
}
