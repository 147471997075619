import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

export interface CanDirtyCheck {
  warnerComponent: WarnOnUnsavedComponent;
}

@Component({
  selector: 'app-warn-on-unsaved',
  templateUrl: './warn-on-unsaved.component.html',
  styleUrls: ['./warn-on-unsaved.component.scss'],
})
export class WarnOnUnsavedComponent implements OnInit, OnDestroy {
  @Input() dirty: boolean;

  visible = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  beforeunloadHandler(e) {
    if (!this.dirty) {
      delete e.returnValue;
      return '';
    }

    // This message is not displayed in resent browsers, but the browsers default message for such case.
    const confirmationMessage =
      'It looks like you have been editing something. ' +
      'If you leave before saving, your changes will be lost.';

    (e || window.event).returnValue = confirmationMessage; // Gecko + IE
    return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
  }

  beforeunloadHandlerBind = (e) => this.beforeunloadHandler(e);

  ngOnInit(): void {
    window.addEventListener('beforeunload', this.beforeunloadHandlerBind);
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.beforeunloadHandlerBind);
  }

  dirtyCheck(): boolean {
    return this.dirty;
  }

  navigateToSave(): void {
    this.visible = true;
    this.changeDetectorRef.detectChanges();
    const messageArea = document.getElementById('save-button-area');
    messageArea.scrollIntoView({ block: 'center' });
  }
}
