import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../api/services';
import { CookieService } from '../cookie-service/cookie-service.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  userFirstName: Subject<string> = new Subject<string>();
  userLastName: Subject<string> = new Subject<string>();
  profileId: Subject<number> = new Subject<number>();
  userId: Subject<number> = new Subject<number>();
  // TODO: caching does not work properly/is not fast enough - multiple requests get send at once
  profileIdCached?: number;
  userIdCached?: number;

  constructor(private apiService: ApiService, private cookieService: CookieService) {}

  async fetchIds() {
    if (this.profileIdCached && this.userIdCached) {
      this.profileId.next(this.profileIdCached);
      this.profileId.next(this.userIdCached);
      return;
    }

    let userObserver;
    switch (this.cookieService.getCookie('userType')) {
      case 'artist':
        userObserver = this.apiService.apiV1UserArtistProfilesRetrieve$Response();
        break;
      case 'user':
        userObserver = this.apiService.apiV1UserUserProfilesRetrieve$Response();
        break;
      default:
        return;
    }

    await userObserver.subscribe((r) => {
      this.profileId.next(r.body.id);
      this.userId.next(r.body.user);
      this.profileIdCached = r.body.id;
      this.userIdCached = r.body.user;
    });
  }
}
