import { AfterViewInit, Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-wagtail-paragraph',
  templateUrl: './wagtail-paragraph.component.html',
  styleUrls: ['./wagtail-paragraph.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WagtailParagraphComponent implements AfterViewInit {
  @Input() entries;
  @Input() fontSize: number;
  @Input() color: string;
  @Input() border: boolean;
  @Input() borderColor: string;
  @Input() margin: string;
  @Input() padding: string;
  @Input() id;
  @Input() styleExtra: string | undefined;
  @Input() classExtra: string | undefined;

  constructor(private sanitizer: DomSanitizer) {}

  ngAfterViewInit(): void {
    // Set font size to p tag and b tag within one paragraph block defined by the id provided by wagtail
    const rootTag = document.documentElement.getElementsByClassName(this.id)[0];

    const pTag = rootTag.getElementsByTagName('p');

    if (this.border) {
      rootTag.setAttribute(
        'style',
        rootTag.getAttribute('style') + '; ' + 'border: 1px solid ' + this.borderColor + ';'
      );
    }
    if (this.margin) {
      rootTag.setAttribute('style', rootTag.getAttribute('style') + '; ' + this.margin + ';');
    }

    if (this.padding) {
      rootTag.setAttribute('style', rootTag.getAttribute('style') + '; ' + this.padding + ';');
    }

    for (let i = 0; i <= pTag.length - 1; i++) {
      pTag[i].setAttribute(
        'style',
        `color: ${this.color} !important; font-size: ${this.fontSize}px !important; ${this.styleExtra}`
      );

      if (pTag[i].getElementsByTagName('b').length) {
        const bTag = pTag[i].getElementsByTagName('b');
        for (let j = 0; j <= bTag.length - 1; j++) {
          bTag[j].setAttribute('style', `font-size: ${this.fontSize}px !important`);
        }
      }

      if (pTag[i].getElementsByTagName('i').length) {
        const bTag = pTag[i].getElementsByTagName('i');
        for (let j = 0; j <= bTag.length - 1; j++) {
          bTag[j].setAttribute('style', `font-size: ${this.fontSize}px !important`);
        }
      }

      if (pTag[i].getElementsByTagName('a').length) {
        const aTag = pTag[i].getElementsByTagName('a');
        for (let k = 0; k <= aTag.length - 1; k++) {
          aTag[k].setAttribute(
            'style',
            `font-size: ${this.fontSize}px !important; color: ${this.color} !important;`
          );
        }
      }
    }

    if (this.classExtra) {
      rootTag.setAttribute('class', rootTag.getAttribute('class') + ' ' + this.classExtra + ' ');
    }
  }
}
