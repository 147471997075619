import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  data: any;

  constructor(private route: ActivatedRoute, protected sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.route.data.pipe(map((dat) => dat.cmsData)).subscribe((r) => {
      this.data = r;
    });
  }
}
