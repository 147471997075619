<header>
  <h2>Artist data</h2>
  <app-profile-message></app-profile-message>
</header>

<form id="user-form" [formGroup]="form">
  <h3 class="mandatory-note">Please fill in the mandatory fields marked with a star *</h3>

  <formly-form [form]="form" [fields]="fields" [options]="options" [model]="model"></formly-form>

  <!-- Warning pop up when navigating without saving form -->
  <!-- !! NgIf has been added here to allow reloading on successful save -->
  <app-warn-on-unsaved
    *ngIf="!success"
    [dirty]="!form.pristine || form.dirty"
    id="warn-on-unsaved"
  ></app-warn-on-unsaved>

  <!-- Error for not filling out mandatory fields -->
  <div id="warnOnErrors" *ngIf="errors">
    Please fill in the mandatory fields marked with a star *
  </div>

  <div class="buttons">
    <!-- Reset button -->
    <app-field-button text="Reset" (click)="onReset()" [success]="resetSuccess">
      Reset
    </app-field-button>

    <!-- Submit button -->
    <app-field-button
      text="Submit"
      (click)="onSubmit()"
      [success]="success"
      [iconUrl]="'/assets/icons/open_icon.svg'"
    >
      Submit
    </app-field-button>
  </div>
</form>

<!-- Modal on backend error -->
<app-modal
  [show]="showErrorOnSaveModal"
  header="There was a problem"
  [buttonTexts]="['Ok']"
  [buttonFunctions]="[acknowledgeError]"
  [buttonIcons]="['/assets/icons/open_icon.svg']"
>
  <p>Whoops, something went wrong on our side. Please try again later.</p>
</app-modal>

<!-- Loading indicator -->
<app-loading-indicator [display]="displayLoadingIndicator"></app-loading-indicator>
