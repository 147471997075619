<div [class]="classExtra" [style]="styleExtra">
  <div *ngFor="let entry of entries">
    <img
      class="image"
      [src]="entry.image_url | relativePathToBackendUrl"
      [alt]="entry.title"
      [style]="entry.style_extra"
      [class]="entry.class_extra"
    />
  </div>
</div>
