import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-field-text',
  templateUrl: './field-text.component.html',
  styleUrls: ['./field-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldTextComponent),
      multi: true,
    },
  ],
})
export class FieldTextComponent implements OnInit {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() id = '';

  @Input() parentForm: UntypedFormGroup;

  @Input() hint = '';
  @Input() required = false;

  @Input() theme = 'light';
  @Input() footprint = 'small';
  @Input() hideRequired = false;

  value = '';
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {}

  ngOnInit(): void {}

  printLabel() {
    if (this.required) {
      return this.label + ' *';
    } else {
      return this.label;
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
