import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  category = [
    { value: 'PA', text: 'Painting' },
    { value: 'SC', text: 'Sculpture' },
    { value: 'OB', text: 'Object' },
    { value: 'DR', text: 'Drawing' },
    { value: 'CP', text: 'Collage or other work on paper' },
    { value: 'PH', text: 'Photography' },
    { value: 'VI', text: 'Video' },
    { value: 'FI', text: 'Film' },
    { value: 'ANI', text: 'Animation' },
    { value: 'PR', text: 'Performance art' },
    { value: 'PRI', text: 'Print' },
    { value: 'INS', text: 'Installation' },
    { value: 'TEXTART', text: 'Textile Arts' },
    { value: 'JEWE', text: 'Jewellery' },
    { value: 'ARCH', text: 'Architecture' },
    { value: 'DE', text: 'Design' },
    { value: 'DEA', text: 'Decorative art' },
  ];
}
