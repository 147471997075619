import { ComponentRef } from '@angular/core';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CanDirtyCheck } from 'src/app/shared/components/warn-on-unsaved/warn-on-unsaved.component';

@Injectable({
  providedIn: 'root',
})
export class WarnOnUnsavedGuard implements CanDeactivate<unknown> {
  canDeactivate(
    component: CanDirtyCheck,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.warnerComponent.dirtyCheck()) {
      component.warnerComponent.navigateToSave();
    }
    return !component.warnerComponent.dirtyCheck();
  }
}
