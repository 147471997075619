<!-- Galleria view -->
<div [class]="classExtra">
  <gallery
    *ngIf="type === 'galleria'"
    style="height: 60vh; width: 100%"
    [items]="images$ | async"
    imageSize="contain"
    [dots]="true"
    [counter]="false"
    [thumb]="true"
    gallerize
  ></gallery>

  <!-- Masonry view -->
  <div
    class="masonry"
    *ngIf="type === 'masonry' || type === undefined"
    [style]="styleExtra"
    gallerize
    selector=".gallery-img"
  >
    <div *ngFor="let item of images; let index = index" class="content">
      <img
        class="gallery-img"
        [src]="item.image_url | relativePathToBackendUrl"
        [attr.imageSrc]="item.image_url | relativePathToBackendUrl"
        [attr.thumbSrc]="item.image_url | relativePathToBackendUrl"
        style="cursor: pointer"
        [style]="item.style_extra"
      />
    </div>
  </div>
</div>
