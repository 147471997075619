import { Injectable } from '@angular/core';
import { CookieService } from '../cookie-service/cookie-service.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileEditorLinkService {
  constructor(private cookieService: CookieService) {}

  getLink() {
    const userType = this.cookieService.getCookie('userType');

    if (userType === 'artist') {
      return '/artist-editor';
    } else {
      return '/user-editor';
    }
  }
}
