<div class="glide_news">
  <div class="glide__track" data-glide-el="track">
    <ul class="glide__slides">
      <li class="glide__slide" *ngFor="let newsItem of data">
        <div
          class="news_image"
          [style]="
            'background-image: url(' + (newsItem.news_list.url | relativePathToBackendUrl) + ');'
          "
        ></div>
        <div class="news_text-wrapper">
          <div class="news_text-block">
            <div class="head">
              <div>
                {{ newsItem.news_list.date.day }}.{{ newsItem.news_list.date.month }}.{{
                  newsItem.news_list.date.year
                }}.
              </div>
              <app-share-buttons
                [imageUrl]="newsItem.news_list.url"
                text=""
                [link]="newsItem.news_list.link"
                [title]="newsItem.news_list.title"
              ></app-share-buttons>
            </div>
            <h2>{{ newsItem.news_list.title }}</h2>
            <div [innerHtml]="newsItem.news_list.description"></div>
          </div>
          <div class="news_text-block_overlay"></div>
          <div class="btn_wrapper">
            <app-button [url]="newsItem.news_list.link" text="Read more"></app-button>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="glide__arrows" data-glide-el="controls">
    <button class="glide__arrow glide__arrow--left" data-glide-dir="<"></button>
    <button class="glide__arrow glide__arrow--right" data-glide-dir=">"></button>
  </div>
</div>
