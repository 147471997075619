import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AvailabilityService {
  availability = [
    { value: 'AVA', text: 'Available' },
    { value: 'SO', text: 'Sold' },
    { value: 'RE', text: 'Reserved' },
    { value: 'LO', text: 'Loaned' },
    { value: 'EXH', text: 'Exhibited' },
  ];
}
