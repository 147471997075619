/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/unbound-method */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Provider, forwardRef } from '@angular/core';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './modules/home/home.component';
import { CarouselComponent } from './shared/components/carousel/carousel.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { HeroImageComponent } from './shared/components/hero-image/hero-image.component';
import { TextBoxComponent } from './shared/components/text-box/text-box.component';
import { ArtAwardCallComponent } from './shared/components/art-award-call/art-award-call.component';
import { SponsorsComponent } from './shared/components/sponsors/sponsors.component';
import { NewsComponent } from './shared/components/news/news.component';
import { ButtonComponent } from './shared/components/button/button.component';

import { WagtailModule } from 'angular-wagtail';
import { MultiSelectModule } from 'primeng/multiselect';
import { SafeHTMLPipe } from './shared/pipes/safe-html.pipe';
import { PreviewModule } from './core/components/preview/preview.module';
import { StoreModule } from '@ngrx/store';
import { config } from './app.config';
import { FieldTextComponent } from './shared/components/field-text/field-text.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldTextareaComponent } from './shared/components/field-textarea/field-textarea.component';
import { FieldCheckboxComponent } from './shared/components/field-checkbox/field-checkbox.component';
import { FieldButtonComponent } from './shared/components/field-button/field-button.component';

import { UserLoginComponent } from './shared/components/user-login/user-login.component';
import { ToggleDirective } from './shared/components/toggle.directive';
import { OverlayContainerComponent } from './shared/components/overlay-container/overlay-container.component';
import { UserSignupComponent } from './shared/components/user-signup/user-signup.component';
import { DropzoneDirective } from './shared/directives/dropzone.directive';
import { NavigationbarComponent } from './core/components/navigationbar/navigationbar.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { ResetPasswordComponent } from './shared/components/reset-password/reset-password.component';
import { VerifyUserComponent } from './modules/verify-user/verify-user.component';
import { CheckboxGroupComponent } from './shared/components/checkbox-group/checkbox-group.component';
import { CheckboxComponent } from './shared/components/checkbox-group/checkbox/checkbox.component';

import { ApiInterceptor } from './core/services/api-interceptor';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';
import { RelativePathToBackendUrlPipe } from './shared/pipes/relative-path-to-backend-url.pipe';
import { ResolveTechniquePipe } from './shared/pipes/resolve-technique.pipe';
import { ResolveUnitOfLengthPipe } from './shared/pipes/resolve-unit-of-length.pipe';
import { CmToInPipe } from './shared/pipes/cm-to-in.pipe';

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true,
};
import { SubpageComponent } from './shared/components/subpage/subpage.component';
import { ArtistViewComponent } from './modules/artist-view/artist-view.component';
import { TextblockComponent } from './shared/components/textblock/textblock.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MasonryComponent } from './shared/components/masonry/masonry.component';
import { NewlineToBrPipe } from './shared/pipes/newline-to-br.pipe';
import { BaseService } from './core/services/api/base-service';
import { ArtworkViewComponent } from './modules/artwork-view/artwork-view.component';
import { GenericPageComponent } from './modules/generic-page/generic-page.component';
import { ArtistsComponent } from './modules/artists/artists.component';
import { ArtworksComponent } from './modules/artworks/artworks.component';
import { ResolveMaterialPipe } from './shared/pipes/resolve-material.pipe';
import { MainArtworkImagePipe } from './shared/pipes/main-artwork-image.pipe';
import { ProfileEditorLinkService } from './core/services/profile-editor-link/profile-editor-link.service';
import { PinchZoomModule } from './vendors/ivypinch_pro_ng8+/pinch-zoom.module';
import { SliderModule } from 'primeng/slider';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ArtworkZoomComponent } from './modules/artwork-zoom/artwork-zoom.component';
import { LoadingIndicatorComponent } from './shared/components/loading-indicator/loading-indicator.component';
import { CalendarModule } from 'primeng/calendar';
import { AuthGuard } from './core/guards/auth.guard';
import { VoteButtonComponent } from './shared/components/vote-button/vote-button.component';
import { GalleriaModule } from 'primeng/galleria';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { CarouselModule } from 'primeng/carousel';
import { GalleriaBlockComponent } from './shared/components/galleria-block/galleria-block.component';
import { WagtailGridLayoutComponent } from './shared/components/wagtail-grid-layout/wagtail-grid-layout.component';
import { WagtailHeadingComponent } from './shared/components/wagtail-heading/wagtail-heading.component';
import { WagtailParagraphComponent } from './shared/components/wagtail-paragraph/wagtail-paragraph.component';
import { WagtailImageComponent } from './shared/components/wagtail-image/wagtail-image.component';
import { WagtailButtonComponent } from './shared/components/wagtail-button/wagtail-button.component';
import { WagtailGridBlockComponent } from './shared/components/wagtail-grid-block/wagtail-grid-block.component';
import { WagtailRawHtmlComponent } from './shared/components/wagtail-raw-html/wagtail-raw-html.component';

import { WarnOnUnsavedComponent } from './shared/components/warn-on-unsaved/warn-on-unsaved.component';
import { UserArtworkOverviewComponent } from './modules/user-artwork-overview/user-artwork-overview.component';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ArtworkStatusComponent } from './shared/components/artwork-status/artwork-status.component';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { ProfileEditorGuard } from './core/guards/profile-editor/profile-editor.guard';
import { ProfileMessageComponent } from './shared/components/profile-message/profile-message/profile-message.component';
import { SafeUrlPipe } from './shared/pipes/safe-url.pipe';
import { AwardTermsComponent } from './shared/components/award-terms/award-terms.component';
import { ChangePasswordComponent } from './shared/components/change-password/change-password.component';

// for storing state in a redux way
import * as appStore from './store/app.reducer';

// for improvement form
import { ListboxModule } from 'primeng/listbox';
import { InputTextareaModule } from 'primeng/inputtextarea';

// Sentry integration
import { environment } from './../environments/environment';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import SentryRRWeb from '@sentry/rrweb';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

// for formly switch
import { FormlyModule } from '@ngx-formly/core';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
import { FormlyUserEditorComponent } from './modules/formly-user-editor/formly-user-editor.component';
import { FormlyTextFieldComponent } from './shared/components/formly-text-field/formly-text-field.component';
import { FormlyMultiCheckboxFieldComponent } from './shared/components/formly-multi-checkbox-field/formly-multi-checkbox-field.component';
import { FormlyDropdownFieldComponent } from './shared/components/formly-dropdown-field/formly-dropdown-field.component';
import { FormlyCalendarFieldComponent } from './shared/components/formly-calendar-field/formly-calendar-field.component';

import { FormlyValidators } from './shared/form-validators/formly-validators';
import { FormlyTextareaFieldComponent } from './shared/components/formly-textarea-field/formly-textarea-field.component';
import { FormlyFileUploadFieldComponent } from './shared/components/formly-file-upload-field/formly-file-upload-field.component';
import { FileValueAccessorDirective } from './shared/directives/file-value-accessor-directive/file-value-accessor-directive.directive';
import { FormlyRepeatSectionComponent } from './shared/components/formly-repeat-section/formly-repeat-section.component';
import { DatePipe } from '@angular/common';
import { FormlyArtistEditorComponent } from './modules/formly-artist-editor/formly-artist-editor.component';
import { FormlyArtworkEditorComponent } from './modules/formly-artwork-editor/formly-artwork-editor.component';
import { FormlyCheckboxFieldComponent } from './shared/components/formly-checkbox-field/formly-checkbox-field.component';
import { ContactPageComponent } from './modules/contact-page/contact-page.component';
import { NewsIndexComponent } from './modules/news-index/news-index.component';
import { NewsPageComponent } from './modules/news-page/news-page.component';
import { ShareButtonsComponent } from './shared/components/share-buttons/share-buttons.component';
import { ImageLazyLoadDirective } from './shared/directives/image-lazy-load/image-lazy-load.directive';
import { FormlyMultiselectComponent } from './shared/components/formly-multiselect/formly-multiselect.component';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';

const sentryIntegrations = [];

// This enables automatic instrumentation (highly recommended)
if (environment.sentryDsn) {
  if (environment.sentryIsCaptureConsole) {
    sentryIntegrations.push(
      new CaptureConsoleIntegration({
        levels: environment.sentryCaptureConsoleLevels,
      })
    );
  }
  if (environment.sentryIsTracing) {
    sentryIntegrations.push(new TracingIntegrations.BrowserTracing());
  }
  if (environment.sentryIsRrweb) {
    sentryIntegrations.push(
      new SentryRRWeb({
        maskAllInputs: environment.sentryIsRrwebMaskInputs,
        recordCanvas: environment.sentryIsRrwebCanvas,
      })
    );
  }
  if (
    environment.sentryIsCaptureConsole ||
    environment.sentryIsTracing ||
    environment.sentryIsRrweb
  ) {
    Sentry.init({
      dsn: environment.sentryDsn,
      release: environment.sentryRelease,
      sampleRate: environment.sentrySampleRate,
      integrations: sentryIntegrations,
      tracesSampleRate: environment.sentryTracingRate,
    });

    Sentry.setTag('rrweb.active', environment.sentryIsRrweb ? 'yes' : 'no');
  } else {
    Sentry.init({
      dsn: environment.sentryDsn,
      release: environment.sentryRelease,
      sampleRate: environment.sentrySampleRate,
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CarouselComponent,
    HeroImageComponent,
    TextBoxComponent,
    SafeHTMLPipe,
    ArtAwardCallComponent,
    SponsorsComponent,
    NewsComponent,
    ButtonComponent,
    FieldTextComponent,
    FieldTextareaComponent,
    FieldCheckboxComponent,
    FieldButtonComponent,
    UserLoginComponent,
    ToggleDirective,
    OverlayContainerComponent,
    UserSignupComponent,
    DropzoneDirective,
    NavigationbarComponent,
    FooterComponent,
    ResetPasswordComponent,
    VerifyUserComponent,
    PageNotFoundComponent,
    CheckboxGroupComponent,
    CheckboxComponent,
    SubpageComponent,
    ArtistViewComponent,
    TextblockComponent,
    MasonryComponent,
    NewlineToBrPipe,
    RelativePathToBackendUrlPipe,
    ResolveTechniquePipe,
    ResolveUnitOfLengthPipe,
    CmToInPipe,
    ArtworkViewComponent,
    GenericPageComponent,
    ArtistsComponent,
    ArtworksComponent,
    ResolveMaterialPipe,
    MainArtworkImagePipe,
    LoadingIndicatorComponent,
    ArtworkZoomComponent,
    VoteButtonComponent,
    WarnOnUnsavedComponent,
    UserArtworkOverviewComponent,
    ArtworkStatusComponent,
    ModalComponent,
    ProfileMessageComponent,
    SafeUrlPipe,
    AwardTermsComponent,
    ChangePasswordComponent,
    GalleriaBlockComponent,
    FormlyUserEditorComponent,
    FormlyTextFieldComponent,
    FormlyTextareaFieldComponent,
    FormlyMultiCheckboxFieldComponent,
    FormlyDropdownFieldComponent,
    FormlyCalendarFieldComponent,
    WagtailGridLayoutComponent,
    WagtailHeadingComponent,
    WagtailParagraphComponent,
    WagtailImageComponent,
    WagtailButtonComponent,
    WagtailGridBlockComponent,
    FormlyFileUploadFieldComponent,
    FileValueAccessorDirective,
    FormlyRepeatSectionComponent,
    FormlyArtistEditorComponent,
    WagtailRawHtmlComponent,
    FormlyArtworkEditorComponent,
    FormlyCheckboxFieldComponent,
    ContactPageComponent,
    NewsIndexComponent,
    NewsPageComponent,
    ShareButtonsComponent,
    ImageLazyLoadDirective,
    FormlyMultiselectComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'app-root' }),
    FormsModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFTOKEN',
    }),
    WagtailModule.forRoot({
      wagtailSiteDomain: config.wagtailSiteDomain,
      pagesApiLimit: 200,
      pageTypes: [],
    }),
    WagtailModule.forFeature([
      {
        type: 'landing.LandingPage',
        component: HomeComponent,
      },
      {
        type: 'generic.GenericPage',
        component: GenericPageComponent,
      },
      {
        type: 'news.NewsIndexPage',
        component: NewsIndexComponent,
      },
      {
        type: 'news.NewsPage',
        component: NewsPageComponent,
      },
    ]),
    AppRoutingModule,
    HttpClientModule,
    BrowserTransferStateModule,
    IvyCarouselModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    GalleryModule,
    LightboxModule,
    PreviewModule,
    CalendarModule,
    GalleriaModule,
    ConfirmPopupModule,
    PinchZoomModule,
    SliderModule,
    InputTextModule,
    ButtonModule,
    MultiSelectModule,
    DragDropModule,
    DialogModule,
    ListboxModule,
    CarouselModule,
    InputTextareaModule,
    FormlyModule.forRoot({
      validators: [
        { name: 'url', validation: FormlyValidators.url },
        { name: 'numbers', validation: FormlyValidators.numbers },
        { name: 'vimeoUrl', validation: FormlyValidators.vimeoUrl },
        { name: 'validDimensionFormat', validation: FormlyValidators.validDimensionFormat },
        { name: 'validYear', validation: FormlyValidators.validYear },
      ],
      types: [
        { name: 'text', component: FormlyTextFieldComponent },
        { name: 'text-area', component: FormlyTextareaFieldComponent },
        { name: 'dropdown', component: FormlyDropdownFieldComponent },
        { name: 'calendar', component: FormlyCalendarFieldComponent },
        { name: 'file', component: FormlyFileUploadFieldComponent },
        { name: 'multicheckbox', component: FormlyMultiCheckboxFieldComponent },
        { name: 'checkbox', component: FormlyCheckboxFieldComponent },
        { name: 'repeat', component: FormlyRepeatSectionComponent },
        { name: 'multiselect', component: FormlyMultiselectComponent },
      ],
    }),
    FormlyPrimeNGModule,
    StoreModule.forRoot({ app: appStore.reducer }),
  ],
  bootstrap: [AppComponent],
  providers: [
    ApiInterceptor,
    ProfileEditorLinkService,
    API_INTERCEPTOR_PROVIDER,
    BaseService,
    AuthGuard,
    ProfileEditorGuard,
    DatePipe,
    // Sentry error reporting
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true,
      }),
      // multi: true,
    },
    // Sentry trace module
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
})
export class AppModule {
  // for including Sentry trace service
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(trace: Sentry.TraceService) {}
}
