import { NgModule } from '@angular/core';
import { RouterModule, ExtraOptions } from '@angular/router';
import { routes } from './routes';

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  // Necessary for server-side rendering to work
  initialNavigation: 'enabledBlocking',
  // restores the scrollposition to the top position whe using routes
  scrollPositionRestoration: 'top',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
