import { Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss'],
  providers: [ConfirmationService],
})
export class ShareButtonsComponent implements OnInit {
  @Input() theme = 'LIGHT';
  @Input() link = '';
  @Input() text = 'Share';
  @Input() title = '';
  @Input() imageUrl = ''; // seems to be the full url on the deployment on beta-app.net, no need to concat it

  fullLink = '';
  fullLinkEncoded = '';
  titleEncoded = '';
  imageUrlEncoded = '';
  shareLinkFacebook = '';
  shareLinkTwitter = '';
  shareLinkPinterest = '';

  constructor(private clipboard: Clipboard, private confirmationService: ConfirmationService) {}

  copyLink(event: Event) {
    this.clipboard.copy(this.fullLink);
    this.confirmationService.confirm({
      target: event.target,
      message: 'Link copied to clipboard',
      icon: 'pi pi-check',
      acceptVisible: false,
      rejectVisible: false,
    });
    setTimeout(() => {
      this.confirmationService.close();
    }, 2000);
  }

  share(social: string) {
    let shareLink = '';
    if (social === 'facebook') {
      shareLink = this.shareLinkFacebook;
    } else if (social === 'twitter') {
      shareLink = this.shareLinkTwitter;
    } else if (social === 'pinterest') {
      shareLink = this.shareLinkPinterest;
    }
    window.open(shareLink, 'shareWindow', 'popup,noopener,noreferrer');
  }

  ngOnInit(): void {
    this.fullLink = location.origin.concat(this.link);
    this.fullLinkEncoded = encodeURIComponent(this.fullLink);
    this.titleEncoded = encodeURIComponent(this.title);
    this.imageUrlEncoded = encodeURIComponent(this.imageUrl);

    this.shareLinkFacebook = 'https://www.facebook.com/sharer/sharer.php?u=' + this.fullLinkEncoded;
    this.shareLinkTwitter =
      'https://twitter.com/intent/tweet?url=' +
      this.fullLinkEncoded +
      (this.title ? '&text=' + this.titleEncoded : '') +
      '&hashtags=artronaut';
    this.shareLinkPinterest =
      'https://www.pinterest.com/pin/create/link/?url=' +
      this.fullLinkEncoded +
      (this.title ? '&description=' + this.titleEncoded : '') +
      (this.imageUrl ? '&media=' + this.imageUrlEncoded : '');
  }
}
