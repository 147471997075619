import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-text-field',
  templateUrl: './formly-text-field.component.html',
  styleUrls: ['./formly-text-field.component.scss'],
})
export class FormlyTextFieldComponent extends FieldType implements OnInit {
  ngOnInit(): void {}

  displayErrorMessage(error) {
    const currentError = Object.keys(error)[0];
    return this.field.validation.messages[currentError];
  }
}
