import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OccupationsService {
  occupations = [
    { value: 'ART', text: 'artist' },
    { value: 'CUR', text: 'curator' },
    { value: 'MUS', text: 'museum' },
    { value: 'INS', text: 'institution' },
    { value: 'GAL', text: 'gallery' },
    { value: 'DEA', text: 'dealer' },
    { value: 'NPO', text: 'non - profit organization' },
    { value: 'AE', text: 'artist estate' },
    { value: 'ARE', text: "artist's representative (foundation, agent)" },
    { value: 'ARS', text: 'art association' },
    { value: 'AFA', text: 'art fair' },
    { value: 'AUC', text: 'auction' },
    { value: 'COL', text: 'collector' },
    { value: 'PRE', text: 'press' },
    { value: 'ARL', text: 'art lover' },
    { value: 'ARA', text: 'art advisor' },
    { value: 'ARH', text: 'art historian' },
    { value: 'ANT', text: 'antique' },
    { value: 'IND', text: 'interior designer' },
    { value: 'BRE', text: 'broker - real estate' },
    { value: 'GOV', text: 'government organization' },
  ];
}
