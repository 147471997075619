import { EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-artwork-zoom',
  templateUrl: './artwork-zoom.component.html',
  styleUrls: ['./artwork-zoom.component.scss'],
})
export class ArtworkZoomComponent implements OnInit {
  @ViewChild('myPinch', { static: false }) myPinch;
  @Input() image = '';
  @Output() closed = new EventEmitter();
  sliderValue = 0;
  scaleValue = 1;

  scaleLimit = 5;
  stepSize = 5;

  scaleFactor = ((this.scaleLimit - 1) / 100) * 5; // per sliderstep

  @HostListener('wheel', ['$event'])
  scrollZoome(event: WheelEvent) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    Math.sign(event.deltaY) === -1 ? this.wheelZoomIn() : this.wheelZoomOut();
  }

  zoomIn() {
    if (this.sliderValue < 100) {
      this.myPinch.zoomIn();
      this.sliderValue += 5;
      this.scaleValue = this.myPinch.scale;
    }
  }

  zoomOut() {
    if (this.sliderValue > 0) {
      this.myPinch.zoomOut();
      this.sliderValue -= 5;
      this.scaleValue = this.myPinch.scale;
    }
  }

  slide(e) {
    this.scaleValue = 1 + (e.value / 5) * this.scaleFactor;
    this.myPinch.setZoom({ scale: this.scaleValue });
  }

  wheelZoomIn() {
    if (this.sliderValue < 100 || this.scaleValue < this.scaleLimit) {
      this.sliderValue += 5;
      this.scaleValue += this.scaleFactor;
      // console.log(this.scaleValue);
      this.myPinch.setZoom({ scale: this.scaleValue });
    }
  }

  wheelZoomOut() {
    // console.log(this.scaleValue > this.scaleLimit);
    if (this.sliderValue > 0 || this.scaleValue > this.scaleLimit) {
      this.sliderValue -= 5;
      this.scaleValue -= this.scaleFactor;
      this.myPinch.setZoom({ scale: this.scaleValue });
    }
  }

  closeZoomView = () => {
    this.closed.emit();
    window.scrollTo(0, 0);
  };

  ngOnInit(): void {}
}
