import { AfterViewInit, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-wagtail-heading',
  templateUrl: './wagtail-heading.component.html',
  styleUrls: ['./wagtail-heading.component.scss'],
})
export class WagtailHeadingComponent implements AfterViewInit {
  @Input() text;
  @Input() color;
  @Input() id;
  @Input() theme;
  @Input() styleExtra: string | undefined;
  @Input() classExtra: string | undefined;

  constructor(private sanitizer: DomSanitizer) {}

  ngAfterViewInit(): void {
    const rootTag = document.documentElement.getElementsByClassName(this.id)[0];

    if (this.classExtra) {
      rootTag.setAttribute('class', rootTag.getAttribute('class') + ' ' + this.classExtra + ' ');
    }
  }
}
