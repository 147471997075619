import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

import Glide from '@glidejs/glide';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit, AfterViewInit {
  @Input() data: Array<any> = [];

  glide: any;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.glide = new Glide('.glide_news', {
      autoplay: 5000,
      focusAt: 'center',
    });

    this.glide.on(['mount.before', 'resize'], () => this.updateGlideResponsive());
    this.glide.mount();
  }

  updateGlideResponsive() {
    let perView = 1;
    let gap = 0;

    const width = window.innerWidth;
    if (width >= 480) {
      perView = 1.1;
      gap = 24;
    }
    if (width >= 1024) {
      perView = 1.5;
      gap = 60;
    }
    if (width >= 1921) {
      perView = 2;
      gap = 60;
    }

    this.glide.update({
      perView,
      gap,
    });
  }
}
