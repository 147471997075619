import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newlineToBr',
})
export class NewlineToBrPipe implements PipeTransform {
  transform(value = '', ...args: unknown[]): string {
    value = value.replace(new RegExp('\r\n', 'g'), '<br />');
    value = value.replace(new RegExp('\n', 'g'), '<br />');
    return value;
  }
}
