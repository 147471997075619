import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wagtail-raw-html',
  templateUrl: './wagtail-raw-html.component.html',
  styleUrls: ['./wagtail-raw-html.component.scss'],
})
export class WagtailRawHtmlComponent implements OnInit {
  @Input() html_value;
  constructor() {}

  ngOnInit(): void {}
}
