import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-calendar-field',
  templateUrl: './formly-calendar-field.component.html',
  styleUrls: ['./formly-calendar-field.component.scss'],
})
export class FormlyCalendarFieldComponent extends FieldType implements OnInit {
  today: Date = new Date();
  minDate: Date = new Date();

  constructor() {
    super();
    this.minDate.setDate(1);
    this.minDate.setMonth(0);
    this.minDate.setFullYear(this.today.getFullYear() - 100);
  }

  ngOnInit(): void {}

  yearRange() {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return this.today.getFullYear() - 100 + ':' + this.today.getFullYear();
  }
}
