import { Pipe, PipeTransform } from '@angular/core';
import { MaterialService } from 'src/app/core/services/material/material.service';

@Pipe({
  name: 'resolveMaterial',
})
export class ResolveMaterialPipe implements PipeTransform {
  materials: Array<any> = this.materialService.material;
  constructor(private materialService: MaterialService) {}

  transform(value: string): string {
    const materials = this.materials.find((el) => el.value === value);
    return materials.text;
  }
}
