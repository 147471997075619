import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { signupValidators } from 'src/app/shared/form-validators/signup-in.validators';
import { ApiService } from '../../services/api/services';
import { MenuService } from '../../services/menu/menu.service';

// Sentry integration
import * as Sentry from '@sentry/angular';

// for improvement form
interface ImproveType {
  name: string;
}

interface ImprovePriority {
  name: string;
}

class ImprovementError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ImprovementError';
  }
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  menus;
  contactForm: UntypedFormGroup;
  mailingListForm: UntypedFormGroup;
  success = false;
  showError = false;

  showFeedback = false;
  feedbackText = '';
  feedbackStyle = '';

  // for sending improvement reports/errors
  isShowImprove = false;
  improveForm: UntypedFormGroup;
  improveError = null;
  improveScope = null;
  improvementTitle = '';
  improvementComment = '';
  improveTypes: ImproveType[];
  selectedImproveType: ImproveType;
  improvePriorities: ImprovePriority[];
  selectedImprovePriority: ImprovePriority;

  constructor(
    private _menuService: MenuService,
    private fb: UntypedFormBuilder,
    private apiService: ApiService
  ) {
    this.contactForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, signupValidators.emailValid]],
      message: ['', [Validators.required]],
      send: [''],
    });

    this.mailingListForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, signupValidators.emailValid]],
      terms: ['', [Validators.required]],
      send: [''],
    });

    // for improvement form
    this.improveTypes = [{ name: 'Bug' }, { name: 'UI/UX' }, { name: 'Idea' }, { name: 'Other' }];
    this.selectedImproveType = { name: 'UI/UX' };
    this.improvePriorities = [{ name: 'Low' }, { name: 'Medium' }, { name: 'High' }];
    this.selectedImprovePriority = { name: 'Low' };
  }

  ngOnInit(): void {
    this._menuService.combinedMenu.subscribe((data) => {
      this.menus = data;
    });
  }

  submitContactForm() {
    if (this.contactForm.invalid) {
      this.contactForm.markAllAsTouched();
      this.contactForm.setErrors({ ...this.contactForm.errors });
      return;
    }

    this.showFeedback = true;
    this.feedbackText = 'Sending...';

    const requestBody = this.contactForm.getRawValue();

    this.apiService.apiV1UserFooterFormCreate$Json$Response({ body: requestBody }).subscribe(
      (r) => {
        this.success = true;
        this.showError = false;
        this.contactForm.reset();
        setTimeout(() => {
          this.success = false;
        }, 3000);
        this.feedbackText = 'Thank you for getting in touch with us!';
        this.feedbackStyle = 'color: green';
      },
      (error) => {
        this.feedbackText = 'Sorry, an error occured, please try again later';
        this.feedbackStyle = 'color: red';

        // report via Sentry
        if (error && error.stack && error.message) {
          Sentry.captureException(error, {
            tags: {
              api: 'apiV1UserFooterFormCreate',
            },
          });
        } else {
          Sentry.captureException(new Error(error?.message), {
            tags: {
              api: 'apiV1UserFooterFormCreate',
              requestStatus: error?.status,
              requestError: error?.name,
              requestUrl: error?.url,
            },
          });
        }
      }
    );
  }
  submitMailingListForm() {}

  @HostListener('window:keydown.control.i', ['$event'])
  onImproveDialogHotKey(event: KeyboardEvent) {
    event.preventDefault();
    this.onImproveDialogShow();
  }

  onImproveDialogShow() {
    // currently just testing to send a error report manually

    // get the stack trace via the error
    this.improveError = new ImprovementError('ImprovementReport');

    this.improveScope = new Sentry.Scope();
    this.improveScope.setTag('improveReport', 'true');

    this.isShowImprove = true;
  }

  onImproveSubmit() {
    this.isShowImprove = false;

    if (!this.improvementTitle) {
      this.improvementTitle = 'Untitled improvement report';
    }
    if (!this.improvementComment) {
      this.improvementComment = 'Not comment provided';
    }
    if (!this.selectedImproveType) {
      this.selectedImproveType = { name: 'Other' };
    }
    if (!this.selectedImprovePriority) {
      this.selectedImprovePriority = { name: 'Low' };
    }

    this.improveScope.setContext('improvement', {
      title: this.improvementTitle,
      comment: this.improvementComment,
    });
    this.improveScope.setTag('type', this.selectedImproveType);
    this.improveScope.setTag('priority', this.selectedImprovePriority);

    this.improveError.message = this.improvementTitle;
    Sentry.captureException(this.improveError, this.improveScope);
  }
}
