<div class="wrapper">
  <div class="close-window"></div>
  <div class="top-wrapper">
    <div class="logo-wrapper">
      <div class="logo"></div>
      <p>SPACE FOR ART</p>
    </div>
  </div>

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="formgroup">
      <p class="order">Please enter a new password</p>

      <div class="textfields">
        <input
          formControlName="password"
          [class.invalid]="password.touched && password!.invalid"
          type="password"
          placeholder="Enter a new password."
          id="password"
          class="text"
        />
        <div *ngIf="password.touched && password!.invalid" class="alert alert-danger">
          <div class="message" *ngIf="password.errors.passwordValid">
            At least 1 lower case letter, 1 upper case letter and a number, at least 8 characters.
          </div>
        </div>
        <!-- ////////////////////verifyPassword//////////////////////// -->
        <input
          formControlName="verifyPassword"
          [class.invalid]="verifyPassword!.invalid && verifyPassword!.touched"
          type="password"
          placeholder="Repeat new password"
          id="password"
          class="text last-item"
        />
        <div *ngIf="verifyPassword.touched && form.invalid" class="alert alert-danger message">
          <div *ngIf="form.errors.passwordMatchValidator">
            Please enter the same password as above
          </div>
        </div>

        <div *ngIf="linkInvalid" class="alert alert-danger message">
          This link was already used. If you still want to reset your password, request another one
          via "forgot password" in the log in form.
        </div>
      </div>
      <button *ngIf="!loggedIn" type="submit" class="btn" [disabled]="form.invalid">
        Change Password
      </button>
      <button *ngIf="loggedIn" type="submit" class="logged-in btn">
        Your Password has been changed
      </button>
      <div *ngIf="formSubmitted || linkInvalid" class="message reset">
        <p *ngIf="formSubmitted">Your Password has been changed</p>
        <a routerLink="/" routerLinkActive="active">Return to the Startpage</a>
      </div>
    </div>
  </form>
</div>
