import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WeightUnitConversionService {
  constructor() {}

  lbToG(val: number): number {
    return this.decimalConverter(val) * 453.59237;
  }

  kgToG(val: number): number {
    return this.decimalConverter(val) * 1000;
  }

  gToLbDisplay(val: number) {
    return this.setMaxDecimalPoints(val / 453.59237, 2);
  }

  gToKgDisplay(val: number) {
    return this.setMaxDecimalPoints(val / 1000, 2);
  }

  setMaxDecimalPoints(val: number, dp: number): string {
    return +parseFloat(val.toString()).toFixed(dp) + '';
  }
  decimalConverter(val: number) {
    return Number(parseFloat(String(val).replace(',', '.')).toFixed(2));
  }
}
