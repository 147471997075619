/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldType } from '@ngx-formly/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/api/base-service';

@Component({
  selector: 'app-formly-file-upload-field',
  templateUrl: './formly-file-upload-field.component.html',
  styleUrls: ['./formly-file-upload-field.component.scss'],
})
export class FormlyFileUploadFieldComponent extends FieldType implements OnInit {
  @ViewChild('fileinput') el: ElementRef;

  previewId = '';
  files = [];

  isHovering = false;

  fileType = ['pdf'];
  imageTypes = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG'];
  allowedTypes = [];

  hint = '';
  totalSize = 0;
  noOfFiles = 0;

  sameNameError = false;

  remoteFiles = [];

  rootUrl = this.baseService.rootUrl;

  firstRun = true;

  constructor(public sanitizer: DomSanitizer, private baseService: BaseService) {
    super();
  }
  ngOnInit() {
    if (this.to.type === 'image') {
      this.allowedTypes = this.imageTypes;
    } else if (this.to.type === 'file') {
      this.allowedTypes = this.fileType;
    }

    if (this.to.maxNoOfFiles) {
      this.hint = '0/' + this.to.maxNoOfFiles.toString();
    }

    this.formControl.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      if (this.options.formState === 'reset' || !this.form.dirty) {
        this.firstRun = true;
        this.files = [];
        this.remoteFiles = [];

        this.noOfFiles = this.files.length;
        this.hint = this.noOfFiles + '/' + this.to.maxNoOfFiles.toString();
      }

      if (this.firstRun) {
        if (typeof value === 'string') {
          value = [
            {
              artwork: null,
              document: value,
              title: this.createFileTitle(value),
            },
          ];
        }
        if (!!value && value.length > 0) {
          this.remoteFiles = value;
        }

        this.firstRun = false;
      }
    });
  }

  createFileTitle(file) {
    const fileChunk = file.split('/');
    return fileChunk[fileChunk.length - 1];
  }

  handleFileAdd(files) {
    let fileOK = false;

    for (const file of files) {
      if (!('name' in file && this.checkIfExists(file.name))) {
        this.totalSize += file.size;

        // * Check for invalid file types
        if (this.checkFileTypeValid(file)) {
          this.formControl.updateValueAndValidity();
          this.formControl.markAllAsTouched();
          this.formControl.markAsDirty();
          fileOK = true;
        } else {
          this.formControl.setErrors({ invalidFileType: true });
          this.formControl.markAllAsTouched();
          this.formControl.markAsDirty();
          return;
        }

        // * Check for invalid file size
        if (this.to.maxFileSize) {
          if ('size' in file && this.checkFileSizeValid(file)) {
            this.formControl.updateValueAndValidity();
            this.formControl.markAllAsTouched();
            this.formControl.markAsDirty();
            fileOK = true;
          } else {
            this.totalSize -= file.size;
            this.formControl.setErrors({ invalidFileSize: true });
            this.formControl.markAllAsTouched();
            this.formControl.markAsDirty();
            return;
          }
        } else {
          fileOK = true;
        }

        if (fileOK) {
          let filesOK = false;

          // * Check for total files size
          if (this.to.maxTotalFileSize) {
            if (this.totalSize < this.to.maxTotalFileSize * 1000000) {
              this.formControl.updateValueAndValidity();
              this.formControl.markAllAsTouched();
              this.formControl.markAsDirty();
              filesOK = true;
            } else {
              this.totalSize -= file.size;
              this.formControl.setErrors({ invalidTotalSize: true });
              this.formControl.markAllAsTouched();
              this.formControl.markAsDirty();
              return;
            }
          } else {
            filesOK = true;
          }

          // * Check for total number of files
          if (this.to.maxNoOfFiles) {
            if (this.noOfFiles < this.to.maxNoOfFiles) {
              this.formControl.updateValueAndValidity();
              this.formControl.markAllAsTouched();
              this.formControl.markAsDirty();
              filesOK = true;
            } else {
              this.formControl.setErrors({ invalidNoOfFiles: true });
              this.formControl.markAllAsTouched();
              this.formControl.markAsDirty();
              return;
            }
          } else {
            filesOK = true;
          }

          if (filesOK) {
            // * Check if file already selected

            // Add files to array
            this.files.push(file);
            // patch files to formcontrol
            this.formControl.patchValue(this.files);

            this.formControl.markAllAsTouched();
            this.formControl.markAsDirty();

            this.noOfFiles = this.files.length;
            this.hint = this.noOfFiles + '/' + this.to.maxNoOfFiles.toString();
          }
        }
      } else {
        this.sameNameError = true;
        return;
      }
    }
  }

  checkFileTypeValid(file) {
    return this.allowedTypes.includes(this.getFileType(file.name));
  }

  checkFileSizeValid(file) {
    return !(file.size > this.to.maxFileSize * 1000000);
  }

  typeIcon(): string {
    if (this.to.type === 'image') {
      return '/assets/icons/camera_icon.svg';
    } else {
      return '/assets/icons/file_icon.svg';
    }
  }

  openFileInput() {
    this.el.nativeElement.click();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.remoteFiles, event.previousIndex, event.currentIndex);

    let updatedOrder = [];
    this.remoteFiles.forEach((file, i) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      updatedOrder.push({ ...file, order_number: i + 1 });
    });
    updatedOrder = updatedOrder.concat(this.files);

    this.formControl.patchValue(updatedOrder);
  }

  onChange(event) {
    this.handleFileAdd(event.target.files);
  }

  onDrop(files: File[]) {
    this.handleFileAdd(files);
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  getSanitizedImageUrl(file: File) {
    return this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
  }

  removeFile(index) {
    this.files.splice(index, 1);
    this.noOfFiles = this.files.length;
    this.totalSize = 0;
    this.files.forEach((file) => {
      this.totalSize += file.size;
    });

    this.hint = this.noOfFiles + '/' + this.to.maxNoOfFiles.toString();

    // * Check for total number of files
    if (this.noOfFiles <= this.to.maxNoOfFiles) {
      this.formControl.updateValueAndValidity();
    }

    // * Check for total files size
    if (this.totalSize <= this.to.maxTotalFileSize * 1000000) {
      this.formControl.updateValueAndValidity();
    }

    this.formControl.patchValue(this.files);
  }

  getFileType(filename) {
    return filename.split('.').pop();
  }

  checkIfExists(name: string) {
    const exists = this.files.some((item) => item.name === name);

    return exists;
  }

  displayErrorMessage(error) {
    const currentError = Object.keys(error)[0];
    return this.field.validation.messages[currentError];
  }

  acceptedTypes() {
    let f = '';
    this.allowedTypes.forEach((fileType, i) => {
      if (i === 0) {
        f = '.' + fileType;
      } else {
        f = f + ', .' + fileType;
      }
    });

    return f;
  }

  getFilePackage() {
    let filePackage;

    if (this.files) {
      filePackage = this.files.concat(this.remoteFiles);
    } else {
      filePackage = this.remoteFiles;
    }

    return filePackage;
  }

  markForDeletion(i) {
    this.remoteFiles[i].delete = true;
    this.formControl.patchValue(this.getFilePackage());
    this.formControl.markAllAsTouched();
    this.formControl.markAsDirty();
  }

  unmarkForDeletion(i) {
    delete this.remoteFiles[i].delete;
    this.formControl.patchValue(this.getFilePackage());
    this.formControl.markAllAsTouched();
    this.formControl.markAsDirty();
  }
}
