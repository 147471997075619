import { Action, createReducer, on } from '@ngrx/store';

import { User } from '../shared/interfaces/user';

import * as AppActions from './app.actions';

export interface AppState {
  user: User;
}

const userNotLoggedIn = {
  firstName: null,
  lastName: null,
  profileType: null,
  profileId: -1,
  userId: -1,
};

const initialState: AppState = {
  user: userNotLoggedIn,
};

const appReducer = createReducer(
  initialState,

  on(AppActions.setUser, (state, userToSet) => {
    if (!userToSet || !userToSet.profileType) {
      return { ...state, user: userNotLoggedIn };
    }
    return { ...state, user: userToSet };
  })
);

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reducer(state: AppState = initialState, action: Action) {
  // uncomment the following for debugging state changes
  // console.log('state', state);
  // console.log('action', action);
  return appReducer(state, action);
}
