/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-wagtail-grid-layout',
  templateUrl: './wagtail-grid-layout.component.html',
  styleUrls: ['./wagtail-grid-layout.component.scss'],
})
export class WagtailGridLayoutComponent implements OnInit {
  @Input() columns;
  @Input() theme;
  @Input() backgroundImage;
  @Input() containerVerticalJustification;
  @Input() styleExtra: string | undefined;
  @Input() classExtra: string | undefined;

  containerClass = '';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.containerVerticalJustification) {
      if (this.containerVerticalJustification === 'RIGHT') {
        this.containerClass = 'justify-content-end';
      } else if (this.containerVerticalJustification === 'LEFT') {
        this.containerClass = 'justify-content-start';
      } else {
        this.containerClass = 'justify-content-center';
      }
    }
  }

  getClass(column): string {
    let cssClass = '';

    if (column.value.size === null) {
      cssClass = 'col-12';
    } else {
      cssClass = 'lg:col-' + column.value.size;
      cssClass += ' xl:col-' + column.value.size;
    }

    if (column.value.offset) {
      cssClass += ' col-offset-' + column.value.offset;
    }

    if (column.value.class_extra) {
      cssClass += column.value.class_extra + ' ';
    } else if (this.classExtra) {
      cssClass += this.classExtra + ' ';
    }

    return cssClass;
  }

  getColumnStyle(column) {
    let cssStyle = '';
    if (column.value.style_extra) {
      cssStyle += column.value.style_extra;
    } else if (this.styleExtra) {
      cssStyle += this.styleExtra;
    }
    return this.sanitizer.bypassSecurityTrustStyle(cssStyle);
  }

  getColumnProperties(column) {
    const transparency = column.value.transparent ? column.value.transparent / 100 : 0.5;

    let columnProperty = '';

    columnProperty += this.getColumnBorderColor(column);

    if (column.value.transparent_color === 'black') {
      columnProperty += 'background: rgba(0,0,0, ' + transparency + ');  ';
    } else if (column.value.transparent_color === 'white') {
      columnProperty += 'background: rgba(255,255,255, ' + transparency + ');  ';
    }

    if (column.value.style_extra) {
      columnProperty += column.value.style_extra;
    }

    return columnProperty;
  }

  getColumnBorderColor(column) {
    if (column.value.border_color) {
      return 'border-color: ' + column.value.border_color + '; ';
    } else if (this.theme === 'DARK') {
      return 'border-color: white; ';
    }

    return '';
  }
}
