<div #wrapper class="wrapper" [class.collapsed]="collapse">
  <div class="grid">
    <div *ngFor="let image of images" class="content">
      <a [routerLink]="'/artwork/' + image.id" title="Link to Artwork Image"
        ><img (load)="checkHeight()" class="image" [src]="image.url" alt="Artwork image"
      /></a>
    </div>
  </div>
</div>

<div class="expander" *ngIf="masonryIsLarge" [class.expanded]="!collapse">
  <div class="gradient" *ngIf="collapse"></div>
  <div class="button" (click)="seeMore()">
    <p *ngIf="collapse">See more</p>
    <p *ngIf="!collapse">Close</p>
  </div>
</div>
