<p-confirmPopup></p-confirmPopup>
<div class="container" [ngClass]="{ dark: theme === 'DARK' }">
  <div *ngIf="text">{{ text }}</div>
  <div class="btn-container">
    <a onclick="return false;" (click)="share('facebook')" [href]="shareLinkFacebook"
      ><i class="pi pi-facebook"></i
    ></a>
    <a onclick="return false;" (click)="share('twitter')" [href]="shareLinkTwitter"
      ><i class="pi pi-twitter"></i
    ></a>
    <a onclick="return false;" (click)="share('pinterest')" [href]="shareLinkPinterest"
      ><i>
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 100 100"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M49.9794 0C22.3365 0 0 22.3776 0 49.9794C0 71.1641 13.1633 89.2637 31.7565 96.5446C31.304 92.5956 30.9338 86.5076 31.921 82.1884C32.826 78.2805 37.7622 57.3427 37.7622 57.3427C37.7622 57.3427 36.2814 54.3398 36.2814 49.9383C36.2814 42.9864 40.3126 37.8034 45.3311 37.8034C49.6092 37.8034 51.666 41.0119 51.666 44.8375C51.666 49.1156 48.951 55.5327 47.5113 61.4973C46.3184 66.4747 50.0206 70.5471 54.9157 70.5471C63.8009 70.5471 70.6294 61.1682 70.6294 47.6759C70.6294 35.7055 62.0321 27.355 49.7326 27.355C35.4998 27.355 27.1493 38.009 27.1493 49.0333C27.1493 53.3114 28.7947 57.9185 30.8515 60.4278C31.2629 60.9214 31.304 61.3739 31.1806 61.8675C30.8104 63.4307 29.9465 66.8449 29.782 67.5442C29.5763 68.4492 29.0415 68.6549 28.0954 68.2024C21.9251 65.2406 18.0584 56.1086 18.0584 48.7865C18.0584 33.0317 29.494 18.552 51.0901 18.552C68.4081 18.552 81.9005 30.8926 81.9005 47.429C81.9005 64.6647 71.0407 78.5274 55.9852 78.5274C50.9255 78.5274 46.1538 75.8947 44.5496 72.7684C44.5496 72.7684 42.0403 82.3118 41.4233 84.6565C40.3126 89.0169 37.2686 94.4467 35.2118 97.7787C39.9013 99.2184 44.8375 100 50.0206 100C77.6224 100 100 77.6224 100 50.0206C99.9589 22.3776 77.5812 0 49.9794 0Z"
          />
        </svg> </i
    ></a>
    <a onclick="return false;" [href]="fullLink"
      ><i (click)="copyLink($event)" class="pi pi-link"></i
    ></a>
  </div>
</div>
