<div class="artworkview-wrapper">
  <!-- <div class="artworkview-headder">
    <img class="logo" src="/assets/artronaut_logo_pur_schwarz_png.png" alt="Logo" />
  </div> -->

  <div class="artworkview-contentwrapper">
    <div class="artworkview-info">
      <div class="text-block">
        <div *ngIf="artworkData.creator_type === 'ART'">
          <a [routerLink]="'/artist/' + artworkData.creator_profile_id">
            <p class="artistname">
              {{ artworkData.creator_first_name }} {{ artworkData.creator_last_name }}
            </p>
          </a>
        </div>
        <div *ngIf="artworkData.creator_type === 'U'" class="userLink">
          <p class="artistname">
            {{ artworkData.creator_first_name }} {{ artworkData.creator_last_name }}
          </p>
        </div>
        <p class="artwork-title">{{ artworkData.title }}</p>
        <p>{{ artworkData.year }}</p>
        <p *ngIf="artworkData.technique && artworkData.material">
          {{ artworkData.technique | resolveTechnique }} on
          {{ artworkData.material | resolveMaterial | lowercase }}
        </p>
        <p *ngIf="unique">unique</p>
        <ng-container *ngIf="artworkData.width">
          <p>
            {{ artworkData.saved_unit === 'IN' ? (artworkData.width | cmToIn) : artworkData.width }}
            x
            {{
              artworkData.saved_unit === 'IN' ? (artworkData.height | cmToIn) : artworkData.height
            }}
            {{ artworkData.saved_unit | resolveUnitOfLength }}
          </p>
          <ng-container>
            <app-artwork-status [artwork]="artworkData" [awards]="awards"></app-artwork-status>
          </ng-container>
        </ng-container>

        <app-share-buttons [imageUrl]="shareImage" [link]="pageLink"></app-share-buttons>

        <a
          *ngIf="artworkData.title && artworkData.creator_type === 'ART'"
          [routerLink]="'/artist/' + artworkData.creator_profile_id"
        >
          <p class="artistpage">> visit artists page</p>
        </a>
      </div>
    </div>
    <div class="art-thumbnail-gallery">
      <div *ngFor="let image of artworkImages; let i = index">
        <img
          [class.highlight]="i === selected"
          class="artworkview-thumbnail-image"
          [src]="image.image_small"
          (click)="scrollImage(i)"
          alt="{{ image.title }}_{{ i }}"
        />
      </div>
    </div>
    <div class="artworkview-gallery">
      <div *ngFor="let image of artworkImages; let i = index">
        <div>
          <img
            class="artwork-image"
            id="{{ 'target' + i }}"
            [src]="image.image_large"
            (click)="openZoomView(image.image_large)"
            alt="Zoom {{ image.title }}_{{ i }}"
          />
        </div>
      </div>
      <div class="video-overview-wrapper">
        <div class="video-wrapper" *ngFor="let video of artworkVideos; let i = index">
          <iframe
            [src]="video.url | safeUrl"
            title="Video {{ video.id }}"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="artistArtworkIdList.length > 1" class="artworkview-symbolbar">
    <a [routerLink]="['/artwork/' + previousArtwork]">
      <img class="previous-artwork-arrow" src="/assets/arrow_black.svg" alt="previousArtwork" />
    </a>
    <a [routerLink]="['/artwork/' + nextArtwork]">
      <img class="next-artwork-arrow" src="/assets/arrow_black.svg" alt="nextArtwork"
    /></a>
  </div>
  <div
    *ngIf="artistArtworkIdList.length < 2"
    class="artworkview-symbolbar artworkview-symbolbar-disabled"
  >
    <img class="previous-artwork-arrow" src="/assets/arrow_grey.svg" alt="previous-artwork-arrow" />
    <img class="next-artwork-arrow" src="/assets/arrow_grey.svg" alt="next-artwork-arrow" />
  </div>
</div>

<app-artwork-zoom *ngIf="zoomView" [image]="zoomImage" (closed)="openZoomView()"></app-artwork-zoom>
