import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UnitService {
  distance = [
    { value: 'CM', text: 'cm' },
    { value: 'IN', text: 'inch' },
  ];

  weight = [
    { value: 'G', text: 'g' },
    { value: 'KG', text: 'kg' },
    { value: 'LBS', text: 'lbs' },
  ];
}
