import { Injectable } from '@angular/core';
import { GenreEnum } from '../api/models/genre-enum';
@Injectable({
  providedIn: 'root',
})
export class GenreService {
  genre = [
    { value: GenreEnum.Abs, text: 'Abstraction' },
    { value: GenreEnum.Fig, text: 'Figuration' },
    { value: GenreEnum.Hyp, text: 'Hyperrealism' },
    { value: GenreEnum.Lan, text: 'Landscape' },
    { value: GenreEnum.Por, text: 'Portrait' },
    { value: GenreEnum.Stl, text: 'Still Life' },
  ];
}
