<div>
  <label
    class="container"
    [class.invalid]="(formControl.dirty || formControl.touched) && to.required && !checked"
  >
    {{ this.to.text }}

    <input
      type="checkbox"
      [formlyAttributes]="field"
      [checked]="checked"
      [disabled]="formControl.disabled"
      (change)="onChange($event.target.checked)"
    />
    <span
      class="checkmark"
      [class.red-checkmark]="(formControl.dirty || formControl.touched) && to.required && !checked"
    ></span>
  </label>
</div>
