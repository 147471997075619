import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-masonry',
  templateUrl: './masonry.component.html',
  styleUrls: ['./masonry.component.scss'],
})
export class MasonryComponent {
  @ViewChild('wrapper') wrapper: ElementRef;

  @Input() images;

  collapse = false;
  masonryIsLarge = false;

  seeMore = () => {
    this.collapse = !this.collapse;
  };

  checkHeight() {
    const height = this.wrapper.nativeElement.offsetHeight;
    if (height > 650) {
      this.masonryIsLarge = true;
      this.collapse = true;
    }
  }
}
