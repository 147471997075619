<div class="overlay" *ngIf="display">
  <div class="indicator-wrapper">
    <div class="indicator">
      <div class="img-wrapper">
        <img src="/assets/bigheadnaut_rakete.png" alt="loading..." />
        <div class="loader"></div>
      </div>
      <h3>The data you entered is being uploaded</h3>
      This may take a while depending on the file size of the uploaded content. Please do not close
      or reload the page.
    </div>
  </div>
</div>
<img src="/assets/bigheadnaut_rakete.png" alt="preload image" style="display: none" />
