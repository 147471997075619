import { ElementRef, Component } from '@angular/core';
import { OnInit, DoCheck } from '@angular/core';
import { SignupInStatusService } from '../app/core/services/signup-in-status/signup-in-status.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, DoCheck {
  title = 'ARTRONAUT';

  showLogin: boolean;
  showSignin: boolean;
  logInOpen: boolean;

  constructor(
    private elementRef: ElementRef,
    private signupInStatusService: SignupInStatusService
  ) {
    this.showLogin = signupInStatusService.showLogin;
    this.showSignin = signupInStatusService.showSignin;
    this.logInOpen = this.showLogin || this.showSignin;
  }

  closeAll() {
    this.signupInStatusService.closeAll();
  }

  onClick() {
    this.showSignin = this.signupInStatusService.showSignin;
  }

  ngOnInit() {}

  ngDoCheck() {
    this.showSignin = this.signupInStatusService.showSignin;
    this.showLogin = this.signupInStatusService.showLogin;
    this.logInOpen = this.signupInStatusService.showLogin || this.signupInStatusService.showSignin;
  }
}
