import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TechniqueService {
  technique = [
    { value: 'ACRY', text: 'Acrylic' },
    { value: 'OIL', text: 'Oil' },
    { value: 'WATCO', text: 'Watercolor' },
    { value: 'MIXMED', text: 'Mixed Media' },
    { value: 'COL', text: 'Collage' },
    { value: 'GOU', text: 'Gouache' },
    { value: 'PAS', text: 'Pastel' },
    { value: 'TEMP', text: 'Tempera' },
    { value: 'GRAF', text: 'Graffiti' },
    { value: 'INK', text: 'Ink' },
    { value: 'WAX', text: 'Wax' },
    { value: 'OTHER', text: 'Other' },
  ];
}
