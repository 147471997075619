import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { ApiService } from 'src/app/core/services/api/services/api.service';
import { CookieService } from 'src/app/core/services/cookie-service/cookie-service.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileStatusService {
  profileLess: Subject<boolean> = new BehaviorSubject<boolean>(this.checkProfile());

  constructor(private apiService: ApiService, private cookieService: CookieService) {
    this.setCookie();
  }

  checkProfile(): any {
    this.apiService.apiV1UserVerifySessionRetrieve$Response().subscribe((r) => {
      if (r.body.filled_out_profile) {
        return false;
      } else {
        return true;
      }
    });
  }

  setCookie() {
    this.apiService.apiV1UserVerifySessionRetrieve$Response().subscribe((r) => {
      if (r.body.filled_out_profile) {
        this.cookieService.setCookie('userProfile', 'true', { secure: true });
      } else {
        this.profileLess.next(true);
      }
    });
  }
}
