import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VerifyRegistration } from 'src/app/core/services/api/models';
import { ApiService } from 'src/app/core/services/api/services';

// Sentry integration
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.scss'],
})
export class VerifyUserComponent implements OnInit {
  verificationStatus = 'pending';

  constructor(private route: ActivatedRoute, private apiService: ApiService) {}

  ngOnInit(): void {
    const params = this.route.snapshot.queryParamMap;
    const userId: string = params.get('user_id');
    const timestamp: number = parseInt(params.get('timestamp'), 10);
    const signature: string = params.get('signature');

    const requestBody: VerifyRegistration = {
      signature,
      timestamp,
      user_id: userId,
    };

    this.apiService
      .apiV1UserVerifyRegistrationCreate$FormData$Response({ body: requestBody })
      .subscribe(
        (r) => {
          // console.log(r);
          this.verificationStatus = 'success';
        },
        (error) => {
          console.log(error.error);
          this.verificationStatus = 'error';

          // report via Sentry
          if (error && error.stack && error.message) {
            Sentry.captureException(error, {
              tags: {
                api: 'apiV1UserVerifyRegistrationCreate',
              },
            });
          } else {
            Sentry.captureException(new Error(error?.message), {
              tags: {
                api: 'apiV1UserVerifyRegistrationCreate',
                requestStatus: error?.status,
                requestError: error?.name,
                requestUrl: error?.url,
              },
            });
          }
        }
      );
  }
}
