import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wagtail-button',
  templateUrl: './wagtail-button.component.html',
  styleUrls: ['./wagtail-button.component.scss'],
})
export class WagtailButtonComponent implements OnInit {
  @Input() entries;

  constructor() {}

  ngOnInit(): void {}
}
