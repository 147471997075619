<div style="background-color: black">
  <div class="content-wrapper">
    <div class="p-grid p-nogutter">
      <div class="p-col-12 p-lg-5 p-p-1 p-xl-5" style="margin-top: 23px">
        <div class="content" style="margin-top: 2.5em">
          <img class="image" style="width: 100%" src="assets/moon-art.png" />
        </div>
      </div>
      <div
        class="p-col-12 p-lg-5 p-lg-offset-1 p-p-1 p-xl-5 p-xl-offset-1"
        style="margin-top: 23px"
      >
        <div class="content" style="color: white; font-size: 24px !important">
          <h1><b> contact </b></h1>
        </div>
        <div class="content" style="margin-top: 1em; color: white; margin: 0 !important">
          <p style="font-size: 19px !important">
            <br />info@artronaut.online<br />Project Office NRW<br />Oberhausener Straße 225<br />45476
            Mülheim an der Ruhr<br />Germany
          </p>
          <p style="font-size: 19px !important">
            <br />ARTRONAUT is a project of:<br />HEINRICH BREUER® Projekt GmbH<br />Heinrich
            Breuer<br />Dipl. Ing. (TU) Architecture and Urban Design<br />Königstraße 68<br />83254
            Breitbrunn am Chiemsee<br />Germany
          </p>
          <p style="font-size: 19px !important">
            <br />ARTRONAUT is a project of:<br />XP · ART AGENCY<br />Xenia Petrischenko<br />M.A.
            International Arts Management<br />Maternusstrasse 32<br />50996 Cologne<br />Germany
          </p>
        </div>

        <div class="contact-wrapper" style="padding-top: 25px">
          <span class="head" style="color: white">get in touch</span>
          <p class="feedbackText" *ngIf="showFeedback; else showForm" [style]="feedbackStyle">
            {{ feedbackText }}
          </p>
          <ng-template #showForm>
            <form
              class="form"
              (ngSubmit)="submitContactForm()"
              [formGroup]="contactForm"
              style="padding-top: 10px"
            >
              <app-field-text
                label=""
                placeholder="NAME"
                id="name"
                theme="dark"
                footprint="big"
                [hideRequired]="true"
                [parentForm]="contactForm"
              ></app-field-text>
              <div class="spacer"></div>
              <app-field-text
                label=""
                placeholder="E-MAIL"
                id="email"
                theme="dark"
                footprint="big"
                [hideRequired]="true"
                [parentForm]="contactForm"
              ></app-field-text>
              <div class="spacer"></div>
              <app-field-textarea
                placeholder="MESSAGE"
                id="message"
                theme="dark"
                footprint="big"
                [rows]="3"
                [hideRequired]="true"
                [parentForm]="contactForm"
              ></app-field-textarea>
              <div class="spacer-big"></div>

              <div class="button-container">
                <app-field-button
                  class="button"
                  [text]="success ? 'Sent' : 'Send'"
                  id="send"
                  theme="dark"
                  footprint="big"
                  [success]="success"
                ></app-field-button>
                <span class="serverError" *ngIf="showError">
                  Something went wrong there. Please try again later.
                </span>
              </div>
            </form>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
