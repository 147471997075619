import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-multi-checkbox-field',
  templateUrl: './formly-multi-checkbox-field.component.html',
  styleUrls: ['./formly-multi-checkbox-field.component.scss'],
})
export class FormlyMultiCheckboxFieldComponent extends FieldType implements OnInit {
  checked;

  ngOnInit(): void {
    const tempChecked = new Set(this.formControl.value);

    this.checked = tempChecked;

    this.formControl.valueChanges.subscribe((x) => {
      this.checked = new Set(x);
    });
  }

  onChange(value: any, checked: boolean) {
    if (checked) {
      this.checked.add(value);
      this.formControl.setValue(this.checked);
    } else {
      this.checked.delete(value);

      this.formControl.setValue(this.checked);
    }

    this.formControl.markAsTouched();
  }

  isChecked(option: any) {
    if (this.checked.has(option)) {
      return true;
    }
    return false;
  }
}
