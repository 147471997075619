<div class="wrapper">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="formgroup">
      <p class="order">Change password</p>

      <div class="textfields">
        <input
          formControlName="oldPassword"
          type="password"
          [class.invalid]="(form.errors && oldPassword!.invalid) || passwordNotCorrect"
          placeholder="Enter your old password"
          id="oldPassword"
          class="text"
        />
        <div class="alert alert-danger" *ngIf="form.errors && oldPassword!.invalid">
          <div class="message">This field is required.</div>
        </div>
        <div class="alert alert-danger" *ngIf="passwordNotCorrect">
          <div class="message">The old password is not correct.</div>
        </div>
        <input
          formControlName="password"
          [class.invalid]="(form.errors && password!.invalid) || passwordTooCommon"
          type="password"
          placeholder="Enter a new password"
          id="password"
          class="text"
        />
        <div *ngIf="form.errors && password!.invalid" class="alert alert-danger">
          <div class="message" *ngIf="password.errors.passwordValid">
            At least 1 lower case letter, 1 upper case letter and a number, at least 8 characters.
          </div>
        </div>
        <div class="alert alert-danger" *ngIf="passwordTooCommon">
          <div class="message">
            The password you entered is too common. Please choose another one.
          </div>
        </div>
        <input
          formControlName="verifyPassword"
          [class.invalid]="form.errors && form.errors.passwordMatchValidator"
          type="password"
          placeholder="Confirm new password"
          id="password"
          class="text last-item"
        />
        <div *ngIf="form.errors" class="alert alert-danger">
          <div class="message" *ngIf="form.errors.passwordMatchValidator">
            Please enter the same password as above
          </div>
        </div>
      </div>

      <button *ngIf="!loggedIn" type="submit" class="btn">Change Password</button>
      <button *ngIf="loggedIn" type="submit" class="logged-in btn">
        Your Password has been changed!
      </button>

      <div *ngIf="formSubmitted" class="message reset">
        <a routerLink="" routerLinkActive="active" (click)="navigateToHome()" class="reset"
          >Return to the Startpage</a
        >
      </div>
    </div>
  </form>
</div>
