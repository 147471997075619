import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativePathToBackendUrl',
})
export class RelativePathToBackendUrlPipe implements PipeTransform {
  transform(path: string): string {
    const url = path;
    return url;
  }
}
