import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextBoxComponent implements OnInit {
  @Input() html = '';

  constructor() {}

  ngOnInit(): void {}
}
