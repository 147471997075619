import { Component, OnDestroy, OnInit } from '@angular/core';
import { WagtailService } from 'angular-wagtail';
import { Gallery, GalleryItem, ImageItem } from 'ng-gallery';
// import { LightboxModule } from 'ng-gallery/lightbox';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.scss'],
})
export class NewsPageComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private slideShowIntervalId: any = null;

  data: any;
  images: GalleryItem[];
  itemAutoSize = true;

  constructor(private gallery: Gallery, private wagtailService: WagtailService) {}

  ngOnInit(): void {
    this.wagtailService
      .getPageForCurrentUrl()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.data = data;

        const images = [];
        data.gallery_images.forEach((image: { image_url: string }) => {
          images.push(new ImageItem({ src: image.image_url, thumb: image.image_url }));
        });

        this.images = images;

        const galleryRef = this.gallery.ref('newsGallery');

        if (this.images.length > 1 && this.slideShowIntervalId === null) {
          this.slideShowIntervalId = setInterval(() => {
            try {
              galleryRef.next();
            } catch (error) {
              console.log(error.error);
              clearInterval(this.slideShowIntervalId);
              this.slideShowIntervalId = null;
            }
          }, 3000);
        } else if (this.slideShowIntervalId !== null) {
          clearInterval(this.slideShowIntervalId);
          this.slideShowIntervalId = null;
        }
      });

    if (this.getBrowserName() === 'safari') {
      this.itemAutoSize = false;
    }
  }

  ngOnDestroy() {
    if (this.slideShowIntervalId !== null) {
      clearInterval(this.slideShowIntervalId);
      this.slideShowIntervalId = null;
    }

    // https://netbasal.com/when-to-unsubscribe-in-angular-d61c6b21bad3
    // https://benlesh.medium.com/rxjs-dont-unsubscribe-6753ed4fda87
    // https://levelup.gitconnected.com/unsubscribing-in-angular-the-right-way-6ed82be43ccc
    // https://blog.bitsrc.io/6-ways-to-unsubscribe-from-observables-in-angular-ab912819a78f

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
}
