<h6>{{ to.sectionLabel }}</h6>

<div *ngFor="let field of field.fieldGroup; let i = index" class="field-container">
  <formly-field class="field" [field]="field"></formly-field>
  <!-- {{ field.formControl.value | json }} -->
  <!-- <div class="cross" (click)="remove(i)">
    <div class="x a"></div>
    <div class="x b"></div>
  </div> -->

  <div *ngIf="field.formControl.value.remote; else removeItem">
    <img
      *ngIf="field.formControl.value.delete; else unmarked"
      class="remove"
      src="/assets/icons/recycle_bin_red.svg"
      (click)="unmarkForDeletion(i)"
    />
    <ng-template #unmarked>
      <img class="remove" src="/assets/icons/recycle_bin.svg" (click)="markForDeletion(i)" />
    </ng-template>
  </div>

  <ng-template #removeItem>
    <div class="cross" (click)="remove(i)">
      <div class="x a"></div>
      <div class="x b"></div>
    </div>
  </ng-template>
</div>
<div class="button">
  <app-field-button [text]="to.addText" (click)="add()"></app-field-button>
</div>
