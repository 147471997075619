import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() show: boolean;
  @Input() header: string;
  @Input() successStyle: boolean;
  @Input() buttonTexts: Array<string>;
  @Input() buttonFunctions: Array<() => void>;
  @Input() buttonIcons: Array<string>;
  @Input() allowContinue = true;

  constructor() {}
}
