<div>
  <!-- Label -->
  <label *ngIf="!to.required; else requiredLabel">{{ to.label }}</label>
  <ng-template #requiredLabel>
    <label>{{ to.label + '&nbsp;*' }}</label>
  </ng-template>

  <!-- Input -->
  <input
    [class.invalid]="(formControl.dirty || formControl.touched) && formControl.invalid"
    type="text"
    [placeholder]="to.placeholder"
    [formControl]="formControl"
    [formlyAttributes]="field"
  />

  <!-- Display validation messages -->
  <small class="error" *ngIf="(formControl.dirty || formControl.touched) && formControl.invalid">
    {{ displayErrorMessage(formControl.errors) }}
  </small>

  <p class="hint">{{ to.hint }}</p>
</div>
