import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() url = '';
  @Input() text = '';
  @Input() icon = '';
  @Input() theme = '';
  @Input() fixedWidth = false;
  @Input() disabled?: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
