<app-subpage>
  <ng-container sidebar>
    <p class="username">
      {{ profileData.user_data?.first_name }} {{ profileData.user_data?.last_name }}
    </p>

    <ul class="user-profile-navigation">
      <li class="user-profile-navigation-item">
        <p (click)="scroll(target1)">Artworks</p>
      </li>
      <li class="user-profile-navigation-item">
        <p (click)="scroll(target2)">Statement</p>
      </li>
      <li class="user-profile-navigation-item">
        <p (click)="scroll(target3)">About</p>
      </li>
      <li class="user-profile-navigation-item">
        <p (click)="scroll(target4)">Biography</p>
      </li>
      <li class="user-profile-navigation-item">
        <p (click)="scroll(target5)">Videos</p>
      </li>
      <li *ngIf="profileData.website" class="user-profile-navigation-item">
        <a [href]="profileData.website" target="_blank">Artist website</a>
      </li>
    </ul>
    <app-share-buttons
      [imageUrl]="profileData.portrait_medium"
      [link]="pageLink"
    ></app-share-buttons>
  </ng-container>
  <ng-container content>
    <div class="content" #target1 style="height: auto">
      <app-masonry [images]="userArtworkImages"></app-masonry>
    </div>
    <div class="content" #target2 style="height: auto">
      <app-textblock *ngIf="profileData.statement">
        <div class="textblock-content header">
          Artist statement <br />
          by {{ profileData.user_data?.first_name }} {{ profileData.user_data?.last_name }}
        </div>
        <div class="textblock-content text" [innerHTML]="profileData.statement | newlineToBr"></div>
      </app-textblock>
    </div>
    <div class="content" #target3>
      <app-textblock *ngIf="profileData.about_the_artist">
        <div class="textblock-content header">ARTRONAUT About The Artist</div>
        <div
          class="textblock-content text"
          [innerHTML]="profileData.about_the_artist | newlineToBr"
        ></div>
      </app-textblock>
    </div>
    <div class="content" #target4>
      <app-textblock>
        <div class="textblock-content header">BIOGRAPHY</div>
        <div class="textblock-content header">
          {{ profileData.user_data?.first_name }} {{ profileData.user_data?.last_name }}
        </div>

        <div class="textblock-content text">
          <ng-container *ngIf="profileData.country_of_birth">
            Born in {{ profileData.country_of_birth }}.
          </ng-container>
          <br />
          <ng-container *ngIf="profileData.current_location">
            Lives in {{ profileData.current_location }}.
          </ng-container>
        </div>

        <ng-container *ngIf="profileData.education">
          <div class="textblock-content header">Education</div>
          <div
            class="textblock-content text"
            [innerHTML]="profileData.education | newlineToBr"
          ></div>
        </ng-container>

        <ng-container *ngIf="profileData.awards_and_accolades">
          <div class="textblock-content header">Awards And Accolades</div>
          <div
            class="textblock-content text"
            [innerHTML]="profileData.awards_and_accolades | newlineToBr"
          ></div>
        </ng-container>

        <ng-container *ngIf="profileData.solo_exhibitions">
          <div class="textblock-content header">Solo Exhibitions</div>
          <div
            class="textblock-content text"
            [innerHTML]="profileData.solo_exhibitions | newlineToBr"
          ></div>
        </ng-container>

        <ng-container *ngIf="profileData.group_exhibitions">
          <div class="textblock-content header">Group Exhibitions</div>
          <div
            class="textblock-content text"
            [innerHTML]="profileData.group_exhibitions | newlineToBr"
          ></div>
        </ng-container>

        <div class="textblock-content header">Collections</div>
        <div
          class="textblock-content text"
          [innerHTML]="profileData.collection | newlineToBr"
        ></div>
      </app-textblock>
    </div>
    <div class="content" #target5>
      <ng-container *ngIf="profileData.artist_videos">
        <div class="textblock-content header">Videos of current exhibitions/projects</div>
        <div class="video-overview-wrapper">
          <div class="video-description-container" *ngFor="let video of artistVideo">
            <div class="video-wrapper">
              <iframe
                [src]="video.url | safeUrl"
                frameborder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
              ></iframe>
            </div>
            <div class="video-description">
              {{ video.description }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</app-subpage>
