export const environment = {
  production: true,
  sentryDsn: 'https://459c073dac2f72840cde1bbb80be0c16@sentry.r0k.live/15',
  sentryRelease: 'production',
  sentrySampleRate: 1.0,
  sentryIsCaptureConsole: true,
  sentryCaptureConsoleLevels: ['error'],
  sentryIsTracing: true,
  sentryTracingRate: 1.0,
  sentryIsRrweb: true,
  sentryIsRrwebCanvas: true,
  sentryIsRrwebMaskInputs: false,
  rootUrl: '',
};
