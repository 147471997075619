import { Pipe, PipeTransform } from '@angular/core';
import { ArtworkImages, Artworks } from 'src/app/core/services/api/models';

@Pipe({
  name: 'mainArtworkImage',
})
export class MainArtworkImagePipe implements PipeTransform {
  transform(artwork: Artworks, ...args: string[]): string {
    const mainImages: Array<ArtworkImages> = artwork.artwork_images.filter(
      (artWorkImage) => artWorkImage.main_image
    );
    if (mainImages.length === 0) {
      mainImages.push(artwork.artwork_images[0]);
    }
    const mainImage: ArtworkImages = mainImages[0];
    const imageSize: string = args[0] || 'image';

    return mainImage[imageSize];
  }
}
