import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { from } from 'rxjs';

@Component({
  selector: 'app-field-textarea',
  templateUrl: './field-textarea.component.html',
  styleUrls: ['./field-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldTextareaComponent),
      multi: true,
    },
  ],
})
export class FieldTextareaComponent implements OnInit {
  @Input() label = '';
  @Input() placeholder = '';

  @Input() id = '';

  @Input() parentForm: UntypedFormGroup;

  @Input() hint = '';

  @Input() rows = 10;

  @Input() theme = 'light';
  @Input() footprint = 'small';
  @Input() hideRequired = false;

  @Input() maxChars?: number;
  @Input() required: false;

  value: any = '';
  invalidInput = false;
  onChange: any = (value) => {
    this.writeValue(value);
  };
  onTouched: any = () => {};

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    const form = this.parentForm.get(this.id);
    form.valueChanges.subscribe((value) => {
      this.writeValue(value);
    });

    if (!this.maxChars) {
      return;
    }

    const newValidators = [Validators.maxLength(this.maxChars)];
    const currentValidators = form.validator;
    if (currentValidators) {
      newValidators.push(currentValidators);
    }
    form.setValidators(newValidators);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = (value) => {
      this.writeValue(value);
      fn(value);
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
