import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { forkJoin, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  apiNavMenuUrl = environment.rootUrl + '/api/nav_menu';
  apiPagesUrl = environment.rootUrl + '/api/v2/pages';

  combinedMenu: Subject<Array<any>> = new Subject<any[]>();

  constructor(private http: HttpClient) {
    // the backend nav-menu plugin does not provide full navbar structure (why would it?!), so as
    // a workaround, we execute two http requests and generate the structure ourselves.
    forkJoin([this.http.get(this.apiNavMenuUrl), this.http.get(this.apiPagesUrl)]).subscribe(
      (res: any) => {
        const navMenu = res[0][0].menu;
        const pages = res[1].items;
        this.combinedMenu.next(this.generateNavStructure(navMenu, pages));
      }
    );
  }

  generateNavStructure(navMenu: any, pages: any): any {
    const combinedMenuTemp = [];

    navMenu.forEach((subMenu) => {
      const newSubMenu: any = JSON.parse(JSON.stringify(subMenu));
      newSubMenu.value.sub_nav = [];

      for (let i = 0; i < subMenu.value.sub_nav.length; i++) {
        const linkId = subMenu.value.sub_nav[i].value.page;
        if (linkId === null) {
          // somebody added a page to the navigation and then deleted that page,
          // so there is now a broken link in the navigation menu.
          console.warn("You've got a broken page in your menu! Remove it in Wagtail settings.");
        } else {
          pages.forEach((page) => {
            if (!environment.production) {
              page = this.setPortForDev(page);
            }
            if (page.id === linkId) {
              newSubMenu.value.sub_nav.push(page);
            }
          });
        }
      }
      // change port to 4200 if on dev environment!1
      combinedMenuTemp.push(newSubMenu);
    });

    return combinedMenuTemp;
  }

  // the urls in the menu structure received from the backend do not contain
  // the correct port for development environments. replacing/adding that here.
  setPortForDev(page) {
    // depending how wagtail is configured, the urls may contain the wrong port
    // or none at all.
    page.meta.html_url = page.meta.html_url.replace('http://localhost/', 'http://localhost:4200/');
    page.meta.html_url = page.meta.html_url.replace(
      'http://localhost:8000/',
      'http://localhost:4200/'
    );
    return page;
  }
}
