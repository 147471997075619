<div class="p-field">
  <label>
    {{ to.label }}
  </label>
  <p-calendar
    [formControl]="formControl"
    [formlyAttributes]="field"
    yearNavigator="true"
    [yearRange]="yearRange()"
    [minDate]="minDate"
    [maxDate]="today"
    dateFormat="dd/mm/yy"
    [style]="{ width: '100%' }"
    [panelStyle]="{ 'box-shadow': 'none', border: '2px solid #2a2a2a' }"
    [inputStyle]="{
      color: 'black',
      marginTop: '6px',
      width: '100%',
      outline: 'none',
      'border-radius': '0',
      'font-family': 'Montserrat, sans-serif',
      'font-size': '0.667rem',
      padding: '0.55rem 0.1rem',
      border: '2px solid #2a2a2a'
    }"
  ></p-calendar>
</div>
