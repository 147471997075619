/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { ApiService } from 'src/app/core/services/api/services';
import { CategoryService } from 'src/app/core/services/category/category.service';
import { GenreService } from 'src/app/core/services/genre/genre.service';
import { MaterialService } from 'src/app/core/services/material/material.service';
import { PriceRangeService } from 'src/app/core/services/price-range/price-range.service';
import { RarityService } from 'src/app/core/services/rarity/rarity.service';
import { SizeService } from 'src/app/core/services/size/size.service';
import { TechniqueService } from 'src/app/core/services/technique/technique.service';
import { TitlesService } from 'src/app/core/services/titles/titles.service';

// Sentry integration
import * as Sentry from '@sentry/angular';
import { DatePipe } from '@angular/common';
import {
  CanDirtyCheck,
  WarnOnUnsavedComponent,
} from 'src/app/shared/components/warn-on-unsaved/warn-on-unsaved.component';

@Component({
  selector: 'app-formly-user-editor',
  templateUrl: './formly-user-editor.component.html',
  styleUrls: ['./formly-user-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyUserEditorComponent implements OnInit, CanDirtyCheck {
  @ViewChild(WarnOnUnsavedComponent) warnerComponent: WarnOnUnsavedComponent;

  // Variables for interests options services
  genre = this.genreService.genre;
  category = this.categoryService.category;
  technique = this.techniqueService.technique;
  priceRange = this.priceRangeService.priceRange;
  size = this.sizeService.size;
  material = this.materialService.material;
  rarity = this.rarityService.rarity;
  titles = this.titlesService.titles;

  // Status indicators
  displayLoadingIndicator = false;
  showErrorOnSaveModal = false;
  success = false;
  resetSuccess = false;
  errors = false;

  // Form
  form = new UntypedFormGroup({});

  // Model for user form
  model = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    name_of_institution: '',
    title: '',
    firstName: '',
    lastName: '',
    address: '',
    email: '',
    phone: '',
    website: '',
    dob: null,
    portrait: null,
    interests: null,
    choices: null,
  };

  // Form options
  options: FormlyFormOptions = {};

  // Fields configuration
  fields: FormlyFieldConfig[] = [
    // Company - row 1
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'name_of_institution',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Name of Institution / Company if applicable',
            required: false,
          },
        },
      ],
    },
    // Title - row 2
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'title',
          type: 'dropdown',
          className: 'flex-1',
          templateOptions: {
            label: 'Title',
            required: true,
            placeholder: 'Select title',
            options: this.titles,
          },
          validation: {
            messages: {
              required: 'Title is required',
            },
          },
        },
        { className: 'flex-1' },
      ],
    },
    // Name - row 3
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'firstName',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'First Name',
            required: true,
          },
          validation: {
            messages: {
              required: 'First name is required',
            },
          },
        },
        {
          key: 'lastName',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Last Name',
            required: true,
          },
          validation: {
            messages: {
              required: 'Last name is required',
            },
          },
        },
      ],
    },
    // Address - row 4
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'address',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Address',
            required: true,
          },
          validation: {
            messages: {
              required: 'Address is required',
            },
          },
        },
      ],
    },
    // Email, Phone - row 5
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'email',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Email',
            disabled: true,
            required: true,
          },
        },
        {
          key: 'phone',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Phone',
            required: false,
            hint: 'Not for public use',
          },
          validators: {
            validation: ['numbers'],
          },
          validation: {
            messages: {
              numbers: 'Invalid phone number',
            },
          },
        },
      ],
    },

    // Website - row 6
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'website',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Website if applicable',
            required: false,
            placeholder: 'https://www.example.com',
            hint: 'Please enter URLs with http:// or https://',
          },
          validators: {
            validation: ['url'],
          },
          validation: {
            messages: {
              url: 'Invalid URL',
            },
          },
        },
      ],
    },
    // Date of Birth - row 7
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'dob',
          type: 'calendar',
          className: 'flex-1',
          templateOptions: {
            label: 'Date of Birth (DD/MM/YYYY)',
            required: false,
            yearNavigator: true,
            yearRange: Array.from({ length: 100 }, (v, k) => k + new Date().getFullYear()),
          },
        },
        { className: 'flex-1' },
      ],
    },
    // Portrait - row 8
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'portrait',
          type: 'file',
          className: 'flex-1',
          templateOptions: {
            label: 'Portrait',
            required: false,
            multiple: false,
            type: 'image',
            maxNoOfFiles: 1,
            maxFileSize: 10,
            maxTotalFileSize: 10,
            description: 'Drag or click to upload your <b>Portrait Image</b>',
            helpText:
              'Please name your Portrait Image as follows: Last Name_First Name_Portrait Image' +
              '</br> Please upload a JPG or PNG format with a height or width of at least 2000 px.',
          },
          validation: {
            messages: {
              invalidFileType: 'File type is invalid',
              invalidNoOfFiles: 'Maximum number of files is 1',
              invalidFileSize: 'Each file can only have a maximum size of 10MB',
            },
          },
        },
      ],
    },
    // Interests - row 9
    {
      fieldGroup: [
        {
          key: 'interests',
          type: 'multicheckbox',
          templateOptions: {
            sectionHeading:
              'I am interested in the following topics (you may select several sections)',
            optionsArray: [
              { label: 'Genre', options: this.genre },
              { label: 'Price Range', options: this.priceRange },
              { label: 'size', options: this.size },
              { label: 'Category', options: this.category },
              { label: 'Technique', options: this.technique },
              { label: 'Rarity', options: this.rarity },
              { label: 'Material', options: this.material },
            ],
          },
        },
      ],
    },
    // Choices - row 10
    {
      fieldGroup: [
        {
          key: 'choices',
          type: 'multicheckbox',
          templateOptions: {
            fullWidth: true,
            optionsArray: [
              {
                label: '',
                options: [
                  {
                    value: 'NL',
                    // eslint-disable-next-line @typescript-eslint/quotes
                    text: "I would like to be informed about ARTRONAUT's activities and receive newsletters",
                  },
                  {
                    value: 'AP',
                    // eslint-disable-next-line @typescript-eslint/quotes
                    text: "I apply for the publication of my artworks on the ARTRONAUT's website",
                  },
                ],
              },
            ],
          },
        },
      ],
    },
  ];

  constructor(
    private apiService: ApiService,
    private genreService: GenreService,
    private categoryService: CategoryService,
    private techniqueService: TechniqueService,
    private priceRangeService: PriceRangeService,
    private sizeService: SizeService,
    private materialService: MaterialService,
    private rarityService: RarityService,
    private titlesService: TitlesService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getUser();
  }

  // Function for getting saved user data
  getUser() {
    this.apiService.apiV1UserUserProfilesRetrieve$Response().subscribe(
      (r) => {
        this.formatDataStructure(r.body);
      },
      (error) => {
        // console.log(error.error);

        this.showErrorOnSaveModal = true;

        // report via Sentry
        if (error && error.stack && error.message) {
          Sentry.captureException(error, {
            tags: {
              api: 'apiV1UserUserProfilesRetrieve',
            },
          });
        } else {
          Sentry.captureException(new Error(error?.message), {
            tags: {
              api: 'apiV1UserUserProfilesRetrieve',
              requestStatus: error?.status,
              requestError: error?.name,
              requestUrl: error?.url,
            },
          });
        }
      }
    );
  }

  // Format data from backed to work with fronetned
  formatDataStructure(data) {
    this.model = data;
    this.model.firstName = data.user_data.first_name;
    this.model.lastName = data.user_data.last_name;
    this.model.email = data.user_data.email;
    this.model.dob = data.date_of_birth ? new Date(data.date_of_birth) : null;
  }

  // Submit function
  onSubmit() {
    if (this.form.invalid) {
      this.errors = true;
      this.form.markAllAsTouched();
      this.form.setErrors({ ...this.form.errors });
      return;
    }

    this.displayLoadingIndicator = true;
    const requestBody: any = {
      address: this.model.address,
      choices: Array.from(this.model.choices),
      date_of_birth: this.datePipe.transform(this.model.dob, 'yyyy-MM-dd'),
      interests: Array.from(this.model.interests),
      name_of_institution: this.model.name_of_institution,
      phone: this.model.phone,
      portrait: this.model.portrait,
      title: this.model.title,
      user_data: {
        first_name: this.model.firstName,
        last_name: this.model.lastName,
        email: this.model.email,
      },
    };

    // Add Portrait to request body and handle deletion
    if (this.model.portrait && this.model.portrait.length > 0) {
      if (this.form.value.portrait && typeof this.form.value.portrait[0].name === 'string') {
        requestBody.portrait = this.form.value.portrait[0];
      } else {
        requestBody.portrait = undefined;
      }

      if (this.model.portrait[0].delete) {
        this.apiService.apiV1UserUserPortraitDestroy$Response().subscribe(
          () => {},
          (error) => {
            this.displayLoadingIndicator = false;

            // report via Sentry
            if (error && error.stack && error.message) {
              Sentry.captureException(error, {
                tags: {
                  api: 'apiV1UserUserPortraitDestroy',
                },
              });
            } else {
              Sentry.captureException(new Error(error?.message), {
                tags: {
                  api: 'apiV1UserUserPortraitDestroy',
                  requestStatus: error?.status,
                  requestError: error?.name,
                  requestUrl: error?.url,
                },
              });
            }
          }
        );
      }
    }
    this.apiService.apiV1UserUserProfilesUpdate$FormData$Response({ body: requestBody }).subscribe(
      () => {
        this.displayLoadingIndicator = false;
        this.success = true;

        setTimeout(() => {
          location.reload();
        }, 0);
      },
      (error) => {
        this.showErrorOnSaveModal = true;
        this.displayLoadingIndicator = false;
        console.log(error.error);

        // report via Sentry
        if (error && error.stack && error.message) {
          Sentry.captureException(error, {
            tags: {
              api: 'apiV1UserUserProfilesUpdate',
            },
          });
        } else {
          Sentry.captureException(new Error(error?.message), {
            tags: {
              api: 'apiV1UserUserProfilesUpdate',
              requestStatus: error?.status,
              requestError: error?.name,
              requestUrl: error?.url,
            },
          });
        }
      }
    );
  }

  // Reset function
  onReset() {
    this.resetSuccess = true;
    this.options.resetModel();

    setTimeout(() => {
      this.resetSuccess = false;
      // void this.router.navigate(['/']);
    }, 2000);
  }

  // Callback for clicking 'OK' on backend error modal
  acknowledgeError = () => {
    this.showErrorOnSaveModal = false;
  };
}
