import { Component, OnInit } from '@angular/core';
import { ProfileStatusService } from 'src/app/core/services/profile-status/profile-status.service';

@Component({
  selector: 'app-profile-message',
  templateUrl: './profile-message.component.html',
  styleUrls: ['./profile-message.component.scss'],
})
export class ProfileMessageComponent implements OnInit {
  profileLess: boolean;
  constructor(private profileStatus: ProfileStatusService) {
    this.profileStatus.profileLess.subscribe((r) => {
      this.profileLess = r;
    });
  }

  ngOnInit(): void {}
}
