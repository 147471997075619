import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SignatureService {
  signature = [
    { value: 'FLR', text: 'Front lower right' },
    { value: 'FLL', text: 'Front lower left' },
    { value: 'FUR', text: 'Front upper right' },
    { value: 'FUL', text: 'Front upper left' },
    { value: 'BLR', text: 'Back lower right' },
    { value: 'BLL', text: 'Back lower left' },
    { value: 'BUR', text: 'Back upper right' },
    { value: 'BUL', text: 'Back upper left' },
    { value: 'OTH', text: 'Other' },
  ];
}
