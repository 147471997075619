import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  FormControl,
  FormGroup,
} from '@angular/forms';

const passwordRegex = new RegExp('^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\\D*\\d).{8,}$');
const emailRegex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
export class signupValidators {
  static passwordValid(control: AbstractControl): ValidationErrors | null {
    if (!passwordRegex.test(control.value)) {
      return { passwordValid: true };
    } else {
      return null;
    }
  }
  static emailValid(control: AbstractControl): ValidationErrors | null {
    if (!emailRegex.test(control.value)) {
      return { emailValid: true };
    } else {
      return null;
    }
  }

  static passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password').value;
    const verifyPassword = control.get('verifyPassword').value;
    if (password !== verifyPassword) {
      return { passwordMatchValidator: true };
    }
    return null;
  }
}
/*
  Abc12345
*/
