import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-multiselect',
  templateUrl: './formly-multiselect.component.html',
  styleUrls: ['./formly-multiselect.component.scss'],
})
export class FormlyMultiselectComponent extends FieldType implements OnInit {
  ngOnInit(): void {}
}
