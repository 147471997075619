import { Component, OnInit } from '@angular/core';
import { signupValidators } from '../../form-validators/signup-in.validators';
import {
  UntypedFormGroup,
  FormControl,
  Validators,
  ValidationErrors,
  AbstractControl,
  UntypedFormBuilder,
} from '@angular/forms';
import { ApiService } from 'src/app/core/services/api/services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  form: UntypedFormGroup;
  userId: string;
  timestamp: number;
  signature: string;
  loggedIn = false;
  linkInvalid = false;

  constructor(
    fb: UntypedFormBuilder,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) {
    this.form = fb.group(
      {
        password: ['', [Validators.required, signupValidators.passwordValid]],
        verifyPassword: ['', [Validators.required]],
      },
      {
        validators: [signupValidators.passwordMatchValidator],
      }
    );
    const params = this.route.snapshot.queryParamMap;
    this.userId = params.get('user_id');
    this.timestamp = parseInt(params.get('timestamp'));
    this.signature = params.get('signature');
  }

  formSubmitted = false;

  get password() {
    return this.form.get('password');
  }
  get verifyPassword() {
    return this.form.get('verifyPassword');
  }

  onSubmit() {
    if (this.form.valid) {
      const requestBody = {
        password: this.password.value,
        signature: this.signature,
        timestamp: this.timestamp,
        user_id: this.userId,
      };
      this.apiService.apiV1UserResetPasswordCreate$Json$Response({ body: requestBody }).subscribe(
        (r) => {
          this.formSubmitted = true;
          this.loggedIn = true;
        },
        (error) => {
          const e = error.error;
          if (e.detail === 'Invalid signature') {
            console.log('Link already used');
            this.linkInvalid = true;
          }
        }
      );
    }
  }

  ngOnInit(): void {}
}
