<div class="wrapper">
  <div class="close-window" (click)="closeSignin()">
    <div class="line a"></div>
    <div class="line b"></div>
  </div>
  <div class="top-wrapper">
    <div class="logo-wrapper">
      <div class="logo"></div>
      <p>SPACE FOR ART</p>
    </div>
  </div>

  <form [formGroup]="form" (ngSubmit)="signUp()">
    <div class="formgroup">
      <div class="textfields">
        <!-- //////////////////////////////////////////// -->
        <select
          formControlName="occupations"
          (click)="checkOccupation()"
          id="occupation"
          class="text"
          placeholder="Occupation"
          [class.invalid]="form.errors && occupations!.invalid"
        >
          <option
            value=""
            (click)="checkOccupation()"
            [style.display]="occupationSelected ? 'none' : 'block'"
          >
            Please Select an Occupation
          </option>
          <option *ngFor="let occupation of occupationsList" [value]="occupation.value">
            {{ occupation.text }}
          </option>
        </select>
        <div *ngIf="form.errors" class="alert alert-danger">
          <div class="message" *ngIf="!occupations!.valid">Please Select an Occupation</div>
        </div>
        <!-- //////////////////////firstName////////////////////// -->
        <input
          formControlName="firstName"
          [class.invalid]="form.errors && firstName!.invalid"
          type="text"
          placeholder="Enter your first name"
          id="firstName"
          class="text form-control"
        />
        <div *ngIf="form.errors" class="alert alert-danger">
          <div *ngIf="firstName!.invalid">
            <div class="message" *ngIf="firstName!.invalid">Please enter your first name</div>
          </div>
        </div>
        <!-- //////////////////////lastName////////////////////// -->
        <input
          formControlName="lastName"
          [class.invalid]="form.errors && lastName!.invalid"
          type="text"
          placeholder="Enter your last name"
          id="lastName"
          class="text form-control"
        />
        <div *ngIf="form.errors" class="alert alert-danger">
          <div *ngIf="lastName!.invalid">
            <div class="message" *ngIf="lastName!.invalid">Please enter your last name</div>
          </div>
        </div>
        <!-- //////////////////////email////////////////////// -->
        <input
          formControlName="email"
          [class.invalid]="(form.errors && email!.invalid) || emailUnique"
          type="email"
          placeholder="Enter your email address"
          id="email"
          class="text form-control"
          (focus)="emailFocus()"
        />
        <div *ngIf="form.errors" class="alert alert-danger">
          <div *ngIf="email!.invalid">
            <div class="message" *ngIf="email!.errors!.emailValid">
              Email address is invalid. Please enter a valid email address.
            </div>
          </div>
        </div>
        <div *ngIf="emailUnique" class="alert alert-danger">
          <div *ngIf="emailUnique">
            <div class="message" *ngIf="emailUnique">
              Your email address is already registered with ARTRONAUT. Please log in instead or
              register with a different email address.
            </div>
          </div>
        </div>

        <!--////////////////////////password////////////////////-->
        <input
          formControlName="password"
          [class.invalid]="(form.errors && password!.invalid) || passwordTooCommon"
          (focus)="passwordTooCommon = false"
          type="password"
          placeholder="Enter a password"
          id="password"
          class="text"
        />
        <div *ngIf="form.errors" class="alert alert-danger">
          <div *ngIf="password!.invalid">
            <div class="message" *ngIf="password!.errors!.passwordValid">
              At least 1 lower case letter, 1 upper case letter and a number, at least 8 characters.
            </div>
          </div>
        </div>
        <div class="alert alert-danger" *ngIf="passwordTooCommon">
          <div class="message">
            The password you entered is too common. Please choose another one.
          </div>
        </div>
        <!-- ////////////////////verifyPassword//////////////////////// -->
        <input
          formControlName="verifyPassword"
          [class.invalid]="form.errors && form.errors.passwordMatchValidator"
          type="password"
          placeholder="Repeat password"
          id="verifyPassword"
          class="text last-item"
        />

        <div *ngIf="form.errors" class="alert alert-danger">
          <div class="message" *ngIf="form.errors.passwordMatchValidator">
            Please enter the same password as above
          </div>
        </div>
      </div>
      <!-- ////////////////////terms//////////////////////// -->
      <div class="terms-wrapper">
        <div class="checkbox">
          <input
            formControlName="acceptTerms"
            type="checkbox"
            id="acceptTerms"
            class="default-checkbox"
            [checked]="termsAccepted"
            (change)="termsAccepter()"
          />
          <div class="new-checkbox">
            <div class="cross" [style.display]="termsAccepted ? 'flex' : 'none'">
              <div class="x a"></div>
              <div class="x b"></div>
            </div>
          </div>
        </div>
        <label class="checkbox-label" for="terms"
          ><p>
            I agree to ARTRONAUT's
            <a routerLink="">Terms of Use</a>
            and
            <a routerLink="">Privacy Policy</a>
            and to receive emails from ARTRONAUT
          </p>
        </label>
      </div>
      <div *ngIf="form.errors" class="alert alert-danger">
        <div class="message" *ngIf="acceptTerms!.invalid">You have to accept the Terms of Use</div>
      </div>
      <!-- //////////////////Button////////////////////////// -->
      <input class="btn" [disabled]="formSubmitted" type="submit" value="Sign up" />
      <p class="forgot-something" *ngIf="formSubmitted">
        You succesfully created your Account. <br /><a (click)="openLogin()">Log in</a>
      </p>
      <p class="forgot-something" *ngIf="!formSubmitted">
        Already have an account? <a (click)="openLogin()">Log in</a>
      </p>
    </div>
  </form>
  <div *ngIf="registered" class="success-wrapper">
    <img src="/assets/artronaut19_illu_peace.png" alt="" />
    <p>
      <span class="green">YOU HAVE BEEN REGISTERED.</span><br />
      Please close this window and check your inbox for {{ email.value }} to confirm your email
      address.
    </p>
  </div>
</div>
