import { Pipe, PipeTransform } from '@angular/core';
import { LengthUnitConversionService } from 'src/app/core/services/length-unit-conversion/length-unit-conversion.service';

@Pipe({
  name: 'cmToIn',
})
export class CmToInPipe implements PipeTransform {
  constructor(private lengthUnitConversionService: LengthUnitConversionService) {}

  transform(value: number): string {
    return this.lengthUnitConversionService.toInDisplay(value);
  }
}
