<app-carousel [data]="data.landing_page_images"></app-carousel>
<app-hero-image
  height="auto"
  [image]="data.body_image?.meta.download_url | relativePathToBackendUrl"
>
  <app-text-box [html]="data.body_text"></app-text-box>
</app-hero-image>
<app-hero-image height="auto" image="/assets/home-hero-space.png">
  <app-art-award-call [data]="data.landing_page_awards"></app-art-award-call>
</app-hero-image>
<!--
<app-sponsors></app-sponsors>
-->
<app-hero-image height="100vh" image="/assets/home-hero-space.png">
  <app-news [data]="data.landing_page_news"></app-news>
</app-hero-image>
