import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ThemeService } from 'src/app/core/services/theme/theme.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { signupValidators } from 'src/app/shared/form-validators/signup-in.validators';
import { ApiService } from '../../../app/core/services/api/services';
// Sentry integration
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactPageComponent implements OnInit {
  contactForm: UntypedFormGroup;
  showFeedback = false;
  success = false;
  showError = false;
  feedbackText = '';
  feedbackStyle = '';
  constructor(
    private themeService: ThemeService,
    private apiService: ApiService,
    private fb: UntypedFormBuilder
  ) {
    this.contactForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, signupValidators.emailValid]],
      message: ['', [Validators.required]],
      send: [''],
    });
  }

  ngOnInit(): void {
    this.themeService.theme = 'DARK';
  }

  submitContactForm() {
    if (this.contactForm.invalid) {
      this.contactForm.markAllAsTouched();
      this.contactForm.setErrors({ ...this.contactForm.errors });
      return;
    }

    this.showFeedback = true;
    this.feedbackText = 'Sending...';

    const requestBody = this.contactForm.getRawValue();

    this.apiService.apiV1UserFooterFormCreate$Json$Response({ body: requestBody }).subscribe(
      (r) => {
        this.success = true;
        this.showError = false;
        this.contactForm.reset();
        setTimeout(() => {
          this.success = false;
        }, 3000);
        this.feedbackText = 'Thank you for getting in touch with us!';
        this.feedbackStyle = 'color: green';
      },
      (error) => {
        this.feedbackText = 'Sorry, an error occured, please try again later';
        this.feedbackStyle = 'color: red';

        // report via Sentry
        if (error && error.stack && error.message) {
          Sentry.captureException(error, {
            tags: {
              api: 'apiV1UserFooterFormCreate',
            },
          });
        } else {
          Sentry.captureException(new Error(error?.message), {
            tags: {
              api: 'apiV1UserFooterFormCreate',
              requestStatus: error?.status,
              requestError: error?.name,
              requestUrl: error?.url,
            },
          });
        }
      }
    );
  }
}
