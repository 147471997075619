import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-art-award-call',
  templateUrl: './art-award-call.component.html',
  styleUrls: ['./art-award-call.component.scss'],
})
export class ArtAwardCallComponent implements OnInit {
  @Input() data;

  awardData = {
    news_url: '',
    title: '',
  };

  constructor() {}

  ngOnInit(): void {
    if (this.data && this.data[0]) {
      this.awardData = this.data[0].awards_list;
    }
  }
}
