<div>
  <!-- Label -->
  <label *ngIf="!to.required; else requiredLabel">{{ to.label }}</label>
  <ng-template #requiredLabel>
    <label>{{ to.label + ' *' }}</label>
  </ng-template>

  <!-- Input -->
  <select
    [class.invalid]="!formControl.valid && formControl.touched"
    [formControl]="formControl"
    [formlyAttributes]="field"
  >
    <option [value]="null" selected disabled></option>
    <option *ngFor="let option of to.options" [value]="option.value">{{ option.text }}</option>
  </select>

  <!-- Display validation messages -->
  <small class="error" *ngIf="(formControl.dirty || formControl.touched) && formControl.invalid">
    {{ displayErrorMessage(formControl.errors) }}
  </small>
</div>
