<div class="title content-wrapper" *ngIf="voting">
  <h2>
    Artworks nominated for <a [href]="award.url">{{ award.title }}</a>
  </h2>
  <p *ngIf="canVote; else cantVote">
    You are welcome to vote for any artist you would like to receive the ARTRONAUT AUDIENCE ART
    AWARD and become the winner. You may vote for several artists by clicking the vote button.
  </p>
  <ng-template #cantVote>The public voting phase is over for this award.</ng-template>
  <br />
  <span *ngIf="!loggedIn">You have to register or log in to vote.</span>
</div>
<div class="title content-wrapper" *ngIf="!voting">
  <h2>Artworks</h2>
</div>

<div class="artworks content-wrapper">
  <div class="artwork" *ngFor="let artwork of voting ? artworks : artworksList">
    <div class="image-wrapper">
      <a [routerLink]="'/artwork/' + artwork.id" class="artwork-link">
        <div
          class="image"
          [style.backgroundImage]="
            'url(' + (artwork | mainArtworkImage : 'image_small' | relativePathToBackendUrl) + ')'
          "
        ></div>
      </a>
    </div>
    <div *ngIf="loggedIn && voting && canVote" class="artwork-ui">
      <app-vote-button [artwork]="artwork"></app-vote-button>
    </div>
    <div class="caption">
      <span class="title">{{ artwork.title }}</span>
      <br />
      <ng-container *ngIf="artwork.creator_type === 'ART'; then profileLink; else noProfileLink">
      </ng-container>
      <ng-template #profileLink>
        <span class="artist">
          by
          <a [routerLink]="'/artist/' + artwork.creator_profile_id">
            {{ artwork.creator_first_name }} {{ artwork.creator_last_name }}
          </a>
        </span>
      </ng-template>
      <ng-template #noProfileLink>
        <span class="artist">
          by {{ artwork.creator_first_name }} {{ artwork.creator_last_name }}
        </span>
      </ng-template>
      <ng-container>
        <app-artwork-status [artwork]="artwork" [awards]="awards"></app-artwork-status>
      </ng-container>
    </div>
  </div>
</div>
