<div *ngIf="data">
  <gallery
    *ngIf="images"
    id="newsGallery"
    style="height: 60vh; width: 100%"
    [items]="images"
    [itemAutosize]="itemAutoSize"
    imageSize="contain"
    [dots]="true"
    [counter]="false"
    [thumb]="false"
    loop="true"
    gallerize
  ></gallery>

  <div [ngClass]="{ dark: data.theme === 'DARK' }">
    <!-- This has been commented out as there is a gallery above but 
        there is a field in wagtail that accepts a news image and therefore could be used later -->

    <!-- <div
        class="news-image"
        [style]="'background-image: url(' + (data.image_url | relativePathToBackendUrl) + ');'"
      ></div> -->
    <div class="head">
      <a class="return" [routerLink]="data.index">< Return to all news</a>
      <div>{{ data.date.day }}.{{ data.date.month }}.{{ data.date.year }}</div>
    </div>
    <div class="news-container">
      <h1>{{ data.title }}</h1>

      <app-generic-page></app-generic-page>

      <div class="sharing">
        <app-share-buttons
          [imageUrl]="data.news_image.meta.download_url"
          [title]="data.title"
          [theme]="data.theme"
          [link]="data.link"
        ></app-share-buttons>
      </div>

      <div
        *ngIf="data.next_news"
        [routerLink]="data.next_news"
        class="navigate-news next-news"
      ></div>
      <div
        *ngIf="data.prev_news"
        [routerLink]="data.prev_news"
        class="navigate-news prev-news"
      ></div>
    </div>
  </div>
</div>
