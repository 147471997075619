import { Routes } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { CMSLoaderGuard, CMSLoaderComponent, GetPageDataResolverService } from 'angular-wagtail';
import { ResetPasswordComponent } from './shared/components/reset-password/reset-password.component';
import { VerifyUserComponent } from './modules/verify-user/verify-user.component';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';
import { ArtistViewComponent } from './modules/artist-view/artist-view.component';
import { ArtworkViewComponent } from './modules/artwork-view/artwork-view.component';
import { ArtistsComponent } from './modules/artists/artists.component';
import { ArtworksComponent } from './modules/artworks/artworks.component';
import { ContactPageComponent } from './modules/contact-page/contact-page.component';
import { AuthGuard } from './core/guards/auth.guard';
import { WarnOnUnsavedGuard } from './core/guards/warn-on-unsaved.guard';
import { UserArtworkOverviewComponent } from './modules/user-artwork-overview/user-artwork-overview.component';
import { ProfileEditorGuard } from 'src/app/core/guards/profile-editor/profile-editor.guard';
import { ChangePasswordComponent } from './shared/components/change-password/change-password.component';
import { FormlyUserEditorComponent } from './modules/formly-user-editor/formly-user-editor.component';
import { FormlyArtistEditorComponent } from './modules/formly-artist-editor/formly-artist-editor.component';
import { FormlyArtworkEditorComponent } from './modules/formly-artwork-editor/formly-artwork-editor.component';

export const routes: Routes = [
  {
    path: 'preview',
    loadChildren: () => import('angular-wagtail').then((m) => m.PreviewModule),
  },
  {
    path: '',
    component: HomeComponent,
    resolve: { cmsData: GetPageDataResolverService },
    canActivate: [ProfileEditorGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'artist-editor',
    component: FormlyArtistEditorComponent,
    canActivate: [AuthGuard],
    canDeactivate: [WarnOnUnsavedGuard],
  },
  {
    path: 'user-editor',
    component: FormlyUserEditorComponent,
    canActivate: [AuthGuard],
    canDeactivate: [WarnOnUnsavedGuard],
  },
  {
    path: '',
    canActivate: [ProfileEditorGuard],
    children: [
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'verify-user',
        component: VerifyUserComponent,
      },
      {
        path: '404',
        component: PageNotFoundComponent,
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard],
      },

      {
        path: 'artists/:sieve',
        component: ArtistsComponent,
      },
      {
        path: 'artists',
        redirectTo: 'artists/pictures',
      },
      {
        path: 'artist/:id',
        component: ArtistViewComponent,
      },
      {
        path: 'artwork/:id',
        component: ArtworkViewComponent,
      },
      {
        path: 'artwork-editor',
        component: FormlyArtworkEditorComponent,
        canActivate: [AuthGuard],
        canDeactivate: [WarnOnUnsavedGuard],
      },
      {
        path: 'artwork-editor/:id',
        component: FormlyArtworkEditorComponent,
      },
      {
        path: 'artworks',
        component: ArtworksComponent,
      },
      {
        path: 'contact-us',
        component: ContactPageComponent,
      },
      {
        path: 'artworks/award/:awardId',
        component: ArtworksComponent,
      },
      {
        path: 'artwork-overview',
        component: UserArtworkOverviewComponent,
        canActivate: [AuthGuard],
        canDeactivate: [WarnOnUnsavedGuard],
      },
      {
        path: '**',
        component: CMSLoaderComponent,
        canActivate: [CMSLoaderGuard],
      },
    ],
  },
];
