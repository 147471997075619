import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SignupInStatusService } from '../services/signup-in-status/signup-in-status.service';
import { CookieService } from '../services/cookie-service/cookie-service.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  loggedIn: boolean;

  constructor(private route: Router, private cookieService: CookieService) {}

  canActivate(): any {
    if ((this.loggedIn = !!this.cookieService.getCookie('userType'))) {
      return true;
    } else {
      this.route.navigate(['/']);
    }
  }
}
