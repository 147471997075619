<h5 *ngIf="to.sectionHeading" [innerHTML]="to.sectionHeading"></h5>
<div class="gap" *ngIf="to.marginTop"></div>
<div>
  <!-- Label -->
  <label [class.bold]="to.labelBold">{{ to.label }}</label>

  <textarea
    [class.invalid]="formControl.invalid && (formControl.dirty || formControl.touched)"
    [placeholder]="to.placeholder"
    [formControl]="formControl"
    [formlyAttributes]="field"
    maxlength=""
    [rows]="to.rows ? to.rows : 8"
  ></textarea>

  <!-- Display validation messages -->

  <div class="inline-hints">
    <small class="error" *ngIf="(formControl.dirty || formControl.touched) && formControl.invalid">
      {{ displayErrorMessage(formControl.errors) }}
    </small>

    <small
      class="hint-right"
      [class.hint-error]="formControl.invalid && (formControl.dirty || formControl.touched)"
    >
      {{ hint }}
    </small>
  </div>

  <div class="hint-text">
    <p [innerHTML]="to.hintText"></p>
  </div>

  <label class="container" *ngIf="to.showNA">
    Not Applicable

    <input type="checkbox" (change)="toggleDisabled()" />
    <span class="checkmark"></span>
  </label>
</div>
