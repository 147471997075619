<div class="wrapper">
  <div class="textblock">
    <div #textRef class="text" [class.collapsed]="collapse">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="expander" *ngIf="textIsLarge">
    <div class="gradient" *ngIf="collapse"></div>
    <div class="button" (click)="seeMore()">
      <p *ngIf="collapse">Read more</p>
      <p *ngIf="!collapse">Close</p>
    </div>
  </div>
</div>
