import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'resolveUnitOfLength',
})
export class ResolveUnitOfLengthPipe implements PipeTransform {
  units: Array<any> = [
    { value: 'CM', text: 'cm' },
    { value: 'IN', text: 'in' },
  ];

  transform(value: string): string {
    const unit = this.units.find((el) => el.value === value);
    return unit.text;
  }
}
