import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  location = [
    { value: 'ARTS', text: "Artist's studio" },
    { value: 'GA', text: 'Gallery' },
    { value: 'MUS', text: 'Museum' },
    { value: 'COL', text: 'Collection' },
    { value: 'STO', text: 'Storage' },
    { value: 'ARTR', text: 'ARTRONAUT' },
    { value: 'OTH', text: 'Other' },
  ];
}
