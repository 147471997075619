import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SizeService {
  size = [
    { value: 'S', text: 'Small (under 40cm)' },
    { value: 'M', text: 'Medium (40 - 100cm)' },
    { value: 'L', text: 'Large (over 100cm)' },
  ];
}
