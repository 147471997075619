<div class="container" *ngIf="data">
  <div [innerHtml]="data.intro"></div>
  <div *ngFor="let news of data.news">
    <div class="news-card" (click)="newsLinkClicked(news.url)">
      <div
        class="news-card-image"
        [style]="'background-image: url(' + (news.image | relativePathToBackendUrl) + ')'"
      ></div>
      <div class="news-card-description">
        <div class="date-share-container">
          <div>{{ news.date.day }}.{{ news.date.month }}.{{ news.date.year }}</div>
          <app-share-buttons
            [imageUrl]="news.image"
            [title]="news.title"
            [link]="news.url"
            text=""
            theme="DARK"
          ></app-share-buttons>
        </div>
        <h2>{{ news.title }}</h2>
        <div class="news-summary" [innerHtml]="news.summary"></div>
        <div class="text-block-overlay"></div>
      </div>
    </div>
  </div>
</div>
