import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-textarea-field',
  templateUrl: './formly-textarea-field.component.html',
  styleUrls: ['./formly-textarea-field.component.scss'],
})
export class FormlyTextareaFieldComponent extends FieldType implements OnInit {
  hint: any = null;

  value = '';

  ngOnInit(): void {
    if (this.to.maxLength) {
      this.hint = '0/' + this.to.maxLength.toString();
    }

    this.formControl.valueChanges.subscribe((x: string) => {
      if (x) {
        this.value = x;
        if (this.to.maxLength) {
          this.hint = `${x.length}/  ${this.to.maxLength.toString()}`;
        }
      }
    });
  }

  toggleDisabled() {
    this.field.templateOptions.disabled = !this.field.templateOptions.disabled;
    if (this.formControl.disabled) {
      this.formControl.reset();
    } else {
      this.formControl.patchValue(this.value);
    }
  }

  displayErrorMessage(error) {
    const currentError = Object.keys(error)[0];
    return this.field.validation.messages[currentError];
  }
}
