<h5>
  {{ to.sectionHeading }}
</h5>
<div [class]="this.to.fullWidth ? null : 'checkboxContainer'">
  <div
    *ngFor="let option of this.to.optionsArray"
    [class]="this.to.fullWidth ? null : 'checkboxGroup'"
  >
    <h6>{{ option.label }}</h6>
    <div *ngFor="let checkbox of option.options; let i = index">
      <label class="container">
        {{ checkbox.text }}

        <input
          type="checkbox"
          [id]="id + '_' + i"
          [formlyAttributes]="field"
          [checked]="isChecked(checkbox.value)"
          [disabled]="formControl.disabled"
          (change)="onChange(checkbox.value, $event.target.checked)"
        />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</div>
