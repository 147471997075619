<div class="wrapper">
  <div class="close-window" (click)="closeLogin()">
    <div class="line a"></div>
    <div class="line b"></div>
  </div>
  <div class="top-wrapper">
    <div class="logo-wrapper">
      <div class="logo"></div>
      <p>SPACE FOR ART</p>
    </div>
  </div>
  <form [formGroup]="form" *ngIf="!forgotPassword" (ngSubmit)="login()">
    <div class="formgroup">
      <div class="textfields">
        <input
          formControlName="email"
          [class.invalid]="email!.touched && email!.invalid"
          type="text"
          placeholder="Enter your email address"
          id="email"
          class="text form-control"
        />
        <div *ngIf="email!.touched && email!.invalid">
          <div class="message" *ngIf="email!.errors!.emailValid">
            Email address is invalid. Please enter a valid email address.
          </div>
        </div>
        <input
          formControlName="password"
          type="password"
          placeholder="Enter a password"
          id="password"
          class="text"
          [class.invalid]="wrongPassword"
        />
      </div>
      <div *ngIf="wrongPassword" class="message alert alert-danger">
        You entered an unknown email address or a wrong password.
      </div>

      <input *ngIf="!loggedIn" class="btn" [disabled]="!form.valid" type="submit" value="Log in" />
      <input *ngIf="loggedIn" class="btn logged-in" value="Successfully logged in!" />

      <p class="forgot-something" (click)="forgotPwClick()"><a>Forgot Password?</a></p>
    </div>
  </form>

  <form [formGroup]="forgotPwForm" *ngIf="forgotPassword" (ngSubmit)="sendMail()">
    <div class="formgroup">
      <div class="textfields for-pw">
        <input
          formControlName="forgotPwEmail"
          [class.invalid]="email!.touched && forgotPwEmail!.invalid"
          type="text"
          placeholder="Enter your email address"
          id="forgotPwEmail"
          class="text form-control"
        />
      </div>
      <div *ngIf="forgotPwEmail!.touched && forgotPwEmail!.invalid">
        <div class="message" *ngIf="forgotPwEmail!.errors!.emailValid">
          Email address is invalid. Please enter a valid email address.
        </div>
      </div>
      <button *ngIf="!loggedIn" class="btn" [disabled]="!forgotPwForm.valid">Submit</button>
      <button *ngIf="loggedIn" class="btn logged-in">Submitted</button>

      <p *ngIf="forgotPwForm.errors" class="message info">
        A password reset link was send to your email address.
      </p>
      <p *ngIf="!forgotPwForm.errors" class="message info">
        Enter your user account's email address and we will send you a password reset link.
      </p>
    </div>
  </form>
</div>
