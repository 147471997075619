import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-field-button',
  templateUrl: './field-button.component.html',
  styleUrls: ['./field-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldButtonComponent),
      multi: true,
    },
  ],
})
export class FieldButtonComponent implements OnInit {
  @Input() text = '';
  @Input() iconUrl = '';
  @Input() formControlName = '';
  @Input() disabled = false;

  @Input() theme = 'light';
  @Input() footprint = 'small';
  @Input() success = false;

  value: any = '';
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {}

  ngOnInit(): void {}

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
