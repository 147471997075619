<div
  *ngIf="parentForm"
  [formGroup]="parentForm"
  [ngClass]="{ dark: theme === 'dark', big: footprint === 'big', 'hide-required': hideRequired }"
>
  <label
    [class.error]="
      (parentForm.get(id).invalid && parentForm.errors) ||
      (parentForm.get(id).invalid && parentForm.get(id).touched)
    "
    for="{{ id }}"
    ><ng-content></ng-content
  ></label>

  <textarea
    id="{{ id }}"
    [formControlName]="id"
    [value]="value"
    [rows]="rows"
    [placeholder]="placeholder"
    (input)="onChange($event.target!.value)"
    (blur)="onTouched()"
    [class.invalid]="
      (parentForm.get(id).invalid && parentForm.errors) ||
      (parentForm.get(id).invalid && parentForm.get(id).touched)
    "
  ></textarea>

  <div
    *ngIf="
      (parentForm.get(id).invalid && parentForm.errors) ||
      (parentForm.get(id).invalid && parentForm.get(id).touched)
    "
  >
    <small *ngIf="parentForm.get(id).errors.required" class="error">Field is required</small>
  </div>

  <div class="inline-hints">
    <p [innerHTML]="hint" class="hint"></p>
    <p
      *ngIf="maxChars"
      [innerHTML]="(value ? value.length : 0) + '/' + maxChars"
      [class.error]="parentForm.get(id).invalid && parentForm.get(id).touched"
      class="hint-right"
    ></p>
  </div>
</div>
