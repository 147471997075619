import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CookieService } from './cookie-service/cookie-service.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

// Sentry integration
import * as Sentry from '@sentry/angular';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // get csrf cookie content
    const token = this.cookieService.getCookie('csrftoken');

    // Apply the headers
    if (environment.production === true) {
      req = req.clone({
        withCredentials: false,
        setHeaders: {
          'X-CSRFToken': token,
        },
      });
    }

    // Also handle errors globally
    return next.handle(req).pipe(
      tap(
        (x) => x,
        (err) => {
          // Handle this err
          console.error(`Error performing request, status code = ${err.status}`);
          if (err.status === 404) {
            this.router.navigateByUrl('/404', { replaceUrl: true });
          }
          // to challenge: does this get handled by Sentry.createErrorHandler anyways?
          // this will also result in duplicate issues where console.log/error has been extended with Sentry.captureException
          if (err && err.stack && err.message) {
            Sentry.captureException(err, {
              tags: {
                angularHttpIntercept: 'true',
              },
            });
          } else {
            Sentry.captureException(new Error(err?.message), {
              tags: {
                angularHttpIntercept: 'true',
                requestStatus: err?.status,
                requestError: err?.name,
                requestUrl: err?.url,
              },
            });
          }
        }
      )
    );
  }
}
