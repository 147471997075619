<div *ngIf="show" class="modal-background"></div>
<p-dialog
  [visible]="show"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  [breakpoints]="{ '960px': '75vw' }"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
>
  <ng-template pTemplate="header">
    <div>
      <h2>{{ header }}</h2>
    </div>
  </ng-template>

  <ng-content></ng-content>

  <ng-template pTemplate="footer">
    <div id="modal-buttons">
      <ng-container *ngFor="let buttonText of buttonTexts; let i = index">
        <div class="btn">
          <button
            (click)="buttonFunctions[i] ? buttonFunctions[i]() : null"
            [class.success]="i === buttonFunctions.length - 1 && successStyle"
            [disabled]="i === buttonFunctions.length - 1 && !allowContinue"
            type="button"
          >
            <img *ngIf="buttonIcons && buttonIcons[i]" [src]="buttonIcons[i]" class="btnIcon" />
            <span class="btn-text">{{ buttonText }}</span>
          </button>
        </div>
      </ng-container>
    </div>
  </ng-template>
</p-dialog>
