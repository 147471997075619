<div class="content-wrapper">
  <div class="title">
    <h2>Artists</h2>
  </div>
  <div class="sieve-links">
    <a routerLink="/artists" class="sieve">Pictures</a> |
    <a routerLink="/artists/alph" class="sieve">List</a> |
    <!-- letter links -->
    <ng-container *ngFor="let letter of letters">
      <a [routerLink]="'/artists/' + letter" class="sieve letter">{{ letter }}</a>
    </ng-container>
  </div>

  <div class="no-artists" *ngIf="noArtistsFound">No registered artists with this letter, yet.</div>
  <div class="artists" [class.pictures]="sieve === 'pictures'">
    <div class="artist" *ngFor="let artist of sievedArtists">
      <a [routerLink]="'/artist/' + artist.profileId">
        <div
          *ngIf="sieve === 'pictures' && artist.artworkImage"
          class="image"
          title="{{ artist.artworkImageTitle }} {{ artist.first_name }} {{ artist.last_name }}"
          [style.backgroundImage]="'url(' + (artist.artworkImage | relativePathToBackendUrl) + ')'"
        ></div>
        <div *ngIf="sieve === 'pictures' && !artist.artworkImage" class="image no-artwork">
          Artist has not uploaded any artwork, yet.
        </div>
        {{ artist.first_name }} {{ artist.last_name }}
      </a>
    </div>
  </div>
</div>
