<div [ngClass]="{ dark: theme === 'dark', big: footprint === 'big' }">
  <label class="container" [class.invalid]="error">
    <ng-content></ng-content>
    <input
      [id]="formControlName"
      type="checkbox"
      [checked]="isChecked"
      (change)="onChanged($event)"
      (blur)="onTouched($event)"
      [disabled]="disabled"
    />
    <span [ngClass]="error ? 'red-checkmark' : 'checkmark'"></span>
  </label>
</div>
