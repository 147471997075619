import { Component, Input, OnInit } from '@angular/core';
import { Artworks } from 'src/app/core/services/api/models';
import { ApiService } from 'src/app/core/services/api/services';

@Component({
  selector: 'app-vote-button',
  templateUrl: './vote-button.component.html',
  styleUrls: ['./vote-button.component.scss'],
})
export class VoteButtonComponent implements OnInit {
  @Input() artwork: Artworks;

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {}

  toggleVote(): void {
    if (this.artwork.has_voted) {
      this.downvote();
    } else {
      this.upvote();
    }
  }

  upvote() {
    this.apiService
      .apiV1AwardsAudienceVoteUpdate$Json({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        body: { nominated_artwork_id: this.artwork.artwork_with_award_id },
      })
      .subscribe((r) => {
        this.artwork.has_voted = true;
      });
  }

  downvote() {
    this.apiService
      .apiV1AwardsAudienceUnVoteUpdate$Json({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        body: { nominated_artwork_id: this.artwork.artwork_with_award_id },
      })
      .subscribe((r) => {
        this.artwork.has_voted = false;
      });
  }
}
