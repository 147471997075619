import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WagtailService } from 'angular-wagtail';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-news-index',
  templateUrl: './news-index.component.html',
  styleUrls: ['./news-index.component.scss'],
})
export class NewsIndexComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  data: any;

  constructor(private wagtailService: WagtailService, private router: Router) {}

  // getData() {
  //   this.wagtailService.getPageForCurrentUrl().subscribe((r: any) => {
  //     this.data = r;
  //   });
  // }
  ngOnInit(): void {
    // this.getData();

    this.wagtailService
      .getPageForCurrentUrl()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.data = data;
      });
  }

  newsLinkClicked(url: string) {
    void this.router.navigateByUrl(url);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
