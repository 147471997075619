<div class="p-field">
  <label>
    {{ to.label }}
  </label>
  <p-multiSelect
    [options]="to.selectOptions"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [filter]="false"
    [showHeader]="false"
    optionLabel="name"
    optionValue="code"
    selectedItemsLabel="{0} items selected"
    [dropdownIcon]="'none'"
  ></p-multiSelect>
</div>
