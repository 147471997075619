import { Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { ArtworksList } from 'src/app/core/services/api/models';
import { AppState } from 'src/app/store/app.reducer';
import { selectUser } from 'src/app/store/app.selectors';
@Component({
  selector: 'app-artwork-status',
  templateUrl: './artwork-status.component.html',
  styleUrls: ['./artwork-status.component.scss'],
})
export class ArtworkStatusComponent implements OnChanges {
  @Input() artwork: ArtworksList;
  // * Awards should go via store as well?
  @Input() awards: any[];
  userId = -1;
  awardHasApplied = false;
  awardTitle = 'Art Award';
  moderationStatus: number;

  constructor(private store: Store<AppState>) {
    // Replaced API requests with redux store
    // Alternatives:
    // - API calls: way too many, especially on artwork/artist pages, resulted in hundreds of requests
    // - Cookie: does not yet contain id. Also 'not elegant'
    // - Top down: value passed by parent component (@input). Valid alternative, despite of coupling
    // - Redux store: chosen over top-down as userId/login status must be available to all components, might be overkill by itself
    this.store
      .select(selectUser)
      .pipe(take(1))
      .subscribe((user) => {
        this.userId = user.userId ? user.userId : -1;
      });
  }

  ngOnChanges() {
    this.moderationStatus = this.artwork.moderation_status;
    // console.log('change');
    if (this.userId > 0 && this.awards) {
      this.awardHasApplied =
        this.artwork.applies.length > 0 && this.artwork.creator === this.userId;

      if (this.awardHasApplied) {
        const awardApplied = this.awards.filter((x) => x.id === this.artwork.applies[0]);
        if (awardApplied.length > 0) {
          this.awardTitle = awardApplied ? awardApplied[0].title : null;
        }
      }
    }
  }
}
