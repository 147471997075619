import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService } from 'src/app/core/services/api/base-service';
import { ApiService } from '../../core/services/api/services';

@Component({
  selector: 'app-artwork-view',
  templateUrl: './artwork-view.component.html',
  styleUrls: ['./artwork-view.component.scss'],
})
export class ArtworkViewComponent implements OnInit {
  artworkData: any = {};
  artworkImages: Array<Record<string, string>> = [];
  awards: any = [];
  artworkId: number;
  unique: boolean;
  artistArtworkIdList: Array<number> = [];
  nextArtwork: number;
  previousArtwork: number;
  selectedArtworkIndex: number;
  zoomView = false;
  zoomImage = '';
  artworkVideos: Array<Record<string, any>> = [];

  pageLink = '';
  shareImage;

  constructor(
    private route: ActivatedRoute,
    private baseService: BaseService,
    private apiService: ApiService,
    private router: Router
  ) {
    this.route.params.subscribe((val) => {
      this.artworkId = parseInt(val.id, 10);
      this.retrieveArtworkData();
      this.retrieveAwards();
    });
  }

  ngOnInit() {
    this.pageLink = this.router.url;
  }

  retrieveArtworkData() {
    this.apiService.apiV1ArtworkArtworksRetrieve({ id: this.artworkId }).subscribe((r) => {
      this.artworkData = r;
      this.artworkVideos = this.artworkData.artwork_videos;
      this.unique = this.artworkData.unique;
      this.artworkImages = [];
      const rootUrl = this.baseService.rootUrl;
      for (const image of this.artworkData.artwork_images) {
        this.artworkImages.push({
          // eslint-disable-next-line
          image_large: `${image.image_large}`,
          // eslint-disable-next-line
          image_small: `${image.image_small}`,
        });
      }
      this.shareImage = this.artworkImages[0].image_large;
      this.apiService.apiV1UserArtworksList({ id: this.artworkData.creator }).subscribe((resp) => {
        resp.forEach((artwork) => {
          if (artwork.artwork_images.length > 0) {
            this.artistArtworkIdList.push(artwork.id);
          }
        });
        this.getNeighbourArtwork();
      });
    });
  }

  getNeighbourArtwork() {
    const artworkIndex = this.artistArtworkIdList.indexOf(this.artworkId);
    if (this.artistArtworkIdList.length <= artworkIndex + 1) {
      this.nextArtwork = this.artistArtworkIdList[0];
    } else {
      this.nextArtwork = this.artistArtworkIdList[artworkIndex + 1];
    }

    if (artworkIndex === 0) {
      this.previousArtwork = this.artistArtworkIdList[this.artistArtworkIdList.length - 1];
    } else {
      this.previousArtwork = this.artistArtworkIdList[artworkIndex - 1];
    }
  }

  retrieveAwards() {
    this.apiService.apiV1AwardsAwardsRetrieve$Response().subscribe((r) => {
      let rawAwards: any = [];
      rawAwards = r.body;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      this.awards = rawAwards.filter(({ user_can_apply }) => user_can_apply === true);
    });
  }

  scrollImage(id: number) {
    const el = document.getElementById(`target${id}`);
    this.selectedArtworkIndex = id;
    const y = el.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  openZoomView(image?: string) {
    this.zoomView = !this.zoomView;
    this.zoomImage = image;
  }
}
