<div
  *ngIf="parentForm"
  [formGroup]="parentForm"
  [ngClass]="{ dark: theme === 'dark', big: footprint === 'big', 'hide-required': hideRequired }"
>
  <label [class.error]="parentForm.get(id).invalid && parentForm.get(id).touched">
    {{ printLabel() }}
  </label>
  <input
    [class.invalid]="parentForm.get(id).invalid && parentForm.get(id).touched"
    [formControlName]="id"
    type="text"
    [value]="value"
    [placeholder]="placeholder"
    (input)="onChange($event.target!.value)"
    (blur)="onTouched()"
  />
  <div *ngIf="parentForm.get(id).invalid && parentForm.get(id).touched">
    <small *ngIf="parentForm.get(id).errors.required" class="error">Field is required</small>
    <small *ngIf="parentForm.get(id).errors.framedOneRequiresTheOther" class="error">
      Required when one of the framed dimensions is entered
    </small>
    <small
      *ngIf="parentForm.get(id).errors.emailValid && !parentForm.get(id).errors.required"
      class="error"
    >
      Please enter a valid E-mail
    </small>
    <small *ngIf="parentForm.get(id).errors.urlValid" class="error">Please enter a valid URL</small>
    <small *ngIf="parentForm.get(id).errors.dateValid" class="error">
      Please enter a valid date
    </small>
    <small *ngIf="parentForm.get(id).errors.pattern" class="error">
      Please enter numbers only
    </small>
    <small *ngIf="parentForm.get(id).errors.minlength" class="error">
      Must conatiner at least {{ parentForm.get(id).errors.minlength.requiredLength }} characters
    </small>
    <small
      *ngIf="
        parentForm.get(id).errors.validLengthFormat &&
        !parentForm.get(id).errors.framedOneRequiresTheOther &&
        !parentForm.get(id).errors.required
      "
      class="error"
    >
      Please use only numbers. Seperate decimals with a comma or a period
    </small>
  </div>
  <p class="hint">{{ !!hint ? hint : '' }}</p>
</div>
