<div *ngFor="let entry of entries">
  <div class="button-wrapper">
    <div *ngIf="entry.icon">
      <app-button
        class="button"
        [text]="entry.title"
        [icon]="entry.icon | relativePathToBackendUrl"
        [url]="entry.target"
        externalLink="true"
      ></app-button>
    </div>
    <div *ngIf="!entry.icon">
      <app-button
        class="button"
        [text]="entry.title"
        [url]="entry.target"
        externalLink="true"
      ></app-button>
    </div>
  </div>
</div>
