import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { signupValidators } from '../../form-validators/signup-in.validators';
import { SignupInStatusService } from '../../../core/services/signup-in-status/signup-in-status.service';
import { ApiService } from 'src/app/core/services/api/services';
import { Login } from 'src/app/core/services/api/models';
import { Router } from '@angular/router';
import { UserDataService } from 'src/app/core/services/user-data/user-data.service';
import { CookieService } from 'src/app/core/services/cookie-service/cookie-service.service';
import { DefaultSendResetPasswordLink } from 'src/app/core/services/api/models/default-send-reset-password-link';
import { ProfileStatusService } from 'src/app/core/services/profile-status/profile-status.service';
// ART-545, for beta only - this is probably not DSGVO compliant
import * as Sentry from '@sentry/angular';

import { User } from '../../interfaces/user';
import { setUser } from 'src/app/store/app.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss'],
})
export class UserLoginComponent {
  @Output() closed = new EventEmitter();

  forgotPassword = false;

  /// Form///
  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, signupValidators.emailValid]),
    password: new UntypedFormControl('', Validators.required),
  });

  forgotPwForm = new UntypedFormGroup({
    forgotPwEmail: new UntypedFormControl('', [Validators.required, signupValidators.emailValid]),
  });

  // placeholder for the servercall to validate the form
  formIsValid = false;
  wrongPassword = false;
  loggedIn: boolean;

  pwFormIsValid = false;

  constructor(
    private signupInStatusService: SignupInStatusService,
    private apiService: ApiService,
    private router: Router,
    private userService: UserDataService,
    private cookieService: CookieService,
    private profileStatusService: ProfileStatusService,
    private store: Store<AppState>
  ) {
    this.signupInStatusService.loggedIn.subscribe((val) => (this.loggedIn = val));
  }

  login = () => {
    if (this.form.invalid) {
      this.form.setErrors({ invalidLogin: true });
      return;
    }

    const requestBody: Login = {
      email: this.email.value,
      password: this.password.value,
    };

    this.apiService.apiV1UserLoginCreate$FormData$Response({ body: requestBody }).subscribe(
      (r) => {
        this.userService.userFirstName.next(r.body.first_name);
        this.userService.userLastName.next(r.body.last_name);
        this.apiService.apiV1UserVerifySessionRetrieve().subscribe((rObj: any) => {
          if (rObj.is_authenticated) {
            this.cookieService.setCookie('userType', rObj.user_profile_type, { secure: true });
          }
          if (rObj.user_profile_type == null) {
            this.userService.userFirstName.next('Admin');
            this.cookieService.setCookie('userProfile', 'true', { secure: true });
          }

          this.signupInStatusService.setLoggedIn(true);
          this.profileStatusService.setCookie();
          this.ensureUserInStore();

          setTimeout(() => {
            this.signupInStatusService.closeAll();
            if (
              this.cookieService.getCookie('userProfile') ||
              this.cookieService.getCookie('userType') === 'null'
            ) {
              void this.router.navigate(['/']);
              this.profileStatusService.profileLess.next(false);
            } else {
              if (this.cookieService.getCookie('userType') === 'artist') {
                void this.router.navigate(['/artist-editor']);
              } else if (this.cookieService.getCookie('userType') === 'user') {
                void this.router.navigate(['/user-editor']);
              }
            }
          }, 1500);
        });
      },
      () => {
        this.wrongPassword = true;
      }
    );
  };

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }
  get forgotPwEmail() {
    return this.forgotPwForm.get('forgotPwEmail');
  }

  /// //////////////////////////////////

  sendMail() {
    if (this.pwFormIsValid) {
      this.forgotPwForm.setErrors({ worngMail: true });
    }
    const requestBody: DefaultSendResetPasswordLink = {
      login: this.forgotPwEmail.value,
    };
    this.apiService
      .apiV1UserSendResetPasswordLinkCreate$Json$Response({ body: requestBody })
      .subscribe((r) => {
        this.loggedIn = true;
        setTimeout(() => {
          this.loggedIn = !this.loggedIn;
        }, 2000);
      });
  }

  forgotPwClick = () => (this.forgotPassword = true);

  closeLogin = () => this.signupInStatusService.toggleLogin();

  ensureUserInStore() {
    this.apiService.apiV1UserVerifySessionRetrieve().subscribe((rObj: any) => {
      if (rObj.is_authenticated) {
        const profileType = rObj.user_profile_type ? rObj.user_profile_type : 'admin';
        let apiProfileEndpoint;

        if (profileType === 'artist') {
          apiProfileEndpoint = () => this.apiService.apiV1UserArtistProfilesRetrieve$Response();
        } else {
          apiProfileEndpoint = () => this.apiService.apiV1UserUserProfilesRetrieve$Response();
        }

        apiProfileEndpoint().subscribe(
          (r: any) => {
            const data: any = r.body;
            // ART-545 - to be changed/remove before going live
            Sentry.setUser({ email: data.user_data.email });
            const userLoggedIn: User = {
              firstName: data.user_data.first_name,
              lastName: data.user_data.last_name,
              profileType,
              profileId: data.id,
              userId: data.user,
            };
            this.store.dispatch(setUser(userLoggedIn));
          },
          (error) => {
            console.log(error.error);
          }
        );
      }
    });
  }

  onClick() {
    this.closed.emit();
  }
}
