<a
  *ngIf="!url.startsWith('http') && url.length !== 0"
  class="btn"
  [ngClass]="theme"
  [class.fixed-width]="fixedWidth"
  [class.disabled]="disabled"
  [routerLink]="url"
>
  <img *ngIf="icon !== ''" [src]="icon" alt="" />{{ text }}
</a>

<a
  *ngIf="url.startsWith('http') && url.length !== 0"
  class="btn"
  [ngClass]="theme"
  [class.fixed-width]="fixedWidth"
  [class.disabled]="disabled"
  [href]="url"
>
  <img *ngIf="icon !== ''" [src]="icon" alt="" />{{ text }}
</a>

<a
  *ngIf="url === ''"
  class="btn"
  [ngClass]="theme"
  [class.fixed-width]="fixedWidth"
  [class.disabled]="disabled"
>
  <img *ngIf="icon !== ''" [src]="icon" alt="" />{{ text }}
</a>
