import { Pipe, PipeTransform } from '@angular/core';
import { TechniqueService } from 'src/app/core/services/technique/technique.service';

@Pipe({
  name: 'resolveTechnique',
})
export class ResolveTechniquePipe implements PipeTransform {
  techniques: Array<any> = this.techniqueService.technique;

  constructor(private techniqueService: TechniqueService) {}

  transform(value: string): string {
    const technique = this.techniques.find((el) => el.value === value);
    return technique.text;
  }
}
