import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseService } from 'src/app/core/services/api/base-service';
import { ApiService } from '../../core/services/api/services';

@Component({
  selector: 'app-artist-view',
  templateUrl: './artist-view.component.html',
  styleUrls: ['./artist-view.component.scss'],
})
export class ArtistViewComponent implements OnInit {
  profileId: number;
  profileData: any = {};
  // eslint-disable-next-line @typescript-eslint/ban-types
  artistVideo: Array<object> = [];
  userId: number; // this is different from the profileId
  userArtworkImages: Array<any> = [];

  pageLink = '';

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private baseService: BaseService,
    private router: Router
  ) {
    this.profileId = parseInt(this.route.snapshot.paramMap.get('id'), 10);

    this.apiService.apiV1UserPublicArtistProfilesRetrieve({ id: this.profileId }).subscribe((r) => {
      this.profileData = r;
      this.userId = this.profileData.user;
      this.artistVideo = this.profileData.artist_videos;

      this.apiService.apiV1UserArtworksList({ id: this.userId }).subscribe((resp) => {
        // const rootUrl = this.baseService.rootUrl;
        resp.forEach((artwork) => {
          const artworkObj = {
            url: artwork.artwork_images[0].image_small,
            id: artwork.id,
          };
          if (artwork.artwork_images.length > 0) {
            this.userArtworkImages.push(artworkObj);
          }
        });
      });
    });
  }

  scroll(el: HTMLElement) {
    const y = el.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  ngOnInit(): void {
    this.pageLink = this.router.url;
  }
}
