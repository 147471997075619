import { AfterContentChecked, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-textblock',
  templateUrl: './textblock.component.html',
  styleUrls: ['./textblock.component.scss'],
})
export class TextblockComponent implements AfterContentChecked {
  @ViewChild('textRef') textRef: ElementRef;

  collapse = false;
  textIsLarge = false;

  content = '';

  constructor() {}

  ngAfterContentChecked() {
    this.checkTextHeight();
  }

  seeMore = () => {
    this.collapse = !this.collapse;
  };

  checkTextHeight() {
    if (!this.textRef) {
      return;
    }

    // check if content changed, return if it didn't.
    // otherwise, e.g. clicking sidebar links would trigger the code below.
    const content = this.textRef.nativeElement.innerHTML;
    if (content === this.content) {
      return;
    }

    this.content = content;

    const height = this.textRef.nativeElement.offsetHeight;
    if (height > 600) {
      this.textIsLarge = true;
      this.collapse = true;
    }
  }
}
