/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ArtistProfile } from '../models/artist-profile';
import { ArtistsProfileList } from '../models/artists-profile-list';
import { ArtworkWithAward } from '../models/artwork-with-award';
import { ArtworkWithAwardPostBody } from '../models/artwork-with-award-post-body';
import { Artworks } from '../models/artworks';
import { ArtworksList } from '../models/artworks-list';
import { AudienceVote } from '../models/audience-vote';
import { Award } from '../models/award';
import { ChangePassword } from '../models/change-password';
import { DefaultRegisterEmail } from '../models/default-register-email';
import { DefaultSendResetPasswordLink } from '../models/default-send-reset-password-link';
import { FooterForm } from '../models/footer-form';
import { Login } from '../models/login';
import { Logout } from '../models/logout';
import { NominatedArtworks } from '../models/nominated-artworks';
import { PatchedArtworkImagesOrder } from '../models/patched-artwork-images-order';
import { PatchedSetArtworkOrder } from '../models/patched-set-artwork-order';
import { ResetPassword } from '../models/reset-password';
import { SetOrderResponse } from '../models/set-order-response';
import { UserData } from '../models/user-data';
import { UserProfile } from '../models/user-profile';
import { UserRegister } from '../models/user-register';
import { VerifyEmail } from '../models/verify-email';
import { VerifyRegistration } from '../models/verify-registration';
import { VerifySession } from '../models/verify-session';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation apiV1ArtworkArtworkDocumentsDestroy
   */
  static readonly ApiV1ArtworkArtworkDocumentsDestroyPath = '/api/v1/artwork/artwork-documents/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkArtworkDocumentsDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ArtworkArtworkDocumentsDestroy$Response(params: {
    /**
     * id of artwork document
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1ArtworkArtworkDocumentsDestroyPath,
      'delete'
    );
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkArtworkDocumentsDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ArtworkArtworkDocumentsDestroy(params: {
    /**
     * id of artwork document
     */
    id: number;
  }): Observable<void> {
    return this.apiV1ArtworkArtworkDocumentsDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1ArtworkArtworkImagesDestroy
   */
  static readonly ApiV1ArtworkArtworkImagesDestroyPath = '/api/v1/artwork/artwork-images/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkArtworkImagesDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ArtworkArtworkImagesDestroy$Response(params: {
    /**
     * id of artwork image
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1ArtworkArtworkImagesDestroyPath,
      'delete'
    );
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkArtworkImagesDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ArtworkArtworkImagesDestroy(params: {
    /**
     * id of artwork image
     */
    id: number;
  }): Observable<void> {
    return this.apiV1ArtworkArtworkImagesDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1ArtworkArtworkStampsDestroy
   */
  static readonly ApiV1ArtworkArtworkStampsDestroyPath = '/api/v1/artwork/artwork-stamps/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkArtworkStampsDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ArtworkArtworkStampsDestroy$Response(params: {
    /**
     * id of artwork stamp
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1ArtworkArtworkStampsDestroyPath,
      'delete'
    );
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkArtworkStampsDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ArtworkArtworkStampsDestroy(params: {
    /**
     * id of artwork stamp
     */
    id: number;
  }): Observable<void> {
    return this.apiV1ArtworkArtworkStampsDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1ArtworkArtworkVideosDestroy
   */
  static readonly ApiV1ArtworkArtworkVideosDestroyPath = '/api/v1/artwork/artwork-videos/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkArtworkVideosDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ArtworkArtworkVideosDestroy$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1ArtworkArtworkVideosDestroyPath,
      'delete'
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkArtworkVideosDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ArtworkArtworkVideosDestroy(params: { id: number }): Observable<void> {
    return this.apiV1ArtworkArtworkVideosDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1ArtworkArtworksList
   */
  static readonly ApiV1ArtworkArtworksListPath = '/api/v1/artwork/artworks/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkArtworksList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ArtworkArtworksList$Response(params?: {}): Observable<
    StrictHttpResponse<Array<ArtworksList>>
  > {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1ArtworkArtworksListPath, 'get');
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ArtworksList>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkArtworksList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ArtworkArtworksList(params?: {}): Observable<Array<ArtworksList>> {
    return this.apiV1ArtworkArtworksList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ArtworksList>>) => r.body as Array<ArtworksList>)
    );
  }

  /**
   * Path part for operation apiV1ArtworkArtworksUpdate
   */
  static readonly ApiV1ArtworkArtworksUpdatePath = '/api/v1/artwork/artworks/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkArtworksUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1ArtworkArtworksUpdate$Json$Response(params: {
    body: Artworks;
  }): Observable<StrictHttpResponse<Artworks>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1ArtworkArtworksUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Artworks>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkArtworksUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1ArtworkArtworksUpdate$Json(params: { body: Artworks }): Observable<Artworks> {
    return this.apiV1ArtworkArtworksUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Artworks>) => r.body as Artworks)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkArtworksUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1ArtworkArtworksUpdate$XWwwFormUrlencoded$Response(params: {
    body: Artworks;
  }): Observable<StrictHttpResponse<Artworks>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1ArtworkArtworksUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Artworks>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkArtworksUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1ArtworkArtworksUpdate$XWwwFormUrlencoded(params: { body: Artworks }): Observable<Artworks> {
    return this.apiV1ArtworkArtworksUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Artworks>) => r.body as Artworks)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkArtworksUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1ArtworkArtworksUpdate$FormData$Response(params: {
    body: Artworks;
  }): Observable<StrictHttpResponse<Artworks>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1ArtworkArtworksUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Artworks>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkArtworksUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1ArtworkArtworksUpdate$FormData(params: { body: Artworks }): Observable<Artworks> {
    return this.apiV1ArtworkArtworksUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Artworks>) => r.body as Artworks)
    );
  }

  /**
   * Path part for operation apiV1ArtworkArtworksCreate
   */
  static readonly ApiV1ArtworkArtworksCreatePath = '/api/v1/artwork/artworks/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkArtworksCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1ArtworkArtworksCreate$Json$Response(params: {
    body: Artworks;
  }): Observable<StrictHttpResponse<Artworks>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1ArtworkArtworksCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Artworks>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkArtworksCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1ArtworkArtworksCreate$Json(params: { body: Artworks }): Observable<Artworks> {
    return this.apiV1ArtworkArtworksCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Artworks>) => r.body as Artworks)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkArtworksCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1ArtworkArtworksCreate$XWwwFormUrlencoded$Response(params: {
    body: Artworks;
  }): Observable<StrictHttpResponse<Artworks>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1ArtworkArtworksCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Artworks>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkArtworksCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1ArtworkArtworksCreate$XWwwFormUrlencoded(params: { body: Artworks }): Observable<Artworks> {
    return this.apiV1ArtworkArtworksCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Artworks>) => r.body as Artworks)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkArtworksCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1ArtworkArtworksCreate$FormData$Response(params: {
    body: Artworks;
  }): Observable<StrictHttpResponse<Artworks>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1ArtworkArtworksCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Artworks>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkArtworksCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1ArtworkArtworksCreate$FormData(params: { body: Artworks }): Observable<Artworks> {
    return this.apiV1ArtworkArtworksCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Artworks>) => r.body as Artworks)
    );
  }

  /**
   * Path part for operation apiV1ArtworkArtworksRetrieve
   */
  static readonly ApiV1ArtworkArtworksRetrievePath = '/api/v1/artwork/artworks/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkArtworksRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ArtworkArtworksRetrieve$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Artworks>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1ArtworkArtworksRetrievePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Artworks>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkArtworksRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ArtworkArtworksRetrieve(params: { id: number }): Observable<Artworks> {
    return this.apiV1ArtworkArtworksRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Artworks>) => r.body as Artworks)
    );
  }

  /**
   * Path part for operation apiV1ArtworkArtworksDestroy
   */
  static readonly ApiV1ArtworkArtworksDestroyPath = '/api/v1/artwork/artworks/{id}/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkArtworksDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ArtworkArtworksDestroy$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1ArtworkArtworksDestroyPath,
      'delete'
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkArtworksDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1ArtworkArtworksDestroy(params: { id: number }): Observable<void> {
    return this.apiV1ArtworkArtworksDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1ArtworkArtworksDisplayOrdersPartialUpdate
   */
  static readonly ApiV1ArtworkArtworksDisplayOrdersPartialUpdatePath =
    '/api/v1/artwork/artworks/display-orders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkArtworksDisplayOrdersPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1ArtworkArtworksDisplayOrdersPartialUpdate$Json$Response(params?: {
    body?: PatchedSetArtworkOrder;
  }): Observable<StrictHttpResponse<SetOrderResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1ArtworkArtworksDisplayOrdersPartialUpdatePath,
      'patch'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SetOrderResponse>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkArtworksDisplayOrdersPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1ArtworkArtworksDisplayOrdersPartialUpdate$Json(params?: {
    body?: PatchedSetArtworkOrder;
  }): Observable<SetOrderResponse> {
    return this.apiV1ArtworkArtworksDisplayOrdersPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SetOrderResponse>) => r.body as SetOrderResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkArtworksDisplayOrdersPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1ArtworkArtworksDisplayOrdersPartialUpdate$XWwwFormUrlencoded$Response(params?: {
    body?: PatchedSetArtworkOrder;
  }): Observable<StrictHttpResponse<SetOrderResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1ArtworkArtworksDisplayOrdersPartialUpdatePath,
      'patch'
    );
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SetOrderResponse>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkArtworksDisplayOrdersPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1ArtworkArtworksDisplayOrdersPartialUpdate$XWwwFormUrlencoded(params?: {
    body?: PatchedSetArtworkOrder;
  }): Observable<SetOrderResponse> {
    return this.apiV1ArtworkArtworksDisplayOrdersPartialUpdate$XWwwFormUrlencoded$Response(
      params
    ).pipe(map((r: StrictHttpResponse<SetOrderResponse>) => r.body as SetOrderResponse));
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkArtworksDisplayOrdersPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1ArtworkArtworksDisplayOrdersPartialUpdate$FormData$Response(params?: {
    body?: PatchedSetArtworkOrder;
  }): Observable<StrictHttpResponse<SetOrderResponse>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1ArtworkArtworksDisplayOrdersPartialUpdatePath,
      'patch'
    );
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<SetOrderResponse>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkArtworksDisplayOrdersPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1ArtworkArtworksDisplayOrdersPartialUpdate$FormData(params?: {
    body?: PatchedSetArtworkOrder;
  }): Observable<SetOrderResponse> {
    return this.apiV1ArtworkArtworksDisplayOrdersPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<SetOrderResponse>) => r.body as SetOrderResponse)
    );
  }

  /**
   * Path part for operation apiV1ArtworkOrderArtworkImagesPartialUpdate
   */
  static readonly ApiV1ArtworkOrderArtworkImagesPartialUpdatePath =
    '/api/v1/artwork/order-artwork-images/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkOrderArtworkImagesPartialUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1ArtworkOrderArtworkImagesPartialUpdate$Json$Response(params?: {
    body?: PatchedArtworkImagesOrder;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1ArtworkOrderArtworkImagesPartialUpdatePath,
      'patch'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkOrderArtworkImagesPartialUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1ArtworkOrderArtworkImagesPartialUpdate$Json(params?: {
    body?: PatchedArtworkImagesOrder;
  }): Observable<void> {
    return this.apiV1ArtworkOrderArtworkImagesPartialUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkOrderArtworkImagesPartialUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1ArtworkOrderArtworkImagesPartialUpdate$XWwwFormUrlencoded$Response(params?: {
    body?: PatchedArtworkImagesOrder;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1ArtworkOrderArtworkImagesPartialUpdatePath,
      'patch'
    );
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkOrderArtworkImagesPartialUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1ArtworkOrderArtworkImagesPartialUpdate$XWwwFormUrlencoded(params?: {
    body?: PatchedArtworkImagesOrder;
  }): Observable<void> {
    return this.apiV1ArtworkOrderArtworkImagesPartialUpdate$XWwwFormUrlencoded$Response(
      params
    ).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1ArtworkOrderArtworkImagesPartialUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1ArtworkOrderArtworkImagesPartialUpdate$FormData$Response(params?: {
    body?: PatchedArtworkImagesOrder;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1ArtworkOrderArtworkImagesPartialUpdatePath,
      'patch'
    );
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1ArtworkOrderArtworkImagesPartialUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1ArtworkOrderArtworkImagesPartialUpdate$FormData(params?: {
    body?: PatchedArtworkImagesOrder;
  }): Observable<void> {
    return this.apiV1ArtworkOrderArtworkImagesPartialUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AwardsNominationsList
   */
  static readonly ApiV1AwardsNominationsListPath = '/api/v1/awards/{award_id}/nominations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AwardsNominationsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AwardsNominationsList$Response(params: {
    award_id: number;
  }): Observable<StrictHttpResponse<Array<NominatedArtworks>>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1AwardsNominationsListPath, 'get');
    if (params) {
      rb.path('award_id', params.award_id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<NominatedArtworks>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AwardsNominationsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AwardsNominationsList(params: { award_id: number }): Observable<Array<NominatedArtworks>> {
    return this.apiV1AwardsNominationsList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NominatedArtworks>>) => r.body as Array<NominatedArtworks>)
    );
  }

  /**
   * Path part for operation apiV1AwardsAudienceUnVoteUpdate
   */
  static readonly ApiV1AwardsAudienceUnVoteUpdatePath = '/api/v1/awards/audience-un-vote/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AwardsAudienceUnVoteUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AwardsAudienceUnVoteUpdate$Json$Response(params: {
    body: AudienceVote;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1AwardsAudienceUnVoteUpdatePath,
      'put'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AwardsAudienceUnVoteUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AwardsAudienceUnVoteUpdate$Json(params: { body: AudienceVote }): Observable<void> {
    return this.apiV1AwardsAudienceUnVoteUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AwardsAudienceUnVoteUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1AwardsAudienceUnVoteUpdate$XWwwFormUrlencoded$Response(params: {
    body: AudienceVote;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1AwardsAudienceUnVoteUpdatePath,
      'put'
    );
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AwardsAudienceUnVoteUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1AwardsAudienceUnVoteUpdate$XWwwFormUrlencoded(params: {
    body: AudienceVote;
  }): Observable<void> {
    return this.apiV1AwardsAudienceUnVoteUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AwardsAudienceUnVoteUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1AwardsAudienceUnVoteUpdate$FormData$Response(params: {
    body: AudienceVote;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1AwardsAudienceUnVoteUpdatePath,
      'put'
    );
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AwardsAudienceUnVoteUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1AwardsAudienceUnVoteUpdate$FormData(params: { body: AudienceVote }): Observable<void> {
    return this.apiV1AwardsAudienceUnVoteUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AwardsAudienceVoteUpdate
   */
  static readonly ApiV1AwardsAudienceVoteUpdatePath = '/api/v1/awards/audience-vote/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AwardsAudienceVoteUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AwardsAudienceVoteUpdate$Json$Response(params: {
    body: AudienceVote;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1AwardsAudienceVoteUpdatePath,
      'put'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AwardsAudienceVoteUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AwardsAudienceVoteUpdate$Json(params: { body: AudienceVote }): Observable<void> {
    return this.apiV1AwardsAudienceVoteUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AwardsAudienceVoteUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1AwardsAudienceVoteUpdate$XWwwFormUrlencoded$Response(params: {
    body: AudienceVote;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1AwardsAudienceVoteUpdatePath,
      'put'
    );
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AwardsAudienceVoteUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1AwardsAudienceVoteUpdate$XWwwFormUrlencoded(params: {
    body: AudienceVote;
  }): Observable<void> {
    return this.apiV1AwardsAudienceVoteUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AwardsAudienceVoteUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1AwardsAudienceVoteUpdate$FormData$Response(params: {
    body: AudienceVote;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1AwardsAudienceVoteUpdatePath,
      'put'
    );
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AwardsAudienceVoteUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1AwardsAudienceVoteUpdate$FormData(params: { body: AudienceVote }): Observable<void> {
    return this.apiV1AwardsAudienceVoteUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1AwardsAwardsRetrieve
   */
  static readonly ApiV1AwardsAwardsRetrievePath = '/api/v1/awards/awards/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AwardsAwardsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AwardsAwardsRetrieve$Response(params?: {
    /**
     * id of award
     */
    id?: number;
  }): Observable<StrictHttpResponse<Award>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1AwardsAwardsRetrievePath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Award>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AwardsAwardsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AwardsAwardsRetrieve(params?: {
    /**
     * id of award
     */
    id?: number;
  }): Observable<Award> {
    return this.apiV1AwardsAwardsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<Award>) => r.body as Award)
    );
  }

  /**
   * Path part for operation apiV1AwardsNominationsRetrieve
   */
  static readonly ApiV1AwardsNominationsRetrievePath = '/api/v1/awards/nominations/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AwardsNominationsRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AwardsNominationsRetrieve$Response(params?: {
    /**
     * id
     */
    id?: number;
  }): Observable<StrictHttpResponse<ArtworkWithAward>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1AwardsNominationsRetrievePath,
      'get'
    );
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ArtworkWithAward>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AwardsNominationsRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AwardsNominationsRetrieve(params?: {
    /**
     * id
     */
    id?: number;
  }): Observable<ArtworkWithAward> {
    return this.apiV1AwardsNominationsRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<ArtworkWithAward>) => r.body as ArtworkWithAward)
    );
  }

  /**
   * Path part for operation apiV1AwardsNominationsCreate
   */
  static readonly ApiV1AwardsNominationsCreatePath = '/api/v1/awards/nominations/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AwardsNominationsCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AwardsNominationsCreate$Json$Response(params: {
    body: ArtworkWithAwardPostBody;
  }): Observable<StrictHttpResponse<ArtworkWithAward>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1AwardsNominationsCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ArtworkWithAward>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AwardsNominationsCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1AwardsNominationsCreate$Json(params: {
    body: ArtworkWithAwardPostBody;
  }): Observable<ArtworkWithAward> {
    return this.apiV1AwardsNominationsCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ArtworkWithAward>) => r.body as ArtworkWithAward)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AwardsNominationsCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1AwardsNominationsCreate$XWwwFormUrlencoded$Response(params: {
    body: ArtworkWithAwardPostBody;
  }): Observable<StrictHttpResponse<ArtworkWithAward>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1AwardsNominationsCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ArtworkWithAward>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AwardsNominationsCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1AwardsNominationsCreate$XWwwFormUrlencoded(params: {
    body: ArtworkWithAwardPostBody;
  }): Observable<ArtworkWithAward> {
    return this.apiV1AwardsNominationsCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<ArtworkWithAward>) => r.body as ArtworkWithAward)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AwardsNominationsCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1AwardsNominationsCreate$FormData$Response(params: {
    body: ArtworkWithAwardPostBody;
  }): Observable<StrictHttpResponse<ArtworkWithAward>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1AwardsNominationsCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ArtworkWithAward>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AwardsNominationsCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1AwardsNominationsCreate$FormData(params: {
    body: ArtworkWithAwardPostBody;
  }): Observable<ArtworkWithAward> {
    return this.apiV1AwardsNominationsCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<ArtworkWithAward>) => r.body as ArtworkWithAward)
    );
  }

  /**
   * Path part for operation apiV1AwardsNominationsDestroy
   */
  static readonly ApiV1AwardsNominationsDestroyPath = '/api/v1/awards/nominations/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1AwardsNominationsDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AwardsNominationsDestroy$Response(params: {
    /**
     * id
     */
    id: number;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1AwardsNominationsDestroyPath,
      'delete'
    );
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1AwardsNominationsDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1AwardsNominationsDestroy(params: {
    /**
     * id
     */
    id: number;
  }): Observable<void> {
    return this.apiV1AwardsNominationsDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1UserArtistDegreeDestroy
   */
  static readonly ApiV1UserArtistDegreeDestroyPath = '/api/v1/user/artist-degree/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserArtistDegreeDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserArtistDegreeDestroy$Response(params?: {}): Observable<
    StrictHttpResponse<ArtistProfile>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserArtistDegreeDestroyPath,
      'delete'
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ArtistProfile>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserArtistDegreeDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserArtistDegreeDestroy(params?: {}): Observable<ArtistProfile> {
    return this.apiV1UserArtistDegreeDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<ArtistProfile>) => r.body as ArtistProfile)
    );
  }

  /**
   * Path part for operation apiV1UserArtistDocumentsDestroy
   */
  static readonly ApiV1UserArtistDocumentsDestroyPath = '/api/v1/user/artist-documents/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserArtistDocumentsDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserArtistDocumentsDestroy$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserArtistDocumentsDestroyPath,
      'delete'
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserArtistDocumentsDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserArtistDocumentsDestroy(params: { id: number }): Observable<void> {
    return this.apiV1UserArtistDocumentsDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1UserArtistNominationsList
   */
  static readonly ApiV1UserArtistNominationsListPath = '/api/v1/user/artist-nominations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserArtistNominationsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserArtistNominationsList$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<ArtworkWithAward>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserArtistNominationsListPath,
      'get'
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ArtworkWithAward>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserArtistNominationsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserArtistNominationsList(params: { id: number }): Observable<Array<ArtworkWithAward>> {
    return this.apiV1UserArtistNominationsList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ArtworkWithAward>>) => r.body as Array<ArtworkWithAward>)
    );
  }

  /**
   * Path part for operation apiV1UserArtistPortraitDestroy
   */
  static readonly ApiV1UserArtistPortraitDestroyPath = '/api/v1/user/artist-portrait/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserArtistPortraitDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserArtistPortraitDestroy$Response(params?: {}): Observable<
    StrictHttpResponse<ArtistProfile>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserArtistPortraitDestroyPath,
      'delete'
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ArtistProfile>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserArtistPortraitDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserArtistPortraitDestroy(params?: {}): Observable<ArtistProfile> {
    return this.apiV1UserArtistPortraitDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<ArtistProfile>) => r.body as ArtistProfile)
    );
  }

  /**
   * Path part for operation apiV1UserArtistProfilesRetrieve
   */
  static readonly ApiV1UserArtistProfilesRetrievePath = '/api/v1/user/artist-profiles/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserArtistProfilesRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserArtistProfilesRetrieve$Response(params?: {}): Observable<
    StrictHttpResponse<ArtistProfile>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserArtistProfilesRetrievePath,
      'get'
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ArtistProfile>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserArtistProfilesRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserArtistProfilesRetrieve(params?: {}): Observable<ArtistProfile> {
    return this.apiV1UserArtistProfilesRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<ArtistProfile>) => r.body as ArtistProfile)
    );
  }

  /**
   * Path part for operation apiV1UserArtistProfilesUpdate
   */
  static readonly ApiV1UserArtistProfilesUpdatePath = '/api/v1/user/artist-profiles/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserArtistProfilesUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserArtistProfilesUpdate$Json$Response(params: {
    body: ArtistProfile;
  }): Observable<StrictHttpResponse<ArtistProfile>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserArtistProfilesUpdatePath,
      'put'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ArtistProfile>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserArtistProfilesUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserArtistProfilesUpdate$Json(params: { body: ArtistProfile }): Observable<ArtistProfile> {
    return this.apiV1UserArtistProfilesUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ArtistProfile>) => r.body as ArtistProfile)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserArtistProfilesUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserArtistProfilesUpdate$XWwwFormUrlencoded$Response(params: {
    body: ArtistProfile;
  }): Observable<StrictHttpResponse<ArtistProfile>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserArtistProfilesUpdatePath,
      'put'
    );
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ArtistProfile>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserArtistProfilesUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserArtistProfilesUpdate$XWwwFormUrlencoded(params: {
    body: ArtistProfile;
  }): Observable<ArtistProfile> {
    return this.apiV1UserArtistProfilesUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<ArtistProfile>) => r.body as ArtistProfile)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserArtistProfilesUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserArtistProfilesUpdate$FormData$Response(params: {
    body: ArtistProfile;
  }): Observable<StrictHttpResponse<ArtistProfile>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserArtistProfilesUpdatePath,
      'put'
    );
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ArtistProfile>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserArtistProfilesUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserArtistProfilesUpdate$FormData(params: {
    body: ArtistProfile;
  }): Observable<ArtistProfile> {
    return this.apiV1UserArtistProfilesUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<ArtistProfile>) => r.body as ArtistProfile)
    );
  }

  /**
   * Path part for operation apiV1UserArtistVideosDestroy
   */
  static readonly ApiV1UserArtistVideosDestroyPath = '/api/v1/user/artist-videos/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserArtistVideosDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserArtistVideosDestroy$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserArtistVideosDestroyPath,
      'delete'
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserArtistVideosDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserArtistVideosDestroy(params: { id: number }): Observable<void> {
    return this.apiV1UserArtistVideosDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1UserArtworksList
   */
  static readonly ApiV1UserArtworksListPath = '/api/v1/user/artworks/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserArtworksList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserArtworksList$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Array<ArtworksList>>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserArtworksListPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ArtworksList>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserArtworksList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserArtworksList(params: { id: number }): Observable<Array<ArtworksList>> {
    return this.apiV1UserArtworksList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ArtworksList>>) => r.body as Array<ArtworksList>)
    );
  }

  /**
   * Path part for operation apiV1UserChangePasswordCreate
   */
  static readonly ApiV1UserChangePasswordCreatePath = '/api/v1/user/change-password/';

  /**
   * Change the user password.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserChangePasswordCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserChangePasswordCreate$Json$Response(params: {
    body: ChangePassword;
  }): Observable<StrictHttpResponse<ChangePassword>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserChangePasswordCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ChangePassword>;
        })
      );
  }

  /**
   * Change the user password.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserChangePasswordCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserChangePasswordCreate$Json(params: { body: ChangePassword }): Observable<ChangePassword> {
    return this.apiV1UserChangePasswordCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangePassword>) => r.body as ChangePassword)
    );
  }

  /**
   * Change the user password.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserChangePasswordCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserChangePasswordCreate$XWwwFormUrlencoded$Response(params: {
    body: ChangePassword;
  }): Observable<StrictHttpResponse<ChangePassword>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserChangePasswordCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ChangePassword>;
        })
      );
  }

  /**
   * Change the user password.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserChangePasswordCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserChangePasswordCreate$XWwwFormUrlencoded(params: {
    body: ChangePassword;
  }): Observable<ChangePassword> {
    return this.apiV1UserChangePasswordCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<ChangePassword>) => r.body as ChangePassword)
    );
  }

  /**
   * Change the user password.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserChangePasswordCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserChangePasswordCreate$FormData$Response(params: {
    body: ChangePassword;
  }): Observable<StrictHttpResponse<ChangePassword>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserChangePasswordCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ChangePassword>;
        })
      );
  }

  /**
   * Change the user password.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserChangePasswordCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserChangePasswordCreate$FormData(params: {
    body: ChangePassword;
  }): Observable<ChangePassword> {
    return this.apiV1UserChangePasswordCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<ChangePassword>) => r.body as ChangePassword)
    );
  }

  /**
   * Path part for operation apiV1UserFooterFormCreate
   */
  static readonly ApiV1UserFooterFormCreatePath = '/api/v1/user/footer-form/';

  /**
   * this function sends an email to Artronaut when someone fill the
   * footer get in touch form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserFooterFormCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserFooterFormCreate$Json$Response(params: {
    body: FooterForm;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserFooterFormCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * this function sends an email to Artronaut when someone fill the
   * footer get in touch form
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserFooterFormCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserFooterFormCreate$Json(params: { body: FooterForm }): Observable<void> {
    return this.apiV1UserFooterFormCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * this function sends an email to Artronaut when someone fill the
   * footer get in touch form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserFooterFormCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserFooterFormCreate$XWwwFormUrlencoded$Response(params: {
    body: FooterForm;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserFooterFormCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * this function sends an email to Artronaut when someone fill the
   * footer get in touch form
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserFooterFormCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserFooterFormCreate$XWwwFormUrlencoded(params: { body: FooterForm }): Observable<void> {
    return this.apiV1UserFooterFormCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * this function sends an email to Artronaut when someone fill the
   * footer get in touch form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserFooterFormCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserFooterFormCreate$FormData$Response(params: {
    body: FooterForm;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserFooterFormCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        })
      );
  }

  /**
   * this function sends an email to Artronaut when someone fill the
   * footer get in touch form
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserFooterFormCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserFooterFormCreate$FormData(params: { body: FooterForm }): Observable<void> {
    return this.apiV1UserFooterFormCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiV1UserLoginCreate
   */
  static readonly ApiV1UserLoginCreatePath = '/api/v1/user/login/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserLoginCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserLoginCreate$Json$Response(params: {
    body: Login;
  }): Observable<StrictHttpResponse<UserData>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserLoginCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserData>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserLoginCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserLoginCreate$Json(params: { body: Login }): Observable<UserData> {
    return this.apiV1UserLoginCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserData>) => r.body as UserData)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserLoginCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserLoginCreate$XWwwFormUrlencoded$Response(params: {
    body: Login;
  }): Observable<StrictHttpResponse<UserData>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserLoginCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserData>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserLoginCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserLoginCreate$XWwwFormUrlencoded(params: { body: Login }): Observable<UserData> {
    return this.apiV1UserLoginCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<UserData>) => r.body as UserData)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserLoginCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserLoginCreate$FormData$Response(params: {
    body: Login;
  }): Observable<StrictHttpResponse<UserData>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserLoginCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserData>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserLoginCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserLoginCreate$FormData(params: { body: Login }): Observable<UserData> {
    return this.apiV1UserLoginCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<UserData>) => r.body as UserData)
    );
  }

  /**
   * Path part for operation apiV1UserLogoutCreate
   */
  static readonly ApiV1UserLogoutCreatePath = '/api/v1/user/logout/';

  /**
   * Logs out the user. returns an error if the user is not
   * authenticated.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserLogoutCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserLogoutCreate$Json$Response(params?: {
    body?: Logout;
  }): Observable<StrictHttpResponse<Logout>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserLogoutCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Logout>;
        })
      );
  }

  /**
   * Logs out the user. returns an error if the user is not
   * authenticated.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserLogoutCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserLogoutCreate$Json(params?: { body?: Logout }): Observable<Logout> {
    return this.apiV1UserLogoutCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Logout>) => r.body as Logout)
    );
  }

  /**
   * Logs out the user. returns an error if the user is not
   * authenticated.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserLogoutCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserLogoutCreate$XWwwFormUrlencoded$Response(params?: {
    body?: Logout;
  }): Observable<StrictHttpResponse<Logout>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserLogoutCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Logout>;
        })
      );
  }

  /**
   * Logs out the user. returns an error if the user is not
   * authenticated.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserLogoutCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserLogoutCreate$XWwwFormUrlencoded(params?: { body?: Logout }): Observable<Logout> {
    return this.apiV1UserLogoutCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<Logout>) => r.body as Logout)
    );
  }

  /**
   * Logs out the user. returns an error if the user is not
   * authenticated.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserLogoutCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserLogoutCreate$FormData$Response(params?: {
    body?: Logout;
  }): Observable<StrictHttpResponse<Logout>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserLogoutCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Logout>;
        })
      );
  }

  /**
   * Logs out the user. returns an error if the user is not
   * authenticated.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserLogoutCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserLogoutCreate$FormData(params?: { body?: Logout }): Observable<Logout> {
    return this.apiV1UserLogoutCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Logout>) => r.body as Logout)
    );
  }

  /**
   * Path part for operation apiV1UserPublicArtistProfilesList
   */
  static readonly ApiV1UserPublicArtistProfilesListPath = '/api/v1/user/public-artist-profiles/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserPublicArtistProfilesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserPublicArtistProfilesList$Response(params?: {}): Observable<
    StrictHttpResponse<Array<ArtistsProfileList>>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserPublicArtistProfilesListPath,
      'get'
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<ArtistsProfileList>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserPublicArtistProfilesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserPublicArtistProfilesList(params?: {}): Observable<Array<ArtistsProfileList>> {
    return this.apiV1UserPublicArtistProfilesList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ArtistsProfileList>>) => r.body as Array<ArtistsProfileList>)
    );
  }

  /**
   * Path part for operation apiV1UserPublicArtistProfilesRetrieve
   */
  static readonly ApiV1UserPublicArtistProfilesRetrievePath =
    '/api/v1/user/public-artist-profiles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserPublicArtistProfilesRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserPublicArtistProfilesRetrieve$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<ArtistProfile>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserPublicArtistProfilesRetrievePath,
      'get'
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ArtistProfile>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserPublicArtistProfilesRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserPublicArtistProfilesRetrieve(params: { id: number }): Observable<ArtistProfile> {
    return this.apiV1UserPublicArtistProfilesRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<ArtistProfile>) => r.body as ArtistProfile)
    );
  }

  /**
   * Path part for operation apiV1UserPublicUserProfilesRetrieve
   */
  static readonly ApiV1UserPublicUserProfilesRetrievePath =
    '/api/v1/user/public-user-profiles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserPublicUserProfilesRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserPublicUserProfilesRetrieve$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<UserProfile>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserPublicUserProfilesRetrievePath,
      'get'
    );
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserProfile>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserPublicUserProfilesRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserPublicUserProfilesRetrieve(params: { id: number }): Observable<UserProfile> {
    return this.apiV1UserPublicUserProfilesRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<UserProfile>) => r.body as UserProfile)
    );
  }

  /**
   * Path part for operation apiV1UserRegisterCreate
   */
  static readonly ApiV1UserRegisterCreatePath = '/api/v1/user/register/';

  /**
   * Register new user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserRegisterCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserRegisterCreate$Json$Response(params: {
    body: UserRegister;
  }): Observable<StrictHttpResponse<UserRegister>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserRegisterCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserRegister>;
        })
      );
  }

  /**
   * Register new user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserRegisterCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserRegisterCreate$Json(params: { body: UserRegister }): Observable<UserRegister> {
    return this.apiV1UserRegisterCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserRegister>) => r.body as UserRegister)
    );
  }

  /**
   * Register new user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserRegisterCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserRegisterCreate$XWwwFormUrlencoded$Response(params: {
    body: UserRegister;
  }): Observable<StrictHttpResponse<UserRegister>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserRegisterCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserRegister>;
        })
      );
  }

  /**
   * Register new user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserRegisterCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserRegisterCreate$XWwwFormUrlencoded(params: {
    body: UserRegister;
  }): Observable<UserRegister> {
    return this.apiV1UserRegisterCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<UserRegister>) => r.body as UserRegister)
    );
  }

  /**
   * Register new user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserRegisterCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserRegisterCreate$FormData$Response(params: {
    body: UserRegister;
  }): Observable<StrictHttpResponse<UserRegister>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserRegisterCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserRegister>;
        })
      );
  }

  /**
   * Register new user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserRegisterCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserRegisterCreate$FormData(params: { body: UserRegister }): Observable<UserRegister> {
    return this.apiV1UserRegisterCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<UserRegister>) => r.body as UserRegister)
    );
  }

  /**
   * Path part for operation apiV1UserRegisterEmailCreate
   */
  static readonly ApiV1UserRegisterEmailCreatePath = '/api/v1/user/register-email/';

  /**
   * Register new email.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserRegisterEmailCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserRegisterEmailCreate$Json$Response(params: {
    body: DefaultRegisterEmail;
  }): Observable<StrictHttpResponse<DefaultRegisterEmail>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserRegisterEmailCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DefaultRegisterEmail>;
        })
      );
  }

  /**
   * Register new email.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserRegisterEmailCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserRegisterEmailCreate$Json(params: {
    body: DefaultRegisterEmail;
  }): Observable<DefaultRegisterEmail> {
    return this.apiV1UserRegisterEmailCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DefaultRegisterEmail>) => r.body as DefaultRegisterEmail)
    );
  }

  /**
   * Register new email.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserRegisterEmailCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserRegisterEmailCreate$XWwwFormUrlencoded$Response(params: {
    body: DefaultRegisterEmail;
  }): Observable<StrictHttpResponse<DefaultRegisterEmail>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserRegisterEmailCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DefaultRegisterEmail>;
        })
      );
  }

  /**
   * Register new email.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserRegisterEmailCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserRegisterEmailCreate$XWwwFormUrlencoded(params: {
    body: DefaultRegisterEmail;
  }): Observable<DefaultRegisterEmail> {
    return this.apiV1UserRegisterEmailCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<DefaultRegisterEmail>) => r.body as DefaultRegisterEmail)
    );
  }

  /**
   * Register new email.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserRegisterEmailCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserRegisterEmailCreate$FormData$Response(params: {
    body: DefaultRegisterEmail;
  }): Observable<StrictHttpResponse<DefaultRegisterEmail>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserRegisterEmailCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DefaultRegisterEmail>;
        })
      );
  }

  /**
   * Register new email.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserRegisterEmailCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserRegisterEmailCreate$FormData(params: {
    body: DefaultRegisterEmail;
  }): Observable<DefaultRegisterEmail> {
    return this.apiV1UserRegisterEmailCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<DefaultRegisterEmail>) => r.body as DefaultRegisterEmail)
    );
  }

  /**
   * Path part for operation apiV1UserResetPasswordCreate
   */
  static readonly ApiV1UserResetPasswordCreatePath = '/api/v1/user/reset-password/';

  /**
   * Reset password, given the signature and timestamp from the link.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserResetPasswordCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserResetPasswordCreate$Json$Response(params: {
    body: ResetPassword;
  }): Observable<StrictHttpResponse<ResetPassword>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserResetPasswordCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResetPassword>;
        })
      );
  }

  /**
   * Reset password, given the signature and timestamp from the link.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserResetPasswordCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserResetPasswordCreate$Json(params: { body: ResetPassword }): Observable<ResetPassword> {
    return this.apiV1UserResetPasswordCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ResetPassword>) => r.body as ResetPassword)
    );
  }

  /**
   * Reset password, given the signature and timestamp from the link.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserResetPasswordCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserResetPasswordCreate$XWwwFormUrlencoded$Response(params: {
    body: ResetPassword;
  }): Observable<StrictHttpResponse<ResetPassword>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserResetPasswordCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResetPassword>;
        })
      );
  }

  /**
   * Reset password, given the signature and timestamp from the link.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserResetPasswordCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserResetPasswordCreate$XWwwFormUrlencoded(params: {
    body: ResetPassword;
  }): Observable<ResetPassword> {
    return this.apiV1UserResetPasswordCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<ResetPassword>) => r.body as ResetPassword)
    );
  }

  /**
   * Reset password, given the signature and timestamp from the link.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserResetPasswordCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserResetPasswordCreate$FormData$Response(params: {
    body: ResetPassword;
  }): Observable<StrictHttpResponse<ResetPassword>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserResetPasswordCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ResetPassword>;
        })
      );
  }

  /**
   * Reset password, given the signature and timestamp from the link.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserResetPasswordCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserResetPasswordCreate$FormData(params: {
    body: ResetPassword;
  }): Observable<ResetPassword> {
    return this.apiV1UserResetPasswordCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<ResetPassword>) => r.body as ResetPassword)
    );
  }

  /**
   * Path part for operation apiV1UserSendResetPasswordLinkCreate
   */
  static readonly ApiV1UserSendResetPasswordLinkCreatePath =
    '/api/v1/user/send-reset-password-link/';

  /**
   * Send email with reset password link.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserSendResetPasswordLinkCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserSendResetPasswordLinkCreate$Json$Response(params: {
    body: DefaultSendResetPasswordLink;
  }): Observable<StrictHttpResponse<DefaultSendResetPasswordLink>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserSendResetPasswordLinkCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DefaultSendResetPasswordLink>;
        })
      );
  }

  /**
   * Send email with reset password link.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserSendResetPasswordLinkCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserSendResetPasswordLinkCreate$Json(params: {
    body: DefaultSendResetPasswordLink;
  }): Observable<DefaultSendResetPasswordLink> {
    return this.apiV1UserSendResetPasswordLinkCreate$Json$Response(params).pipe(
      map(
        (r: StrictHttpResponse<DefaultSendResetPasswordLink>) =>
          r.body as DefaultSendResetPasswordLink
      )
    );
  }

  /**
   * Send email with reset password link.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserSendResetPasswordLinkCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserSendResetPasswordLinkCreate$XWwwFormUrlencoded$Response(params: {
    body: DefaultSendResetPasswordLink;
  }): Observable<StrictHttpResponse<DefaultSendResetPasswordLink>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserSendResetPasswordLinkCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DefaultSendResetPasswordLink>;
        })
      );
  }

  /**
   * Send email with reset password link.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserSendResetPasswordLinkCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserSendResetPasswordLinkCreate$XWwwFormUrlencoded(params: {
    body: DefaultSendResetPasswordLink;
  }): Observable<DefaultSendResetPasswordLink> {
    return this.apiV1UserSendResetPasswordLinkCreate$XWwwFormUrlencoded$Response(params).pipe(
      map(
        (r: StrictHttpResponse<DefaultSendResetPasswordLink>) =>
          r.body as DefaultSendResetPasswordLink
      )
    );
  }

  /**
   * Send email with reset password link.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserSendResetPasswordLinkCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserSendResetPasswordLinkCreate$FormData$Response(params: {
    body: DefaultSendResetPasswordLink;
  }): Observable<StrictHttpResponse<DefaultSendResetPasswordLink>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserSendResetPasswordLinkCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<DefaultSendResetPasswordLink>;
        })
      );
  }

  /**
   * Send email with reset password link.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserSendResetPasswordLinkCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserSendResetPasswordLinkCreate$FormData(params: {
    body: DefaultSendResetPasswordLink;
  }): Observable<DefaultSendResetPasswordLink> {
    return this.apiV1UserSendResetPasswordLinkCreate$FormData$Response(params).pipe(
      map(
        (r: StrictHttpResponse<DefaultSendResetPasswordLink>) =>
          r.body as DefaultSendResetPasswordLink
      )
    );
  }

  /**
   * Path part for operation apiV1UserUserPortraitDestroy
   */
  static readonly ApiV1UserUserPortraitDestroyPath = '/api/v1/user/user-portrait/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserUserPortraitDestroy()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserUserPortraitDestroy$Response(params?: {}): Observable<StrictHttpResponse<UserProfile>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserUserPortraitDestroyPath,
      'delete'
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserProfile>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserUserPortraitDestroy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserUserPortraitDestroy(params?: {}): Observable<UserProfile> {
    return this.apiV1UserUserPortraitDestroy$Response(params).pipe(
      map((r: StrictHttpResponse<UserProfile>) => r.body as UserProfile)
    );
  }

  /**
   * Path part for operation apiV1UserUserProfilesRetrieve
   */
  static readonly ApiV1UserUserProfilesRetrievePath = '/api/v1/user/user-profiles/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserUserProfilesRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserUserProfilesRetrieve$Response(params?: {}): Observable<StrictHttpResponse<UserProfile>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserUserProfilesRetrievePath,
      'get'
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserProfile>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserUserProfilesRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserUserProfilesRetrieve(params?: {}): Observable<UserProfile> {
    return this.apiV1UserUserProfilesRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<UserProfile>) => r.body as UserProfile)
    );
  }

  /**
   * Path part for operation apiV1UserUserProfilesUpdate
   */
  static readonly ApiV1UserUserProfilesUpdatePath = '/api/v1/user/user-profiles/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserUserProfilesUpdate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserUserProfilesUpdate$Json$Response(params: {
    body: UserProfile;
  }): Observable<StrictHttpResponse<UserProfile>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserUserProfilesUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserProfile>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserUserProfilesUpdate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserUserProfilesUpdate$Json(params: { body: UserProfile }): Observable<UserProfile> {
    return this.apiV1UserUserProfilesUpdate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UserProfile>) => r.body as UserProfile)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserUserProfilesUpdate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserUserProfilesUpdate$XWwwFormUrlencoded$Response(params: {
    body: UserProfile;
  }): Observable<StrictHttpResponse<UserProfile>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserUserProfilesUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserProfile>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserUserProfilesUpdate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserUserProfilesUpdate$XWwwFormUrlencoded(params: {
    body: UserProfile;
  }): Observable<UserProfile> {
    return this.apiV1UserUserProfilesUpdate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<UserProfile>) => r.body as UserProfile)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserUserProfilesUpdate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserUserProfilesUpdate$FormData$Response(params: {
    body: UserProfile;
  }): Observable<StrictHttpResponse<UserProfile>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserUserProfilesUpdatePath, 'put');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<UserProfile>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserUserProfilesUpdate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserUserProfilesUpdate$FormData(params: { body: UserProfile }): Observable<UserProfile> {
    return this.apiV1UserUserProfilesUpdate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<UserProfile>) => r.body as UserProfile)
    );
  }

  /**
   * Path part for operation apiV1UserVerifyEmailCreate
   */
  static readonly ApiV1UserVerifyEmailCreatePath = '/api/v1/user/verify-email/';

  /**
   * Verify email via signature.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserVerifyEmailCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserVerifyEmailCreate$Json$Response(params: {
    body: VerifyEmail;
  }): Observable<StrictHttpResponse<VerifyEmail>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserVerifyEmailCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VerifyEmail>;
        })
      );
  }

  /**
   * Verify email via signature.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserVerifyEmailCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserVerifyEmailCreate$Json(params: { body: VerifyEmail }): Observable<VerifyEmail> {
    return this.apiV1UserVerifyEmailCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<VerifyEmail>) => r.body as VerifyEmail)
    );
  }

  /**
   * Verify email via signature.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserVerifyEmailCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserVerifyEmailCreate$XWwwFormUrlencoded$Response(params: {
    body: VerifyEmail;
  }): Observable<StrictHttpResponse<VerifyEmail>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserVerifyEmailCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VerifyEmail>;
        })
      );
  }

  /**
   * Verify email via signature.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserVerifyEmailCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserVerifyEmailCreate$XWwwFormUrlencoded(params: {
    body: VerifyEmail;
  }): Observable<VerifyEmail> {
    return this.apiV1UserVerifyEmailCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<VerifyEmail>) => r.body as VerifyEmail)
    );
  }

  /**
   * Verify email via signature.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserVerifyEmailCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserVerifyEmailCreate$FormData$Response(params: {
    body: VerifyEmail;
  }): Observable<StrictHttpResponse<VerifyEmail>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiV1UserVerifyEmailCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VerifyEmail>;
        })
      );
  }

  /**
   * Verify email via signature.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserVerifyEmailCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserVerifyEmailCreate$FormData(params: { body: VerifyEmail }): Observable<VerifyEmail> {
    return this.apiV1UserVerifyEmailCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<VerifyEmail>) => r.body as VerifyEmail)
    );
  }

  /**
   * Path part for operation apiV1UserVerifyRegistrationCreate
   */
  static readonly ApiV1UserVerifyRegistrationCreatePath = '/api/v1/user/verify-registration/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserVerifyRegistrationCreate$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserVerifyRegistrationCreate$Json$Response(params: {
    body: VerifyRegistration;
  }): Observable<StrictHttpResponse<VerifyRegistration>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserVerifyRegistrationCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VerifyRegistration>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserVerifyRegistrationCreate$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiV1UserVerifyRegistrationCreate$Json(params: {
    body: VerifyRegistration;
  }): Observable<VerifyRegistration> {
    return this.apiV1UserVerifyRegistrationCreate$Json$Response(params).pipe(
      map((r: StrictHttpResponse<VerifyRegistration>) => r.body as VerifyRegistration)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserVerifyRegistrationCreate$XWwwFormUrlencoded()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserVerifyRegistrationCreate$XWwwFormUrlencoded$Response(params: {
    body: VerifyRegistration;
  }): Observable<StrictHttpResponse<VerifyRegistration>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserVerifyRegistrationCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'application/x-www-form-urlencoded');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VerifyRegistration>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserVerifyRegistrationCreate$XWwwFormUrlencoded$Response()` instead.
   *
   * This method sends `application/x-www-form-urlencoded` and handles request body of type `application/x-www-form-urlencoded`.
   */
  apiV1UserVerifyRegistrationCreate$XWwwFormUrlencoded(params: {
    body: VerifyRegistration;
  }): Observable<VerifyRegistration> {
    return this.apiV1UserVerifyRegistrationCreate$XWwwFormUrlencoded$Response(params).pipe(
      map((r: StrictHttpResponse<VerifyRegistration>) => r.body as VerifyRegistration)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserVerifyRegistrationCreate$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserVerifyRegistrationCreate$FormData$Response(params: {
    body: VerifyRegistration;
  }): Observable<StrictHttpResponse<VerifyRegistration>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserVerifyRegistrationCreatePath,
      'post'
    );
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VerifyRegistration>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserVerifyRegistrationCreate$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiV1UserVerifyRegistrationCreate$FormData(params: {
    body: VerifyRegistration;
  }): Observable<VerifyRegistration> {
    return this.apiV1UserVerifyRegistrationCreate$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<VerifyRegistration>) => r.body as VerifyRegistration)
    );
  }

  /**
   * Path part for operation apiV1UserVerifySessionRetrieve
   */
  static readonly ApiV1UserVerifySessionRetrievePath = '/api/v1/user/verify-session/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiV1UserVerifySessionRetrieve()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserVerifySessionRetrieve$Response(params?: {}): Observable<
    StrictHttpResponse<VerifySession>
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ApiV1UserVerifySessionRetrievePath,
      'get'
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<VerifySession>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiV1UserVerifySessionRetrieve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiV1UserVerifySessionRetrieve(params?: {}): Observable<VerifySession> {
    return this.apiV1UserVerifySessionRetrieve$Response(params).pipe(
      map((r: StrictHttpResponse<VerifySession>) => r.body as VerifySession)
    );
  }
}
