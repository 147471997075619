/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BaseService } from 'src/app/core/services/api/base-service';
import { ApiService } from 'src/app/core/services/api/services';
import { AvailabilityService } from 'src/app/core/services/availability/availability.service';
import { CategoryService } from 'src/app/core/services/category/category.service';
import { GenreService } from 'src/app/core/services/genre/genre.service';
import { LengthUnitConversionService } from 'src/app/core/services/length-unit-conversion/length-unit-conversion.service';
import { LocationService } from 'src/app/core/services/location/location.service';
import { MaterialService } from 'src/app/core/services/material/material.service';
import { SignatureService } from 'src/app/core/services/signature/signature.service';
import { TechniqueService } from 'src/app/core/services/technique/technique.service';
import { UnitService } from 'src/app/core/services/unit/unit.service';
import { WeightUnitConversionService } from 'src/app/core/services/weight-unit-conversions/weight-unit-conversion.service';
import {
  CanDirtyCheck,
  WarnOnUnsavedComponent,
} from 'src/app/shared/components/warn-on-unsaved/warn-on-unsaved.component';

import * as Sentry from '@sentry/angular';
import {
  CreatorTypeEnum,
  SavedFramedUnitEnum,
  SavedUnitEnum,
  SavedWeightUnitEnum,
} from 'src/app/core/services/api/models';

@Component({
  selector: 'app-formly-artwork-editor',
  templateUrl: './formly-artwork-editor.component.html',
  styleUrls: ['./formly-artwork-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyArtworkEditorComponent implements OnInit, CanDirtyCheck {
  @ViewChild(WarnOnUnsavedComponent) warnerComponent: WarnOnUnsavedComponent;

  // Status indicators
  displayLoadingIndicator = false;
  showErrorOnSaveModal = false;
  success = false;
  resetSuccess = false;
  errors = false;

  genre = this.genreService.genre;
  category = this.categoryService.category;
  material = this.materialService.material;
  availability = this.availabilityService.availability;
  location = this.locationService.location;
  technique = this.techniqueService.technique;
  distanceUnit = this.unitService.distance;
  weightUnit = this.unitService.weight;
  signature = this.signatureService.signature;

  artworkId: number;
  userId: number;

  // Form
  form = new UntypedFormGroup({});

  // Model for user form
  model: any = {};

  // Form options
  options: FormlyFormOptions = {};

  // Fields configuration
  fields: FormlyFieldConfig[] = [
    // Artwork Images - row 1
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'artwork_images',
          type: 'file',
          className: 'flex-1',
          templateOptions: {
            label: 'Portrait',
            multiple: true,
            type: 'image',
            required: true,
            maxNoOfFiles: 10,
            maxFileSize: 10,
            maxTotalFileSize: 100,
            description: 'Drag or click to upload your images of <b>one artwork</b',
            helpText:
              'Here you can upload several images of one artwork, such as: main image, ' +
              'framed, detail, installation view, signature etc.</br>' +
              'Please name your images as follows: Last name_First name_Title_Year_Material_Size_Edition if applicable</br>' +
              'Please upload a JPG or PNG format with a height or width of at least 2000 px.',
          },
          validation: {
            messages: {
              required: 'This field is required.',
              invalidFileType: 'Please select files of type jpg, jpeg or png',
              invalidNoOfFiles: 'Maximum number of files is 10',
              invalidFileSize: 'Each file can only have a maximum size of 10MB',
            },
          },
        },
      ],
    },
    // Category, Genre- row 2
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'category',
          type: 'dropdown',
          className: 'flex-1',
          templateOptions: {
            label: 'Category',
            required: true,
            options: this.category,
          },
          validation: {
            messages: {
              required: 'Category is required',
            },
          },
        },
        {
          key: 'genre',
          type: 'dropdown',
          className: 'flex-1',
          templateOptions: {
            label: 'Genre',
            required: true,
            options: this.genre,
          },
          validation: {
            messages: {
              required: 'Genre is required',
            },
          },
        },
      ],
    },
    // Availability, Location- row 3
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'availability_status',
          type: 'dropdown',
          className: 'flex-1',
          templateOptions: {
            label: 'Availability status',
            required: true,
            options: this.availability,
          },
          validation: {
            messages: {
              required: 'Availability status is required',
            },
          },
        },
        {
          key: 'location',
          type: 'dropdown',
          className: 'flex-1',
          templateOptions: {
            label: 'Location',
            required: false,
            options: this.location,
          },
        },
      ],
    },
    // Title - row 4
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'title',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Title',
            required: true,
          },
          validation: {
            messages: {
              required: 'Title is required',
            },
          },
        },
      ],
    },
    // Year, Technique - row 5
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'year',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Year',
            required: true,
          },
          validators: {
            validation: ['validYear'],
          },
          validation: {
            messages: {
              required: 'Year is required',
              validYear: 'Invalid year',
            },
          },
        },
        {
          key: 'technique',
          type: 'dropdown',
          className: 'flex-1',
          templateOptions: {
            label: 'Technique',
            required: true,
            options: this.technique,
          },
          validation: {
            messages: {
              required: 'Technique is required',
            },
          },
        },
      ],
    },
    // Material, height, weight, depth, unit - Row 6
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'material',
          type: 'dropdown',
          className: 'flex-5',
          templateOptions: {
            label: 'Material',
            required: true,
            options: this.material,
          },
          validation: {
            messages: {
              required: 'Material is required',
            },
          },
        },

        {
          key: 'height',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Height',
            required: true,
          },
          validators: {
            validation: ['validDimensionFormat'],
          },
          validation: {
            messages: {
              required: 'Height is required',
              validDimensionFormat:
                'Please use only numbers. Separate decimals with a comma or a period',
            },
          },
        },
        {
          key: 'width',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Width',
            required: true,
          },
          validators: {
            validation: ['validDimensionFormat'],
          },
          validation: {
            messages: {
              required: 'Width is required',
              validDimensionFormat:
                'Please use only numbers. Separate decimals with a comma or a period',
            },
          },
        },
        {
          key: 'depth',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Depth',
            required: true,
          },
          validators: {
            validation: ['validDimensionFormat'],
          },
          validation: {
            messages: {
              required: 'Depth is required',
              validDimensionFormat:
                'Please use only numbers. Separate decimals with a comma or a period',
            },
          },
        },
        {
          key: 'saved_unit',
          type: 'dropdown',
          className: 'flex-1',
          templateOptions: {
            label: 'Unit',
            required: true,
            options: this.distanceUnit,
          },
          validation: {
            messages: {
              required: 'Unit is required',
            },
          },
        },
      ],
    },
    // Framed height, weight, depth unit and weight and unit - Row 7
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'framed_height',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Framed Height',
            required: false,
          },
          expressionProperties: {
            'templateOptions.required': this.getFramedDimensionRequiredCondition(),
          },
          validators: {
            validation: ['validDimensionFormat'],
          },
          validation: {
            messages: {
              required: 'Framed height is required',
              validDimensionFormat:
                'Please use only numbers. Separate decimals with a comma or a period',
            },
          },
        },
        {
          key: 'framed_width',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Framed Width',
            required: false,
          },
          expressionProperties: {
            'templateOptions.required': this.getFramedDimensionRequiredCondition(),
          },
          validators: {
            validation: ['validDimensionFormat'],
          },
          validation: {
            messages: {
              required: 'Framed width is required',
              validDimensionFormat:
                'Please use only numbers. Separate decimals with a comma or a period',
            },
          },
        },
        {
          key: 'framed_depth',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Framed Depth',
            required: false,
          },
          expressionProperties: {
            'templateOptions.required': this.getFramedDimensionRequiredCondition(),
          },
          validators: {
            validation: ['validDimensionFormat'],
          },
          validation: {
            messages: {
              required: 'Framed depth is required',
              validDimensionFormat:
                'Please use only numbers. Separate decimals with a comma or a period',
            },
          },
        },
        {
          key: 'saved_framed_unit',
          type: 'dropdown',
          className: 'flex-1',
          templateOptions: {
            label: 'Unit',
            required: false,
            options: this.distanceUnit,
          },
          expressionProperties: {
            'templateOptions.required': this.getFramedDimensionRequiredCondition(),
          },
          validation: {
            messages: {
              required: 'Framed unit is required',
            },
          },
        },
        {
          key: 'weight',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Weight',
            required: true,
          },
          validators: {
            validation: ['validDimensionFormat'],
          },
          validation: {
            messages: {
              required: 'Weight is required',
              validDimensionFormat:
                'Please use only numbers. Separate decimals with a comma or a period',
            },
          },
        },
        {
          key: 'saved_weight_unit',
          type: 'dropdown',
          className: 'flex-1',
          templateOptions: {
            label: 'Unit',
            required: true,
            options: this.weightUnit,
          },
          validation: {
            messages: {
              required: 'Unit is required',
            },
          },
        },
        {
          className: 'flex-1',
        },
        {
          className: 'flex-1',
        },
      ],
    },
    // Signature, External characteristics - row 8
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'signature',
          type: 'dropdown',
          className: 'flex-1',
          templateOptions: {
            label: 'Signature',
            options: this.signature,
          },
        },
        {
          key: 'condition',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'External characteristics, condition',
          },
        },
      ],
    },
    // Artist price, Purchase price - row 9
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'artist_price',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Artist price in €',
          },
          validators: {
            validation: ['validDimensionFormat'],
          },
          validation: {
            messages: {
              validDimensionFormat:
                'Please use only numbers. Separate decimals with a comma or a period',
            },
          },
        },
        {
          key: 'purchase_price',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Purchase price in €',
          },
          validators: {
            validation: ['validDimensionFormat'],
          },
          validation: {
            messages: {
              validDimensionFormat:
                'Please use only numbers. Separate decimals with a comma or a period',
            },
          },
        },
      ],
    },
    // Sales price - row 10
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'sales_price',
          type: 'text',
          className: 'flex-1',
          templateOptions: {
            label: 'Sales price in €',
          },
          validators: {
            validation: ['validDimensionFormat'],
          },
          validation: {
            messages: {
              validDimensionFormat:
                'Please use only numbers. Separate decimals with a comma or a period',
            },
          },
        },
        {
          className: 'flex-1',
        },
      ],
    },
    // Certificate of authenticity - Row 11
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'certificate_of_authenticity',
          type: 'file',
          className: 'flex-1',
          templateOptions: {
            label: 'Certificate of Authenticity',
            multiple: true,
            type: 'file',
            marginTop: true,
            maxNoOfFiles: 10,
            maxFileSize: 5,
            maxTotalFileSize: 50,
            description: 'Drag or click to upload a <b>Certificate of Authenticity</b>',
            helpText:
              'Please name your certificate of authenticity as follows: Last Name_First Name_certificate of authenticity</br>' +
              'Please upload the certificate of authenticity as a PDF format of max. 5MB each.',
          },
          validation: {
            messages: {
              invalidFileType: 'Please select files of type pdf',
              invalidNoOfFiles: 'Maximum number of files is 10',
              invalidFileSize: 'File can only have a maximum size of 5MB each',
            },
          },
        },
      ],
    },
    // Documentation - Row 12
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'artwork_documentation',
          type: 'file',
          className: 'flex-1',
          templateOptions: {
            label: 'Documentation',
            multiple: true,
            type: 'file',
            marginTop: true,
            maxNoOfFiles: 10,
            maxFileSize: 5,
            maxTotalFileSize: 50,
            description: 'Drag or click to upload Documents',
            helpText:
              'Please upload additional documentation about the artwork, such as press articles, texts, interviews, etc. ) ' +
              ' in a PDF format of max. 5MB each.',
          },
          validation: {
            messages: {
              invalidFileType: 'Please select files of type pdf',
              invalidNoOfFiles: 'Maximum number of files is 10',
              invalidFileSize: 'File can only have a maximum size of 5MB each',
              helpText:
                'Please upload additional documentation about the artwork, such as press articles, ' +
                'texts, interviews, etc. in a PDF format of max. 5MB each.',
            },
          },
        },
      ],
    },
    // Vimeo videos - Row 13
    {
      key: 'vimeoVideos',
      type: 'repeat',
      templateOptions: {
        addText: 'Add an additional video',
        sectionLabel: 'Videos',
      },
      fieldArray: {
        fieldGroupClassName: 'display-grid',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'text',
            key: 'url',
            templateOptions: {
              label: 'Please add a URL to a Vimeo video',
              placeholder: 'https://vimeo.com/347119375',
            },
            validators: {
              validation: ['vimeoUrl'],
            },
            validation: {
              messages: {
                vimeoUrl: 'Invalid Vimeo URL',
              },
            },
          },
          {
            key: 'remote',
          },
          {
            key: 'delete',
          },
        ],
      },
    },
    // Ownership, literature - row 14
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'ownership',
          type: 'text-area',
          className: 'flex-1',
          templateOptions: {
            label: 'Ownership',
            sectionHeading: 'Provenance',
            showNA: false,
            rows: 10,
            placeholder:
              'Last Name and First Name of the Owner / Name of the Institution, Ownership YYYY - YYYY',
            hintText:
              'Please enter the required information as follows:<br>' +
              'Last Name and First Name of the Owner / Name of the Institution, Ownership YYYY - YYYY',
          },
        },
        {
          key: 'literature',
          type: 'text-area',
          className: 'flex-1',
          templateOptions: {
            label: 'Literature',
            rows: 10,
            marginTop: true,
            required: false,
            showNA: false,
          },
        },
      ],
    },
    // Exhibitions, Historical numbers - row 15
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'provenance_exhibitions',
          type: 'text-area',
          className: 'flex-1',
          templateOptions: {
            label: 'Exhibitions',
            rows: 10,
            showNA: false,
            placeholder: 'Year, Exhibition Title, Name of Exhibition Location, City, Country',
            hintText:
              'Please enter the required information as specified:<br>' +
              'Year, Exhibition Title, Name of Exhibition Location, City, Country',
          },
        },
        {
          key: 'historical_numbers',
          type: 'text-area',
          className: 'flex-1',
          templateOptions: {
            label: 'Historical numbers',
            rows: 10,
            showNA: false,
          },
        },
      ],
    },
    // Stamps - row 16
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'artwork_stamps',
          type: 'file',
          className: 'flex-1',
          templateOptions: {
            label: 'Stamps',
            multiple: true,
            type: 'image',
            maxNoOfFiles: 10,
            maxFileSize: 5,
            maxTotalFileSize: 50,
            description: 'Drag or click to upload your images of the stamps',
            helpText:
              'Here you can upload several images of the stamps.</br>' +
              'Please name your images as follows: Last name_First name_Title_Year_Material_Size_Edition_Stamp number',
          },
          validation: {
            messages: {
              invalidFileType: 'Please select files of type jpg, jpeg or png',
              invalidNoOfFiles: 'Maximum number of files is 10',
              invalidFileSize: 'Each file can only have a maximum size of 5MB',
            },
          },
        },
      ],
    },
    // Notes - row 15
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'notes',
          type: 'text-area',
          className: 'flex-1',
          templateOptions: {
            label: 'Notes',
            rows: 10,
            showNA: false,
          },
        },
      ],
    },
    // Moderation agreement - row 16
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'moderationAgreement',
          type: 'checkbox',
          className: 'flex-1',
          templateOptions: {
            required: true,
            text: 'agree to the ARTRONAUT team to view the uploaded content for moderation purposes *',
            value: 'agree',
          },
        },
      ],
    },
  ];

  constructor(
    private baseService: BaseService,
    private apiService: ApiService,
    private genreService: GenreService,
    private categoryService: CategoryService,
    private materialService: MaterialService,
    private availabilityService: AvailabilityService,
    private locationService: LocationService,
    private techniqueService: TechniqueService,
    private unitService: UnitService,
    private lengthConversionService: LengthUnitConversionService,
    private signatureService: SignatureService,
    private weightUnitConversionService: WeightUnitConversionService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.artworkId = this.artworkId
      ? this.artworkId
      : parseInt(this.route.snapshot.paramMap.get('id'), 10);

    if (this.artworkId) {
      this.getArtwork();
    }
  }

  getFramedDimensionRequiredCondition() {
    return 'model.framed_height || model.framed_width || model.framed_depth || model.saved_framed_unit';
  }

  // Get artwork if editing
  getArtwork() {
    this.apiService.apiV1ArtworkArtworksRetrieve({ id: this.artworkId }).subscribe(async (r) => {
      let userObserver;
      switch (r.creator_type) {
        case CreatorTypeEnum.Art:
          userObserver = this.apiService.apiV1UserArtistProfilesRetrieve$Response();
          break;
        case CreatorTypeEnum.U:
          userObserver = this.apiService.apiV1UserUserProfilesRetrieve$Response();
          break;
      }

      await new Promise<void>((resolve) =>
        userObserver.subscribe(
          (re) => {
            const data: any = re.body;
            this.userId = data.id;
            resolve();
          },
          (error) => {
            // console.log(error.error);

            // report via Sentry
            if (error && error.stack && error.message) {
              Sentry.captureException(error, {
                tags: {
                  api: 'apiV1ArtworkArtworksRetrieve',
                },
              });
            } else {
              Sentry.captureException(new Error(error?.message), {
                tags: {
                  api: 'apiV1ArtworkArtworksRetrieve',
                  requestStatus: error?.status,
                  requestError: error?.name,
                  requestUrl: error?.url,
                },
              });
            }
          }
        )
      );

      if (this.userId === parseInt(r.creator_profile_id, 10)) {
        this.formatDataStructure(r);
      } else {
        void this.router.navigate(['/404']);
      }
    });
  }

  // Format data received from backend
  formatDataStructure(data) {
    this.model = {};
    this.model = data;
    this.model.artwork_images = this.model.artwork_images.map((image) => ({
      document: image.image_small,
      ...image,
    }));

    if (data.saved_unit === SavedUnitEnum.In) {
      this.model.height = this.lengthConversionService.toInDisplay(data.height);
      this.model.width = this.lengthConversionService.toInDisplay(data.width);
      this.model.depth = this.lengthConversionService.toInDisplay(data.depth);
    }

    if (data.saved_framed_unit === SavedFramedUnitEnum.In) {
      this.model.framed_height = this.lengthConversionService.toInDisplay(data.framed_height);
      this.model.framed_width = this.lengthConversionService.toInDisplay(data.framed_width);
      this.model.framed_depth = this.lengthConversionService.toInDisplay(data.framed_depth);
    }

    if (data.saved_weight_unit === SavedWeightUnitEnum.Kg) {
      this.model.weight = this.weightUnitConversionService.gToKgDisplay(data.weight);
    }

    if (data.saved_weight_unit === SavedWeightUnitEnum.Lbs) {
      this.model.weight = this.weightUnitConversionService.gToLbDisplay(data.weight);
    }

    this.model.artwork_stamps = this.model.artwork_stamps.map((image) => ({
      document: image.image,
      title: 'Current stamp',
      ...image,
    }));

    this.model.vimeoVideos = [];

    if (this.model.artwork_videos.length) {
      this.model.artwork_videos.forEach((video) => {
        this.model.vimeoVideos.push({
          id: video.id,
          url: video.url,
          description: video.description,
          remote: true,
          delete: false,
        });
      });
    } else {
      this.model.vimeoVideos = [{}];
    }
  }
  // Submit function
  onSubmit() {
    if (this.form.invalid) {
      this.errors = true;
      this.form.markAllAsTouched();
      this.form.setErrors({ ...this.form.errors });
      return;
    }

    if (
      this.model.hasOwnProperty('moderationAgreement') &&
      this.model.moderationAgreement === true
    ) {
      this.displayLoadingIndicator = true;

      const requestBody: any = {
        artwork_images: this.model.artwork_images,
        artwork_documentation: this.model.artwork_documentation,
        artwork_stamps: this.model.artwork_stamps,
        certificate_of_authenticity: this.model.certificate_of_authenticity,

        category: this.model.category,
        genre: this.model.genre,
        availability_status: this.model.availability_status,
        location: this.model.location,
        title: this.model.title,
        year: this.model.year,
        technique: this.model.technique,
        material: this.model.material,
        signature: this.model.signature,
        condition: this.model.condition,
        artist_price: this.model.artist_price,
        purchase_price: this.model.purchase_price,
        sales_price: this.model.sales_price,

        saved_unit: this.model.saved_unit,
        saved_framed_unit: this.model.saved_framed_unit,
        saved_weight_unit: this.model.saved_weight_unit,
        ownership: this.model.ownership,
        literature: this.model.literature,
        provenance_exhibitions: this.model.provenance_exhibitions,
        historical_numbers: this.model.historical_numbers,
        notes: this.model.notes,
      };

      //  Convert dimension to cm
      if (this.model.saved_unit === SavedUnitEnum.In) {
        requestBody.width = this.lengthConversionService.toCm(this.model.width);
        requestBody.height = this.lengthConversionService.toCm(this.model.height);
        requestBody.depth = this.lengthConversionService.toCm(this.model.depth);
      }

      //  Convert decimal symbol
      if (this.model.saved_unit === SavedUnitEnum.Cm) {
        requestBody.width = this.lengthConversionService.decimalConverter(this.model.width);
        requestBody.height = this.lengthConversionService.decimalConverter(this.model.height);
        requestBody.depth = this.lengthConversionService.decimalConverter(this.model.depth);
      }

      //  Convert framed dimension to cm
      if (this.model.saved_framed_unit === SavedUnitEnum.In) {
        requestBody.framed_width = this.lengthConversionService.toCm(this.model.framed_width);
        requestBody.framed_height = this.lengthConversionService.toCm(this.model.framed_height);
        requestBody.framed_depth = this.lengthConversionService.toCm(this.model.framed_depth);
      }

      //  Convert decimal symbol
      if (this.model.saved_framed_unit === SavedUnitEnum.Cm) {
        requestBody.framed_width = this.lengthConversionService.decimalConverter(
          this.model.framed_width
        );
        requestBody.framed_height = this.lengthConversionService.decimalConverter(
          this.model.framed_height
        );
        requestBody.framed_depth = this.lengthConversionService.decimalConverter(
          this.model.framed_depth
        );
      }

      if (this.model.saved_framed_unit === undefined) {
        requestBody.framed_width = undefined;
        requestBody.framed_height = undefined;
        requestBody.framed_depth = undefined;
      }

      //  Convert weight to Grams
      if (this.model.saved_weight_unit === SavedWeightUnitEnum.Lbs) {
        requestBody.weight = this.weightUnitConversionService.lbToG(this.model.weight);
      }
      if (this.model.saved_weight_unit === SavedWeightUnitEnum.Kg) {
        requestBody.weight = this.weightUnitConversionService.kgToG(this.model.weight);
      }
      if (this.model.saved_weight_unit === SavedWeightUnitEnum.G) {
        requestBody.weight = this.weightUnitConversionService.decimalConverter(this.model.weight);
      }

      const artworkVideos = [];

      this.model.vimeoVideos.forEach((video) => {
        if (!video.delete) {
          if (video.hasOwnProperty('url')) {
            artworkVideos.push({ id: video.id, url: video.url, description: video.description });
          }
        }
      });

      requestBody.artwork_videos = JSON.stringify(artworkVideos);

      if (this.artworkId) {
        let updatedRequestBody: any = this.getDirtyValues(this.form);
        updatedRequestBody.id = this.artworkId;

        updatedRequestBody = this.convertToStandardUnits(updatedRequestBody);

        const updatedArtworkVideos = [];

        if (updatedRequestBody.hasOwnProperty('vimeoVideos')) {
          this.model.vimeoVideos.forEach((video) => {
            if (!video.delete) {
              if (video.hasOwnProperty('url')) {
                updatedArtworkVideos.push({
                  id: video.id,
                  url: video.url,
                  description: video.description,
                });
              }
            }
          });
          updatedRequestBody.artwork_videos = JSON.stringify(updatedArtworkVideos);
        }

        this.model.vimeoVideos.forEach((video) => {
          if (video.delete) {
            this.apiService.apiV1ArtworkArtworkVideosDestroy$Response({ id: video.id }).subscribe(
              () => {},
              (error) => {
                this.displayLoadingIndicator = false;
                // console.log(error.error);

                // report via Sentry
                if (error && error.stack && error.message) {
                  Sentry.captureException(error, {
                    tags: {
                      api: 'apiV1ArtworkArtworkVideosDestroy$Response',
                    },
                  });
                } else {
                  Sentry.captureException(new Error(error?.message), {
                    tags: {
                      api: 'apiV1ArtworkArtworkVideosDestroy$Response',
                      requestStatus: error?.status,
                      requestError: error?.name,
                      requestUrl: error?.url,
                    },
                  });
                }
              }
            );
          }
        });

        const image_id = this.model.artwork_images.map((a) => a.id);
        const order_number = this.model.artwork_images.map((a) => a.order_number);

        const image_order = order_number.reduce((imag_order, field, index) => {
          imag_order[image_id[index]] = field;
          return imag_order;
        }, {});

        const body = {
          image_order,
        };

        if (order_number[0]) {
          this.apiService
            .apiV1ArtworkOrderArtworkImagesPartialUpdate$Json$Response({ body })
            .subscribe(
              () => {},
              (error) => {
                // report via Sentry
                if (error && error.stack && error.message) {
                  Sentry.captureException(error, {
                    tags: {
                      api: 'apiV1ArtworkOrderArtworkImagesPartialUpdate',
                    },
                  });
                } else {
                  Sentry.captureException(new Error(error?.message), {
                    tags: {
                      api: 'apiV1ArtworkOrderArtworkImagesPartialUpdate',
                      requestStatus: error?.status,
                      requestError: error?.name,
                      requestUrl: error?.url,
                    },
                  });
                }
              }
            );
        }
        this.deleteSelectedFiles(updatedRequestBody);

        this.apiService
          .apiV1ArtworkArtworksUpdate$FormData$Response({ body: updatedRequestBody })
          .subscribe(
            () => {
              this.submitSuccess(this.artworkId);
            },
            (error) => {
              this.showErrorOnSaveModal = true;
              this.displayLoadingIndicator = false;

              // report via Sentry
              if (error && error.stack && error.message) {
                Sentry.captureException(error, {
                  tags: {
                    api: 'apiV1ArtworkArtworksCreate',
                  },
                });
              } else {
                Sentry.captureException(new Error(error?.message), {
                  tags: {
                    api: 'apiV1ArtworkArtworksCreate',
                    requestStatus: error?.status,
                    requestError: error?.name,
                    requestUrl: error?.url,
                  },
                });
              }
            }
          );
      } else {
        this.apiService
          .apiV1ArtworkArtworksCreate$FormData$Response({ body: requestBody })
          .subscribe(
            (response) => {
              this.submitSuccess(response.body.id);
            },
            (error) => {
              this.showErrorOnSaveModal = true;
              this.displayLoadingIndicator = false;
              // console.log(error.error);

              // report via Sentry
              if (error && error.stack && error.message) {
                Sentry.captureException(error, {
                  tags: {
                    api: 'apiV1ArtworkArtworksCreate',
                  },
                });
              } else {
                Sentry.captureException(new Error(error?.message), {
                  tags: {
                    api: 'apiV1ArtworkArtworksCreate',
                    requestStatus: error?.status,
                    requestError: error?.name,
                    requestUrl: error?.url,
                  },
                });
              }
            }
          );
      }
    } else {
      this.errors = true;
      this.form.markAllAsTouched();
      this.form.setErrors({ ...this.form.errors });
    }
  }

  // Reset function
  onReset() {
    this.resetSuccess = true;
    this.options.formState = 'reset';
    this.options.resetModel();
    this.form.updateValueAndValidity();
    this.form.markAsPristine();

    setTimeout(() => {
      this.resetSuccess = false;
      this.options.formState = {};
    }, 2000);
  }

  // Callback for clicking 'OK' on backend error modal
  acknowledgeError = () => {
    this.showErrorOnSaveModal = false;
  };

  getDirtyValues(form: any) {
    const dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) {
          dirtyValues[key] = this.getDirtyValues(currentControl);
        } else {
          dirtyValues[key] = currentControl.value;
        }
      }
    });

    return dirtyValues;
  }

  submitSuccess(id: number) {
    this.displayLoadingIndicator = false;
    this.success = true;

    this.form.updateValueAndValidity();
    this.form.markAsPristine();
    setTimeout(() => {
      this.success = false;
      void this.router.navigate([`/artwork/${id}`]);
    }, 2000);
  }

  convertToStandardUnits(body) {
    //  Convert dimension to cm
    if (this.model.saved_unit === SavedUnitEnum.In) {
      if (body.hasOwnProperty('width') || body.hasOwnProperty('saved_unit')) {
        body.width = this.lengthConversionService.toCm(this.model.width);
      }

      if (body.hasOwnProperty('height') || body.hasOwnProperty('saved_unit')) {
        body.height = this.lengthConversionService.toCm(this.model.height);
      }
      if (body.hasOwnProperty('depth') || body.hasOwnProperty('saved_unit')) {
        body.depth = this.lengthConversionService.toCm(this.model.depth);
      }
    }

    //  Convert decimal symbol
    if (this.model.saved_unit === SavedUnitEnum.Cm) {
      if (body.hasOwnProperty('width') || body.hasOwnProperty('saved_unit')) {
        body.width = this.lengthConversionService.decimalConverter(this.model.width);
      }
      if (body.hasOwnProperty('height') || body.hasOwnProperty('saved_unit')) {
        body.height = this.lengthConversionService.decimalConverter(this.model.height);
      }
      if (body.hasOwnProperty('depth') || body.hasOwnProperty('saved_unit')) {
        body.depth = this.lengthConversionService.decimalConverter(this.model.depth);
      }
    }

    //  Convert framed dimension to cm
    if (this.model.saved_framed_unit === SavedUnitEnum.In) {
      if (body.hasOwnProperty('framed_width') || body.hasOwnProperty('saved_framed_unit')) {
        body.framed_width = this.lengthConversionService.toCm(this.model.framed_width);
      }
      if (body.hasOwnProperty('framed_height') || body.hasOwnProperty('saved_framed_unit')) {
        body.framed_height = this.lengthConversionService.toCm(this.model.framed_height);
      }
      if (body.hasOwnProperty('framed_depth') || body.hasOwnProperty('saved_framed_unit')) {
        body.framed_depth = this.lengthConversionService.toCm(this.model.framed_depth);
      }
    }

    //  Convert decimal symbol
    if (this.model.saved_framed_unit === SavedUnitEnum.Cm) {
      if (body.hasOwnProperty('framed_width') || body.hasOwnProperty('saved_framed_unit')) {
        body.framed_width = this.lengthConversionService.decimalConverter(this.model.framed_width);
      }

      if (body.hasOwnProperty('framed_height') || body.hasOwnProperty('saved_framed_unit')) {
        body.framed_height = this.lengthConversionService.decimalConverter(
          this.model.framed_height
        );
      }
      if (body.hasOwnProperty('framed_depth') || body.hasOwnProperty('saved_framed_unit')) {
        body.framed_depth = this.lengthConversionService.decimalConverter(this.model.framed_depth);
      }
    }

    //  Convert weight to Grams
    if (body.hasOwnProperty('weight') || body.hasOwnProperty('saved_weight_unit')) {
      if (this.model.saved_weight_unit === SavedWeightUnitEnum.Lbs) {
        body.weight = this.weightUnitConversionService.lbToG(this.model.weight);
      }
      if (this.model.saved_weight_unit === SavedWeightUnitEnum.Kg) {
        body.weight = this.weightUnitConversionService.kgToG(this.model.weight);
      }
      if (this.model.saved_weight_unit === SavedWeightUnitEnum.G) {
        body.weight = this.weightUnitConversionService.decimalConverter(this.model.weight);
      }
    }

    return body;
  }

  deleteSelectedFiles(body) {
    if (body.hasOwnProperty('artwork_images')) {
      const filesToDelete = body.artwork_images.filter(
        (image) => image.hasOwnProperty('delete') && image.delete === true
      );
      filesToDelete.forEach((file) => {
        this.apiService.apiV1ArtworkArtworkImagesDestroy$Response({ id: file.id }).subscribe(
          () => {},
          (error) => {
            this.showErrorOnSaveModal = true;
            this.displayLoadingIndicator = false;

            // report via Sentry
            if (error && error.stack && error.message) {
              Sentry.captureException(error, {
                tags: {
                  api: 'apiV1ArtworkArtworkImagesDestroy',
                },
              });
            } else {
              Sentry.captureException(new Error(error?.message), {
                tags: {
                  api: 'apiV1ArtworkArtworkImagesDestroy',
                  requestStatus: error?.status,
                  requestError: error?.name,
                  requestUrl: error?.url,
                },
              });
            }
          }
        );
      });
    }

    if (body.hasOwnProperty('artwork_documentation')) {
      const filesToDelete = body.artwork_documentation.filter(
        (document) => document.hasOwnProperty('delete') && document.delete === true
      );
      filesToDelete.forEach((file) => {
        this.apiService.apiV1ArtworkArtworkDocumentsDestroy$Response({ id: file.id }).subscribe(
          () => {},
          (error) => {
            this.showErrorOnSaveModal = true;
            this.displayLoadingIndicator = false;

            // report via Sentry
            if (error && error.stack && error.message) {
              Sentry.captureException(error, {
                tags: {
                  api: 'apiV1ArtworkArtworkDocumentsDestroy',
                },
              });
            } else {
              Sentry.captureException(new Error(error?.message), {
                tags: {
                  api: 'apiV1ArtworkArtworkDocumentsDestroy',
                  requestStatus: error?.status,
                  requestError: error?.name,
                  requestUrl: error?.url,
                },
              });
            }
          }
        );
      });
    }

    if (body.hasOwnProperty('artwork_stamps')) {
      const filesToDelete = body.artwork_stamps.filter(
        (document) => document.hasOwnProperty('delete') && document.delete === true
      );
      filesToDelete.forEach((file) => {
        this.apiService.apiV1ArtworkArtworkStampsDestroy$Response({ id: file.id }).subscribe(
          () => {},
          (error) => {
            this.showErrorOnSaveModal = true;
            this.displayLoadingIndicator = false;

            // report via Sentry
            if (error && error.stack && error.message) {
              Sentry.captureException(error, {
                tags: {
                  api: 'apiV1ArtworkArtworkStampsDestroy',
                },
              });
            } else {
              Sentry.captureException(new Error(error?.message), {
                tags: {
                  api: 'apiV1ArtworkArtworkStampsDestroy',
                  requestStatus: error?.status,
                  requestError: error?.name,
                  requestUrl: error?.url,
                },
              });
            }
          }
        );
      });
    }
  }
}
