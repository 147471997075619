import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArtistsProfileList } from 'src/app/core/services/api/models';
import { ApiService } from 'src/app/core/services/api/services';
import { ArtistsComponentArtist } from 'src/app/shared/interfaces/artists-component-artist';

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.scss'],
})
export class ArtistsComponent implements OnInit {
  artists: Array<ArtistsComponentArtist> = [];
  sieve: string;
  sievedArtists: Array<ArtistsComponentArtist> = [];
  noArtistsFound = false;

  // generate array of letters
  letters: Array<string> = 'abcdefghijklmnopqrstuvwxyz'.split('');

  constructor(private apiService: ApiService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(async (val) => {
      this.sieve = val.sieve;

      await this.getArtists().then(() => {
        this.sievedArtists = this.sieveArtists([...this.artists], this.sieve);
        this.noArtistsFound = this.sievedArtists.length === 0;
      });
    });
  }

  getArtists() {
    return new Promise<string>((resolve) => {
      if (this.artists.length > 0) {
        return resolve('already got artists');
      }

      this.apiService
        .apiV1UserPublicArtistProfilesList()
        .subscribe((r: Array<ArtistsProfileList>) => {
          r.forEach((artistProfile: ArtistsProfileList) => {
            this.artists.push({
              first_name: artistProfile.user_data.first_name,
              last_name: artistProfile.user_data.last_name,
              profileId: artistProfile.id,
            });
            const artist: ArtistsComponentArtist = this.artists.find(
              (e) => e.profileId === artistProfile.id
            );
            if (artistProfile.artwork_image) {
              artist.artworkImage = artistProfile.artwork_image.image_small;
              artist.artworkImageTitle = artistProfile.artwork_image.title;
            }
          });
          return resolve('got artists');
        });
    });
  }

  // depending on sieve, we filter or shuffle
  sieveArtists(
    artists: Array<ArtistsComponentArtist>,
    sieve: string
  ): Array<ArtistsComponentArtist> {
    // sort alphabetically
    if (sieve === 'alph') {
      return artists.sort((a, b) => a.last_name.localeCompare(b.last_name));
    }
    // if sieve is one letter, filter for artists last name
    else if (sieve.length === 1) {
      const index = this.letters.indexOf(sieve.toLowerCase());
      if (index > -1) {
        return (
          artists
            .filter(
              (e) =>
                // compare normalized (diacritics removed) and lowercased first letter of last name to letter
                e.last_name
                  .substr(0, 1)
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toLowerCase() === this.letters[index]
            )
            // sort alphabetically after filtering
            .sort((a, b) => a.last_name.localeCompare(b.last_name))
        );
      } else {
        console.error('Sieve is not a known letter');
        return artists;
      }
    }
    // default: sieve === pictures. list will always be shuffled
    else {
      return this.shuffle(artists);
    }
  }

  shuffle(array: Array<ArtistsComponentArtist>) {
    let currentIndex: number = array.length;
    let temporaryValue: ArtistsComponentArtist;
    let randomIndex: number;

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
}
