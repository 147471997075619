import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LengthUnitConversionService {
  constructor() {}

  toIn(val: number): number {
    return this.decimalConverter(val) / 2.54;
  }

  toCm(val: number): number {
    return this.decimalConverter(val) * 2.54;
  }

  toInDisplay(val: number): string {
    return this.setMaxDecimalPoints(this.toIn(val), 2);
  }

  toCmDisplay(val: number): string {
    return this.setMaxDecimalPoints(this.toCm(val), 2);
  }

  setMaxDecimalPoints(val: number, dp: number): string {
    return +parseFloat(val.toString()).toFixed(dp) + '';
  }

  decimalConverter(val: number) {
    return Number(parseFloat(String(val).replace(',', '.')).toFixed(2));
  }
}
