import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TitlesService {
  titles = [
    { value: 'mr', text: 'Mr.' },
    { value: 'ms', text: 'Ms.' },
    { value: 'mrs', text: 'Mrs.' },
  ];
}
