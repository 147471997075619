<div class="footer-wrapper">
  <div class="content-wrapper">
    <div class="logo-wrapper">
      <img src="/assets/artronaut logo footer.png" alt="" />
    </div>

    <div class="submenu" *ngFor="let menu of menus">
      <span class="submenu_head">{{ menu.value.title }}</span>
      <ul>
        <li class="submenu_item" *ngFor="let subMenu of menu.value.sub_nav">
          <a [routerLink]="subMenu.meta.slug">
            <p>{{ subMenu.title }}</p>
          </a>
        </li>
      </ul>
    </div>

    <div class="wrap after-submenus"></div>

    <div class="location-wrapper">
      <span class="head">location address</span>
      <p id="location">
        Oberhausener Straße 225<br />
        45476 Mülheim an der Ruhr<br />
        Germany
      </p>
      <p id="mail">
        <a href="mailto:info@artronaut.online">info@artronaut.online</a>
      </p>
      <p>
        <a routerLink=""><span class="head">imprint</span></a>
      </p>
      <p>
        <a routerLink=""><span class="head">privacy policy</span></a>
      </p>
      <p>
        <a routerLink="/contact"><span class="head">contact</span></a>
      </p>
      <p>
        <a routerLink="" (click)="onImproveDialogShow()"><span class="head">improve</span></a>
      </p>
    </div>

    <div class="wrap"></div>

    <div class="contact-wrapper">
      <span class="head">get in touch</span>
      <p class="feedbackText" *ngIf="showFeedback; else showForm" [style]="feedbackStyle">
        {{ feedbackText }}
      </p>
      <ng-template #showForm>
        <form class="form" (ngSubmit)="submitContactForm()" [formGroup]="contactForm">
          <app-field-text
            label=""
            placeholder="NAME"
            id="name"
            theme="dark"
            footprint="big"
            [hideRequired]="true"
            [parentForm]="contactForm"
          ></app-field-text>
          <div class="spacer"></div>
          <app-field-text
            label=""
            placeholder="E-MAIL"
            id="email"
            theme="dark"
            footprint="big"
            [hideRequired]="true"
            [parentForm]="contactForm"
          ></app-field-text>
          <div class="spacer"></div>
          <app-field-textarea
            placeholder="MESSAGE"
            id="message"
            theme="dark"
            footprint="big"
            [rows]="3"
            [hideRequired]="true"
            [parentForm]="contactForm"
          ></app-field-textarea>
          <div class="spacer-big"></div>

          <div class="button-container">
            <app-field-button
              class="button"
              [text]="success ? 'Sent' : 'Send'"
              id="send"
              theme="dark"
              footprint="big"
              [success]="success"
            ></app-field-button>
            <span class="serverError" *ngIf="showError"
              >Something went wrong there. Please try again later.</span
            >
          </div>
        </form>
      </ng-template>
    </div>

    <div class="mailinglist-wrapper">
      <span class="head">mailing list</span>
      <form
        action=""
        class="form"
        (ngSubmit)="submitMailingListForm()"
        [formGroup]="mailingListForm"
      >
        <app-field-text
          label=""
          placeholder="NAME"
          id="name"
          theme="dark"
          footprint="big"
          [hideRequired]="true"
          [parentForm]="mailingListForm"
        ></app-field-text>
        <div class="spacer"></div>
        <app-field-text
          label=""
          placeholder="E-MAIL"
          id="email"
          theme="dark"
          footprint="big"
          [hideRequired]="true"
          [parentForm]="mailingListForm"
        ></app-field-text>
        <div class="spacer"></div>
        <app-field-checkbox id="terms" theme="dark" footprint="big">
          <span class="checkbox-label">I accept the <a href="#">terms and conditions</a></span>
        </app-field-checkbox>
        <div class="spacer-big"></div>
        <app-field-button text="Submit" id="send" theme="dark" footprint="big"></app-field-button>
      </form>
    </div>
    <div class="copyright-wrapper">ⓒ ARTRONAUT space for art 2019-2021</div>
  </div>
  <div *ngIf="isShowImprove">
    <p-dialog
      header="Improvement suggestion"
      [(visible)]="isShowImprove"
      [modal]="true"
      [style]="{ width: '80vw' }"
      [maximizable]="true"
      [baseZIndex]="10000"
      [draggable]="false"
      [resizable]="false"
    >
      <form>
        <h5>Type</h5>
        <p-listbox
          [options]="improveTypes"
          [(ngModel)]="selectedImproveType"
          optionLabel="name"
          optionValue="name"
          [style]="{ width: '100%' }"
        ></p-listbox>
        <h5>Priority</h5>
        <p-listbox
          [options]="improvePriorities"
          [(ngModel)]="selectedImprovePriority"
          optionLabel="name"
          optionValue="name"
          [style]="{ width: '100%' }"
        ></p-listbox>

        <h5>Title</h5>
        <span class="p-float-label">
          <input
            id="float-input"
            type="text"
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%' }"
            [(ngModel)]="improvementTitle"
            pInputText
          />
          <label for="float-input">Summary of your improvement report</label>
        </span>

        <h5>Description</h5>
        <span class="p-float-label">
          <textarea
            id="float-input"
            [(ngModel)]="improvementComment"
            rows="10"
            cols="80"
            [style]="{ width: '100%' }"
            pInputTextarea
          ></textarea>
          <label for="float-input"
            >Bug: expected vs actual behavior? UI/UX: where? Idea: what, why, and for whom?
          </label>
        </span>
      </form>
      <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="isShowImprove = false" label="Abort"></p-button>
        <p-button
          icon="pi pi-check"
          (click)="onImproveSubmit()"
          label="Submit"
          class="p-button-text"
        ></p-button>
      </ng-template>
    </p-dialog>
  </div>
</div>
