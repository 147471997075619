<div class="artwork-overview-wrapper">
  <div #rowLayout cdkDropListGroup class="artwork-overview-grid grid">
    <div
      class="row"
      *ngFor="let itemsRow of getItemsTable(rowLayout)"
      [cdkDropListDisabled]="!editMode"
      cdkDropList
      cdkDropListOrientation="horizontal"
      [cdkDropListData]="itemsRow"
      (cdkDropListDropped)="reorderDroppedItem($event)"
    >
      <div *ngFor="let artwork of itemsRow" class="item" cdkDrag>
        <div class="drag-placeholder" *cdkDragPlaceholder></div>
        <div>
          <div
            [routerLink]="editMode ? [] : ['/artwork/' + artwork.id]"
            class="image-wrapper"
            [class.link-cursor]="!editMode"
          >
            <div
              *ngIf="editMode && !artworksToDelete.includes(artwork.id)"
              class="edit-mode-button-wrapper"
            >
              <div
                class="edit-mode-button drag-handle"
                title="Move {{ artwork.title }}"
                cdkDragHandle
              >
                <img src="/assets/icons/drag_to_move.svg" alt="Move {{ artwork.title }}" />
              </div>
              <ng-template [ngIf]="artwork.nomination_approved === false">
                <div
                  class="edit-mode-button delete-button"
                  title="Delete {{ artwork.title }}"
                  (click)="markForDeletion(artwork.id)"
                >
                  <img src="/assets/icons/recycle_bin.svg" alt="Delete {{ artwork.title }}" />
                </div>
                <a
                  class="edit-mode-button edit-button"
                  [routerLink]="'/artwork-editor/' + artwork.id"
                  title="Edit {{ artwork.title }}"
                >
                  <img
                    src="/assets/icons/edit_icon_grey_wo_circle.svg"
                    alt="Edit {{ artwork.title }}"
                  />
                </a>
              </ng-template>
              <ng-template [ngIf]="artwork.nomination_approved">
                <div>
                  <span style="font-size: 0.8rem"> Editing and Deleting are Disabled </span>
                </div>
              </ng-template>
            </div>
            <div
              *ngIf="artworksToDelete.includes(artwork.id)"
              class="edit-mode-delete-mark"
              (click)="unmarkForDeletion(artwork.id)"
              title="Unmark deletion {{ artwork.title }}"
            >
              <img src="/assets/icons/recycle_bin.svg" alt="Unmark deletion {{ artwork.title }}" />
              <h3>Tap/click<br />to undelete</h3>
            </div>
            <img
              [src]="artwork | mainArtworkImage : 'image_small' | relativePathToBackendUrl"
              title="{{ artwork.title }}"
            />
          </div>

          <app-artwork-status [artwork]="artwork" [awards]="awards"></app-artwork-status>

          <div styleClass="active" class="modal">
            <app-button
              *ngIf="
                !hasApplied && artwork.moderation_status !== 2 && !editMode && awards.length > 0
              "
              text="Apply for award"
              (click)="showDialog(artwork.id, artwork.title)"
              title="Apply for award {{ artwork.title }}"
            ></app-button>
          </div>
        </div>
      </div>
    </div>

    <a routerLink="/artwork-editor">
      <div class="empty-container">
        <div class="circle">
          <div class="plus"></div>
        </div>
      </div>
    </a>

    <ng-container *ngIf="step > 0">
      <ng-container *ngIf="!hasApplied">
        <app-modal
          [show]="step === 1"
          [header]="'Apply for &quot;' + awardName + '&quot;'"
          [buttonTexts]="['No, thanks', 'Yes, apply']"
          [buttonFunctions]="[closeDialog, next]"
          [buttonIcons]="['/assets/icons/close_icon.svg', '/assets/icons/open_icon.svg']"
          [successStyle]="nextStep"
        >
          <p>
            You can apply for this award with exactly one artwork. Are you sure you want to apply
            with the artwork titled "{{ artworkTitle }}"?
          </p>
        </app-modal>

        <app-modal
          [show]="step === 2"
          header="Terms & Conditions"
          [buttonTexts]="['Cancel', 'Agree']"
          [buttonFunctions]="[closeDialog, applyForArtAward]"
          [buttonIcons]="['/assets/icons/close_icon.svg', '/assets/icons/open_icon.svg']"
          [successStyle]="success"
          [allowContinue]="termsScrolled"
        >
          <p>
            To apply for the Art Award, you have to read and agree to the following Terms &
            Conditions
          </p>
          <app-award-terms [handleScrolledToBottom]="handleTermsScrolled">
            This is child content Shopify is one of the most popular eCommerce platforms on the
            Internet powering millions of online stores located all over the globe The platform is
            accessible through a web browser on which business owners can manage their online store
            In order to have access to the service, users must pay a monthly plan subscription fee
            along with transaction fees. Kudos should be given to Shopify for the readability of its
            terms of serviceShopify is one of the most popular eCommerce platforms on the
            Internetpowering millions of online stores located all over the globe In order to have
            access to the service, users must pay a monthly plan subscription fee along with
            transaction fees. Kudos should be given to Shopify for the readability of its terms of
            service Shopify is one of the most popular eCommerce platforms on the Internet powering
            millions of online stores located all over the globe powering millions of online stores
            located all over the globe. In order to have access to the service, users must pay a
            monthly plan subscription fee along with transaction fees. Kudos should be given to
            Shopify for the readability of its terms of service. Shopify is one of the most popular
            eCommerce platforms on the Internet powering millions of online stores located all over
            the globe In order to have access to the service, users must pay a monthly plan
            subscription fee along with transaction fees. Kudos should be given to Shopify for the
            readability of its terms of service. Shopify is one of the most popular eCommerce
            platforms on the Internet powering millions of online stores located all over the globe
            powering millions of online stores located all over the globe. In order to have access
            to the service, users must pay a monthly plan subscription fee along with transaction
            fees. Kudos should be given to Shopify for the readability of its terms of service.
            Shopify is one of the most popular eCommerce platforms on the Internet powering millions
            of online stores located all over the globe In order to have access to the service,
            users must pay a monthly plan subscription fee along with transaction fees. Kudos should
            be given to Shopify for the readability of its terms of service. Shopify is one of the
            most popular eCommerce platforms on the Internet powering millions of online stores
            located all over the globe powering millions of online stores located all over the
            globe. Powering millions of online stores located all over the globe.
          </app-award-terms>
        </app-modal>

        <app-modal
          [show]="step === 3"
          header="Thank you for applying!"
          [buttonTexts]="['Ok']"
          [buttonFunctions]="[refreshArtwork]"
          [buttonIcons]="['/assets/icons/open_icon.svg']"
          [successStyle]="success"
        >
          <p>Your artwork will be put into consideration for the Art Award.</p>
        </app-modal>
      </ng-container>

      <app-modal
        [show]="hasApplied"
        header="Sorry!"
        [buttonTexts]="['Ok']"
        [buttonFunctions]="[closeDialog]"
        [buttonIcons]="['/assets/icons/open_icon.svg']"
      >
        <p>You have already applied for the "{{ awardName }}" with an artwork.</p>
      </app-modal>
    </ng-container>
  </div>

  <div class="button-group-wrapper">
    <div *ngIf="!editMode" class="button-wrapper">
      <app-button
        *ngIf="!editMode"
        fixedWidth="true"
        text="Edit"
        (click)="this.editMode = true"
      ></app-button>
    </div>
    <ng-container *ngIf="editMode">
      <app-button
        fixedWidth="true"
        text="Save"
        [disabled]="!checkDirty()"
        (click)="checkDirty() && (saveModal = true)"
      ></app-button>
      <app-button
        fixedWidth="true"
        text="Cancel"
        (click)="checkDirty() ? (this.cancelModal = true) : (editMode = false)"
      ></app-button>
    </ng-container>
  </div>
  <app-warn-on-unsaved
    [dirty]="this.editMode && checkDirty()"
    id="warn-on-unsaved"
  ></app-warn-on-unsaved>
</div>

<!-- cancel edit mode modal -->
<app-modal
  [show]="cancelModal"
  header="Are you sure you want to cancel?"
  [buttonTexts]="['No', 'Yes']"
  [buttonFunctions]="[continueEditMode, cancelEditMode]"
  [buttonIcons]="['/assets/icons/close_icon.svg', '/assets/icons/open_icon.svg']"
>
  <p>Your changes will be discarded.</p>
</app-modal>

<!-- save changes modal -->
<app-modal
  [show]="saveModal"
  header="Save the changes to your artworks?"
  [buttonTexts]="['Continue editing', 'Discard changes', 'Save']"
  [buttonFunctions]="[continueEditMode, cancelEditMode, saveEditMode]"
  [buttonIcons]="[
    '/assets/icons/back_icon.svg',
    '/assets/icons/close_icon.svg',
    '/assets/icons/open_icon.svg'
  ]"
>
  <p>
    Artworks marked for deletion will be permanently deleted. If you rearranged your artworks, the
    new order will be saved.
  </p>
</app-modal>
