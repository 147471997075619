<div
  *ngFor="let entry of entries; let i = index"
  class="grid-block-item"
  [class.right]="i % 2 === 0"
  [class.left]="i % 2 !== 0"
>
  <div class="grid-block-text" [class.right]="i % 2 === 0" [class.left]="i % 2 !== 0">
    <h2 class="grid-block-title" [innerHTML]="entry.title"></h2>
    <div class="grid-block-paragraph" [innerHTML]="entry.text"></div>
  </div>
  <img
    *ngIf="true"
    class="grid-block-image"
    [src]="entry.image | relativePathToBackendUrl"
    [alt]="entry.title"
  />
</div>
