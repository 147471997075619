<div class="navbar-root" [class.dark]="themeService.theme === 'DARK'" (mouseleave)="closeMenus()">
  <div class="background" *ngIf="burgerActive"></div>
  <div class="navbar-wrapper" (window:resize)="checkWindowWidth()">
    <div class="logo-burger-wrapper">
      <div class="logo-wrapper">
        <a routerLink="/">
          <img
            class="logo"
            *ngIf="themeService.theme !== 'DARK'"
            src="/assets/artronaut_logo_pur_schwarz_png.png"
            alt=""
          />
          <img
            class="logo"
            *ngIf="themeService.theme === 'DARK'"
            src="/assets/artronaut_logo_pur_weiss_png.png"
            alt=""
          />
        </a>
      </div>
      <div class="burger" (click)="setBurgerActive()" [class.burger-active]="burgerActive">
        <div class="line one"></div>
        <div class="line two"></div>
        <div class="line three"></div>
      </div>
    </div>
    <div class="all-menu-wrapper" *ngIf="burgerActive">
      <div class="user">
        <div class="login-buttons" *ngIf="!loggedIn">
          <p class="login" (click)="openLogin()">Log in</p>
          <p class="signup" (click)="openSignin()">Sign up</p>
        </div>
        <div class="user-menu" *ngIf="loggedIn">
          <div class="user-name" (click)="setUserMenuActive()">
            <div>Hello {{ userFirstName }}!</div>
            <div class="arrow" [class.active]="userMenuActive"></div>
          </div>
          <ul class="user-menu-items" *ngIf="userMenuActive">
            <li>
              <p>
                <a (click)="closeMenus()" [routerLink]="editorLink">Edit profile</a>
              </p>
            </li>
            <li>
              <p>
                <a (click)="closeMenus()" routerLink="/change-password">Change Password</a>
              </p>
            </li>
            <li>
              <p>
                <a (click)="closeMenus()" routerLink="/artwork-editor">Upload Artwork</a>
              </p>
            </li>
            <li>
              <p><a (click)="closeMenus()" routerLink="artwork-overview">My artworks</a></p>
            </li>
            <!-- <li>
              <p><a routerLink="">Following</a></p>
            </li>
            <li>
              <p><a routerLink="">Settings</a></p>
            </li> -->
            <li>
              <p><a (click)="logOut()">Log out</a></p>
            </li>
          </ul>
        </div>
      </div>
      <div class="search-n-menu-wrapper">
        <div class="search-n-lang-wrapper">
          <!-- <div class="search-wrapper">
            <form class="search-form" action="">
              <button type="submit" class="fa-search"></button>
              <input class="search" type="text" name="search" />
            </form>
          </div> -->
          <!-- <div class="language">
            <p><a routerLink="">DE</a></p>
            <p class="line">|</p>
            <p><a routerLink="">EN</a></p>
          </div> -->
        </div>
        <ul class="menu">
          <li class="menu-items" *ngFor="let menu of menus; let i = index">
            <a
              (click)="setMenuActive(i)"
              [style.font-weight]="subMenuActive === i ? 'bold' : 'inherit'"
            >
              {{ menu.value.title }}
            </a>
            <ul class="submenu-items" [style.display]="subMenuActive === i ? 'block' : 'none'">
              <li *ngFor="let submenu of menu.value.sub_nav">
                <p>
                  <a [routerLink]="submenu.meta.slug" (click)="closeMenus()">{{ submenu.title }}</a>
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="spaceblock"></div>
