<div [class.marginTop]="to.marginTop">
  <!-- Label -->
  <label *ngIf="!to.required; else requiredLabel">{{ to.label }}</label>
  <ng-template #requiredLabel>
    <label>{{ to.label + ' *' }}</label>
  </ng-template>

  <div
    class="dropzone"
    [id]="id"
    dropzone
    [class.invalid]="(formControl.dirty || formControl.touched) && formControl.invalid"
    (hovered)="toggleHover($event)"
    (dropped)="onDrop($event)"
    [class.hovering]="isHovering"
    (click)="openFileInput()"
  >
    <div class="main-text">
      <img [src]="typeIcon()" class="upload-icon" />
      <p [innerHTML]="to.description"></p>
    </div>

    <input
      #fileinput
      type="file"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [multiple]="to.multiple"
      (change)="onChange($event)"
      [accept]="acceptedTypes()"
    />
  </div>

  <small class="error" *ngIf="(formControl.dirty || formControl.touched) && formControl.invalid">
    {{ displayErrorMessage(formControl.errors) }}
  </small>

  <small class="warning" *ngIf="sameNameError"> Files cannot have the same name </small>

  <p
    class="hint"
    [class.hint-error]="
      (formControl.dirty || formControl.touched) &&
      formControl.invalid &&
      formControl.errors.invalidNoOfFiles
    "
  >
    {{ hint }}
  </p>

  <div
    class="filePreviewContainer"
    [style]="
      remoteFiles.length > 0 && files.length > 0
        ? ' grid-template-columns: 1fr 1fr;'
        : ' grid-template-columns: 1fr;'
    "
  >
    <div *ngIf="files.length > 0" [class]="remoteFiles.length > 0 ? 'selected' : 'preview-center'">
      <h6>Selected</h6>
      <div [id]="previewId" class="files" cdkDropList (cdkDropListDropped)="drop($event)">
        <p class="reorderText" *ngIf="to.type === 'image' && to.multiple">Drag to reorder</p>
        <div
          [cdkDragDisabled]="to.type === 'file' || !to.multiple"
          class="file"
          *ngFor="let file of files; let i = index"
          cdkDrag
        >
          <img
            class="image-preview"
            [src]="to.type === 'image' ? getSanitizedImageUrl(file) : '/assets/icons/pdf_black.png'"
          />

          <p class="file-name">{{ file.name }}</p>
          <div class="cross" (click)="removeFile(i)">
            <div class="x a"></div>
            <div class="x b"></div>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="remoteFiles.length > 0 && remoteFiles[0].hasOwnProperty('document')"
      [class]="files.length > 0 ? 'uploaded' : 'preview-center'"
    >
      <h6>Uploaded</h6>
      <div [id]="previewId" class="files" cdkDropList (cdkDropListDropped)="drop($event)">
        <p class="reorderText" *ngIf="to.type === 'image' && to.multiple">Drag to reorder</p>
        <div
          [cdkDragDisabled]="to.type === 'file' || !to.multiple"
          class="file"
          *ngFor="let remoteFile of remoteFiles; let i = index"
          cdkDrag
        >
          <img
            class="image-preview"
            [src]="
              to.type === 'image' ? rootUrl + remoteFile.document : '/assets/icons/pdf_black.png'
            "
          />

          <p class="file-name">{{ remoteFile.title }}</p>
          <img
            *ngIf="remoteFile.delete; else unmarked"
            class="remove"
            src="/assets/icons/recycle_bin_red.svg"
            (click)="unmarkForDeletion(i)"
          />
          <ng-template #unmarked>
            <img class="remove" src="/assets/icons/recycle_bin.svg" (click)="markForDeletion(i)" />
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <p [innerHTML]="to.helpText" class="help-text"></p>
</div>
