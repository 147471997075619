import { Component, Input, OnInit } from '@angular/core';
import { GalleryItem, ImageItem } from 'ng-gallery';
import { Observable, map, of } from 'rxjs';

@Component({
  selector: 'app-galleria-block',
  templateUrl: './galleria-block.component.html',
  styleUrls: ['./galleria-block.component.scss'],
})
export class GalleriaBlockComponent implements OnInit {
  @Input() images: any = [];
  @Input() type: any;
  @Input() styleExtra: string | undefined;
  @Input() classExtra: string | undefined;

  galleryImages: GalleryItem[] = [];
  images$: Observable<GalleryItem[]>;

  ngOnInit(): void {
    this.images$ = of(this.galleryImages).pipe(map((res) => res));
    this.images.forEach((image) => {
      this.galleryImages.push(new ImageItem({ src: image.image_url, thumb: image.image_url }));
    });
  }
}
