/* tslint:disable */
/* eslint-disable */
export enum GenreEnum {
  Abs = 'ABS',
  Fig = 'FIG',
  Hyp = 'HYP',
  Lan = 'LAN',
  Por = 'POR',
  Stl = 'STL',
}
