<div
  class="flex flex-wrap flex-stretch"
  [style]="
    backgroundImage
      ? 'background-image: url(' + (backgroundImage | relativePathToBackendUrl) + ');'
      : ''
  "
>
  <div
    *ngFor="let column of columns; let i = index"
    [style]="this.sanitizer.bypassSecurityTrustStyle(getColumnStyle(column))"
    [class]="getClass(column)"
  >
    <div
      [style]="this.sanitizer.bypassSecurityTrustStyle(getColumnProperties(column))"
      [class]="column.value.border === true ? 'border h-full' : 'h-full'"
    >
      <div class="content" *ngFor="let item of column.value.entries">
        <!-- Grid block -->
        <div *ngIf="item.type === 'grid_block'">
          <div
            class="grid-block"
            [ngClass]="{
              alternating: item.value.layout === 'alternating',
              grid: item.value.layout === 'grid'
            }"
          >
            <app-wagtail-grid-block [entries]="item.value.entries"></app-wagtail-grid-block>
          </div>
        </div>
        <!-- Heading -->
        <div *ngIf="item.type === 'heading'">
          <app-wagtail-heading
            [id]="item.id"
            [text]="item.value.text"
            [color]="item.value.font_color"
            [theme]="theme"
            [styleExtra]="item.value.style_extra"
            [classExtra]="item.value.class_extra"
          ></app-wagtail-heading>
        </div>
        <!-- Paragraph -->
        <div
          *ngIf="item.type === 'paragraph'"
          [ngClass]="{
            horizontal: item.value.layout === 'horizontal',
            vertical: item.value.layout === 'vertical'
          }"
        >
          <app-wagtail-paragraph
            [id]="item.id"
            [border]="item.value.border"
            [borderColor]="item.value.border_color"
            [margin]="item.value.margin"
            [padding]="item.value.padding"
            [fontSize]="item.value.font_size"
            [entries]="item.value.entries"
            [color]="item.value.font_color"
            [styleExtra]="item.value.style_extra"
            [classExtra]="item.value.class_extra"
          ></app-wagtail-paragraph>
        </div>

        <!-- Image -->
        <div *ngIf="item.type === 'image'">
          <app-wagtail-image
            [entries]="item.value.entries"
            [styleExtra]="item.value.style_extra ? item.value.style_extra : ''"
            [classExtra]="item.value.class_extra"
          ></app-wagtail-image>
        </div>
        <!-- Buttons -->
        <div *ngIf="item.type === 'buttons'" class="buttons">
          <app-wagtail-button [entries]="item.value"></app-wagtail-button>
        </div>

        <!-- Galleria Block -->
        <div *ngIf="item.type === 'gallery_block'">
          <app-galleria-block
            [images]="item.value.images"
            [type]="item.value.type"
            [styleExtra]="item.value.style_extra"
            [classExtra]="item.value.class_extra"
          ></app-galleria-block>
        </div>

        <!-- Raw HTML Block -->
        <div *ngIf="item.type === 'raw_html'">
          <app-wagtail-raw-html [html_value]="item.value.html"></app-wagtail-raw-html>
        </div>

        <!-- Grid layout block -->
        <div *ngIf="item.type === 'container'">
          <app-wagtail-grid-layout
            [containerVerticalJustification]="item.value.vertical_justification"
            [columns]="item.value.columns"
            [backgroundImage]="item.value.background_image"
            [theme]="theme"
            [styleExtra]="item.value.style_extra"
            [classExtra]="item.value.class_extra"
          ></app-wagtail-grid-layout>
        </div>

        <!-- Vimeo videos -->

        <!-- Height and width needs to be confirmed. Possibly should be entered by the user -->
        <div *ngIf="item.type === 'vimeo_urls'">
          <iframe
            [src]="item.value.url | safeUrl"
            [style]="item.value.style_extra"
            [class]="item.value.class_extra"
            width="100%"
            height="500px"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
